import { 
    Create,
    SimpleForm, 
    NumberInput,
    SelectInput,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { Calibres } from '../../utils';



export const CajaCreate = () => {

    return(
        <Create redirect="list">
            <SimpleForm>
                <Grid paddingTop='1rem' container direction='row' columnSpacing={2} justifyContent='space-between'>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <SelectInput fullWidth source='calibre' style={{ marginTop:'-0rem'}} label='Calibre' choices={Calibres}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <NumberInput fullWidth multiline maxRows={1} source="consecutivo" label='Consecutivo'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <NumberInput fullWidth multiline maxRows={1} source="ancho" label='Ancho'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <NumberInput fullWidth multiline maxRows={1} source="largo" label='Largo'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <NumberInput fullWidth multiline maxRows={1} source="cantidadLaminas" label='Cantidad Laminas'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <NumberInput fullWidth source="areaTotal" label='Área Total'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <NumberInput fullWidth source="areaRestante" label='Área Restante'/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
