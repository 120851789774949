import React from 'react';
import { List, 
  Labeled, 
  Datagrid, 
  ChipField, 
  TextField, 
  useGetOne,
  TopToolbar, 
  NumberInput, 
  SelectField, 
  SelectInput, 
  CreateButton, 
  ExportButton, 
  FunctionField, 
  useRecordContext,
} from 'react-admin';
import { Calibre } from '../../utils';
import { Calibres } from '../../utils';
import { responsiveFontSizes } from '@mui/material';
import { CajaDialog } from './components/CajaDialog';



export const CajaList = () => {


  const record = useRecordContext();

  const TagListActions = () => (
    <TopToolbar> 
      <CreateButton label="ra.action.create" />
      <ExportButton />
    </TopToolbar>
  );

  return (
    <>
      <List actions={<TagListActions/>} style={{responsiveFontSizes}} >
        <Datagrid rowClick="edit" >
          <TextField source="ancho" style={{fontWeight:'bold'}} label="ANCHO CAJA"/>
          <TextField source="largo" style={{fontWeight:'bold'}} label="LARGO CAJA"/>
          <TextField source="cantidadLaminas" style={{fontWeight:'bold'}} label="CANTIDAD LÁMINAS"/>
          <TextField source="areaTotal" style={{fontWeight:'bold'}} label="ÁREA TOTAL"/>
          <TextField source="areaRestante" style={{fontWeight:'bold'}} label="ÁREA RESTANTE"/>
          <SelectField source="calibre" style={{fontWeight:'bold'}} choices={Calibres} optionText='name' label="CALIBRE"/>
          <TextField source="consecutivo" style={{fontWeight:'bold'}} label="CONSECUTIVO"/>
          <CajaDialog/>
        </Datagrid>
      </List>
    </>
  )
};