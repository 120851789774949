
import React from "react";
import { Create, TextInput, useGetOne, useGetList, Loading,
        AutocompleteArrayInput, useUpdate, useDelete, ArrayInput,
        ReferenceInput, SelectInput, FormTab,
        useRecordContext, FormDataConsumer, TabbedForm} from "react-admin";
import { Box, Grid, TextField, Typography } from '@mui/material';
import { mapUserGroupNames } from "../../utils";
import { useFormContext, useWatch} from 'react-hook-form'
import { genUID } from "../../utils/helperFunctions";
import CognitoUserInput from './components/CognitoUserInput';
import CognitoUserGroups from './components/CognitoUserGroups';
import { GeneralTab } from './tabs/GeneralTab';






const UserCreate = (props) => {
    let cognitoSelected = false
    const setCognitoValuesAndDisableAll = (val)=>{
        cognitoSelected = !val
    }
    const defaultId = ()=>genUID(4)
    return(
    <Create redirect='edit'   alignContent='center' defaultValues = {{id:defaultId}}> 
        <TabbedForm  alignContent='center' >
            <TabbedForm.Tab label={'GENERAL'}>
                <GeneralTab/>
            </TabbedForm.Tab>
        </TabbedForm>
    </Create>
    )
};


const CognitoUserGroupsOld = ()=>{

    const {setValue} = useFormContext()
    const cognitoUserId = useWatch({name:'cognitoUserId'})
    const [update, {  isLoading }] = useUpdate();
    const [deleteOne,  ] = useDelete();

    let fetchGroups = false

    if( cognitoUserId ){
        fetchGroups=true
    }


    const choices = useGetList(
           'cognitoGroups',
           {filter:{listGroups:{}},
           pagination:{ page: 1, perPage: 10 }}, 
       );
        
    const groups = useGetList(
    'cognitoGroups',
    {
        filter:{listGroupsForUser:{username:cognitoUserId }},
        pagination:{ page: 1, perPage: 10 }
    },
    {
        enabled: fetchGroups
    } 
    )
    let  formattedGroups = []
    let notIntersection  = []

    React.useEffect(()=>{
        if(groups.status !== 'idle' && !groups.isLoading && groups.data){
            const  gg = groups.data  ? groups.data.map(group => group.id):[]
            setValue('roles', gg)
        }
            
    }, [groups.data])


    let choicesGroups = choices.data

    const AddOrDeleteCognitoGroup=(e, cognitoUserId, groups )=>{
        formattedGroups =  groups.data.map(group => group.id) || []
        if (e.length>formattedGroups.length){
            notIntersection = e.filter(x => !formattedGroups.includes(x))

            update(
                'cognitoGroups',
                { id:cognitoUserId, data: notIntersection, previousData:formattedGroups }
            );
        }
        if (e.length<formattedGroups.length){
            notIntersection = formattedGroups.filter(x => !e.includes(x))
            deleteOne(
                'cognitoGroups',
                { id:[cognitoUserId,notIntersection[0]], previousData:formattedGroups }
            );
        }
    }
    if( isLoading ) return (<Loading/>)
    return(
            <AutocompleteArrayInput 
                source={'roles'}
                choices={choicesGroups}
                optionText={text=> text && text.id ? mapUserGroupNames.find(x => x.id === text.id) ? mapUserGroupNames.find(x => x.id === text.id).name : '' : []}
                onChange={e=>AddOrDeleteCognitoGroup(e, cognitoUserId, groups )}
                size='small'
                />
    )
}

const CognitoUserInputOld = ( {props, autoSetParam} ) => {
    const {setValue} = useFormContext()
    const cognitoUserId = useWatch({name:'cognitoUserId'})

    const [ cognito, setCognito ] = React.useState('')
    const [ cognitoSelected, setCognitoSelected ] = React.useState(false)
    const { data } = useGetOne('cognitoUsers', { id:cognitoUserId }, { enabled: cognitoSelected });
   

    

    React.useEffect(() => {
        if (cognito && data.email) {
            setValue('email', data.email )
            setValue('id', cognito )
            setValue('nombres', data.name )
            setValue('apellidos', data.family_name )
            autoSetParam(cognitoSelected)
            } 
        
        }, [data]
    );


    const handleCognitoChange = (e)=>{
        if(e.target.value){
            setCognito(e.target.value)
            setCognitoSelected(true)
        }
        else{
            setCognito(e.target.value)
            setCognitoSelected(false)
        }
    }

    
 

    const OptionRenderer =  ( ) => {
        const record = useRecordContext(); 
        return(
            <Grid container direction='row' justifyContent='space-between' spacing={2}>
                <Grid item xs zeroMinWidth>
                    <Typography gutterBottom  component={'div'} noWrap style={{'overflowWrap': 'break-word'}}>
                        <Box  sx={{ width:'200px', maxWidth:'300px' ,marginLeft:'2px', marginRight:'5px'}}>{`${record.name} ${record.family_name}`}</Box> 
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component={'div'}>
                        <Box sx={{fontStyle:'italic', fontWeight:'light', fontSize:'11pt'}}>{`${record.email}` }</Box>
                    </Typography>
                </Grid>                     
            </Grid>
        )}

    return(
    <>    
        <ReferenceInput source='cognitoUserId' reference='cognitoUsers'>
            <SelectInput label='Usuario Cognito' optionText={<OptionRenderer/>} onChange={handleCognitoChange} variant='outlined' size='small' fullWidth/>
        </ReferenceInput>
        <FormDataConsumer>
            {
                ({formData, ...rest})=> (formData && formData.email && cognitoSelected ?
                (
                    <TextInput source='email' disabled={false} variant='outlined' fullWidth/>
                ):(                    
                    <TextInput source='email' disabled={false} variant='outlined' fullWidth/>
                )
            )}   
        </FormDataConsumer>
    </>
    )
}


export default UserCreate