import LenView from './LenView';
import Tooltip from '@mui/material/Tooltip';
import React, { useState, useEffect } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'; 
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded';
import { Grid, Accordion, Typography, AccordionDetails, AccordionSummary } from '@mui/material';
import { Datagrid,TextField, ArrayField, NumberField, useRecordContext, useGetList } from 'react-admin';
import { organizeAndSortLen, checkLen, checkOp, compararArrays } from '../components/extractOpAndVersion';      

export const LenContent = () => {
  const record = useRecordContext();
  const organizedLen = organizeAndSortLen(record.len);
  const calibreExp = record.calibre;
  //console.log("calibreExp", calibreExp)
  const { data: dataOrdenSalidas } = useGetList(
    'OrdenSalidas', { pagination: { page: 1, perPage: 10 } }
  );

  const [showWarningIcon, setShowWarningIcon] = useState([]);
  const [comparacionRealizada, setComparacionRealizada] = useState(false);

  useEffect(() => {
    if (!comparacionRealizada) {
      const functionLenContent = async () => {
        const lenDataArray = await checkLen(organizedLen, calibreExp);
        const opDataArray = await checkOp(dataOrdenSalidas);
        const showIcons = compararArrays(opDataArray, lenDataArray);
        console.log("ICONS ARRAY" , showIcons)

        setShowWarningIcon(showIcons)
        setComparacionRealizada(true); // Marcar que la comparación se ha realizado
      };
    
      functionLenContent();
    }
  }, [organizedLen, dataOrdenSalidas, comparacionRealizada]);

  const calculateOpArray = (organizedLen) => {
    return Object.keys(organizedLen).map(op => {
      return {
        op: op,
        totalPlanchasExp: organizedLen[op].data.length
      };
    });
  };

  const opArray = calculateOpArray(organizedLen);

  const calculateTotalPlanchasExp = (op) => {
    const opData = opArray.find(item => item.op === op);
    return opData ? opData.totalPlanchasExp : 0;
  };

  return (
    <div style={{ marginTop: '2rem' }}>
      {Object.keys(organizedLen).map((op, index) => {
        const lenData = organizedLen[op].data;
        const totalPlanchasExp = lenData.length;
  
        return (
          <Accordion key={op} square sx={{ boxShadow: 'none',}}>
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon/>} 
              aria-controls={`${op}-content`} 
              id={`${op}-header`} 
            >
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {`OP: ${op} - Total Planchas (${totalPlanchasExp})`}
                {showWarningIcon[index]?.warning ? (
                  <Tooltip title="Cantidad de planchas sobrepasa al requerido por la orden">
                    <WarningRoundedIcon style={{ marginLeft: '1rem', color: '#F39C12' }}/>
                  </Tooltip>
                ) : (
                  <Tooltip title="La Orden de Producción se puede exponer">
                    <span>
                      <BeenhereRoundedIcon style={{ marginLeft: '1rem', color: 'green' }}/>
                    </span>
                  </Tooltip>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {lenData && lenData.length > 0 ? (
                <Grid sx={{ '& img': { minWidth: 250, minHeight: 250 } }} style={{ marginTop: '1rem' }}>
                  <ArrayField record={{ len: lenData }} source="len">
                    <Datagrid bulkActionButtons={false}>
                      <LenView source="image" label="IMAGEN EXPOSICION" />
                      <TextField source="uuid" label="ID Len" style={{ fontWeight: 'bold' }}/>
                      <TextField source="name" label="Nombre Len" style={{ fontWeight: 'bold' }}/>
                      <NumberField source="lenWidth" label="Ancho Len" style={{ fontWeight: 'bold' }}/>
                      <NumberField source="lenHeight" label="Altura Len" style={{ fontWeight: 'bold' }}/>
                      <NumberField source="area" label="Área Len" style={{ fontWeight: 'bold' }}/>
                    </Datagrid>
                  </ArrayField>
                </Grid>
                ) : (
                  <Typography>
                    {`No hay datos para mostrar.`}
                  </Typography>
                )
              }
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};