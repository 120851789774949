import React, { useState, useEffect } from 'react';
import { 
  List, 
  Button,
  Datagrid, 
  TextField,
  TextInput, 
  TopToolbar,
  FilterList, 
  NumberField, 
  CreateButton, 
  ExportButton, 
  FunctionField, 
  useListContext,
  ReferenceField,
  useUnselectAll,
  FilterListItem,
} from 'react-admin';
import Divider from '@mui/material/Divider';
import ImageView from './components/ImageView';
import RuleIcon from '@mui/icons-material/Rule';
import BadgeIcon from '@mui/icons-material/Badge';
import { Card, CardContent } from '@mui/material';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { CreateExposicionDialog } from './components/CreateExposicionDialog';
import { ShowExposicionDialogRA } from './components/ShowExposicionDialogRA';

export const ExposicionList = () => {

  const ExposicionFilter = [
    <TextInput 
      key={1} 
      style={{marginLeft: '16px'}} 
      source="expoName.contains" 
      label="Buscar Nombre Exposición" 
      alwaysOn resettable
    />,
  ]

  const filterCity = {ciudad:{eq:'BARRANQUILLA'}};
  
  return (
    <List filters={ExposicionFilter} aside={<ExposicionFilters/>} filter={filterCity} rowClick={false} >
      <Datagrid rowClick="edit" sort={{field: "expoName", order: "DESC"}}>
        <ImageView source="imageExp" label='IMAGEN EXPOSICIÓN' style={{marginLeft:'-5rem'}} />
        <TextField source="expoName" style={{fontWeight:'bold'}} label="NOMBRE EXPOSICIÓN" />
        <TextField source="plateType" style={{fontWeight:'bold'}} label="TIPO DE PLANCHA" /> 
        <TextField source="plateName" style={{fontWeight:'bold'}} label="NOMBRE PLANCHA" />     
        <TextField source='calibre' style={{fontWeight:'bold'}} label='CALIBRE' />
        <FunctionField source='dateExposed' style={{fontWeight:'bold'}} label="FECHA EXPOSICIÓN" 
          render={record=>record.dateExposed!==undefined?new Date(record.dateExposed).toLocaleString():""} />     
        <FunctionField source="updatedAt" style={{fontWeight:'bold'}} label="FECHA ACTUALIZACIÓN" 
          render={record=>record.updatedAt!==undefined?new Date(record.updatedAt).toLocaleString():""} />      
        <ReferenceField source='cajaId' reference="cajas" style={{display:'flex', flexDirection:'column', justifyContent:'center'}} label='CAJA' >
          <NumberField source="consecutivo" style={{fontWeight:'bold'}} />
        </ReferenceField>  
        <NumberField source='taras' style={{display:'flex', flexDirection:'column', fontWeight:'bold', justifyContent:'center'}} label='TARA' />
        <ReferenceField label='Responsable Reposicion' source='responsableId' reference='usuarios' link={false}>
          <FunctionField label="RESPONSABLE" style={{ fontWeight: 'bold' }} render={record => `${record.nombres} ${record.apellidos}`} />
        </ReferenceField>
        <CreateExposicionDialog />
        <ShowExposicionDialogRA />
      </Datagrid>
    </List>
  ) 
};

const removeDuplicates = (data, uniqueKey) => {
  return data?data.reduce((accumulator, current) => {
    if (!accumulator.some((item) => item[uniqueKey] === current[uniqueKey])) {
      if(current[uniqueKey]){
        accumulator.push(current);
      }
    }
    return accumulator;
  }, []):[];
};

const ExposicionFilters = () => {

  const { data, filterValues, setFilters } = useListContext();
  const unselectAll = useUnselectAll('exposicions');
  const calibres = removeDuplicates(data, 'calibre'); 
  const responsableExpo = removeDuplicates(data, 'responsableId');
  //const ciudades = removeDuplicates(data, 'ciudad');

  useEffect(() => {
    if (filterValues) {
      unselectAll()
    }
    else{
    }
  }, [filterValues]);

  /*
  if (!data || data.length === 0) {
    return null;
  }
  */

  return (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 210 }}>
      <CardContent>
        {data && data.length === 0 ?
          <Button onClick={()=>setFilters({})}>
            {`Limpiar Filtros`}
          </Button>
          :(
        <>
          {/*
          <FilterList label="Ciudades" icon={<LocationCityIcon/>}>{
            ciudades?ciudades.map((item)=>(
              <FilterListItem sx={{RaFilterListItem:{fontSize:'10px'}}} 
                source='ciudad.contains'
                onChange={(value)=>console.log("value ciudades", value)} 
                key={item.ciudad} 
                label={item.ciudad} 
                value={{ ciudad: { contains: item.ciudad }}}
              />
            )): <FilterListItem label="Sin Ciudades" />}
          </FilterList>
          <Divider sx={{ mt:2 }}/>
          */}
          <FilterList label="Pendientes" icon={<RuleIcon sx={{ fontSize: 12 }}/>} >
            <FilterListItem 
              label="Sí" 
              onChange={(value)=>console.log("Pendiente Salida Sí", value)}
              value={{ pendienteSalida: { contains: 'true' }}} 
            />
            <FilterListItem 
              label="No"
              onChange={(value)=>console.log("Pendiente Salida No", value)}
              value={{ pendienteSalida: { contains: 'false' }}} 
            />
          </FilterList>
          <Divider sx={{ mt:2 }}/>
          <FilterList label="Calibres" icon={<LineWeightIcon sx={{ fontSize: 12 }}/>} >
            {calibres?calibres.map((item)=>(
              <FilterListItem sx={{RaFilterListItem:{fontSize:'10px'}}} 
                source='calibre.contains'
                onChange={(value)=>console.log("value calibre", value)} 
                key={item.calibre} 
                label={ item.calibre}
                value={{ calibre: { contains: item.calibre }}}
              />
            )): <span/>}
          </FilterList>
          <Divider sx={{ mt:2 }}/>
          <FilterList label="Responsable" icon={<BadgeIcon sx={{ fontSize: 12 }}/>} >
            {responsableExpo?responsableExpo.map((item)=>(
              <FilterListItem sx={{RaFilterListItem:{fontSize:'10px'}, ml:-4, width: 200}} 
                source='responsableName.contains'
                onChange={(value)=>console.log("value responsableName", value)} 
                key={item.responsableName} 
                label={ item.responsableName} 
                value={{ responsableName: { contains: item.responsableName }}}
              />
            )): <span/>}
          </FilterList>
        </>)}
      </CardContent>
    </Card>
  )
};