import { post, get } from "aws-amplify/api";
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { Pagination } from "./Pagination";
import config from '../../amplifyconfiguration.json';

Amplify.configure(config);






export class AdminQueries {
  static async listCognitoUsers(
    params
  ){
    // Only 2 filters are available with Cognito users
    const { getUser, listUsersInGroup } = params.filter;

    // First filter getUser
    // Returns one Cognito user by username
    if (getUser) {
      try {
        const userData = await AdminQueries.get("/getUser", {
          username: getUser.username,
        });

        const user = AdminQueries.parseUser(userData);
        console.log("User Data->", user)

        return {
          data: [user],
          total: 1,
        };
      } catch (e) {
        // Returns 400 when user not found
        if (e.response?.status !== 400) {
          throw e;
        }

        return {
          data: [],
          total: 0,
        };
      }
    }

    let queryName = "listUsers";
    let queryVariables = {};

    // Second filter listUsersInGroup
    // Returns Cognito users by group
    if (listUsersInGroup) {
      queryName = "listUsersInGroup";
      queryVariables = {
        groupname: listUsersInGroup.groupname,
      };
    }

    const { page, perPage } = params.pagination;

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });
    const token = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof token === "undefined") {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    let queryResult = { Users: [], NextToken: undefined };

    // Executes the query
    try {
      queryResult = await AdminQueries.get(`/${queryName}`, {
        ...queryVariables,
        limit: perPage,
        token,
      });
    } catch (e) {
      // Returns 400 when group not found
      if (!listUsersInGroup || e.response?.status !== 400) {
        throw e;
      }
    }
    console.log("List users Query Result->", queryResult)

    const users = queryResult.Users.map(AdminQueries.parseUser);
    const nextToken = queryResult.NextToken || null;
    
    // Saves next token
    Pagination.saveNextToken(nextToken, querySignature, page);

    // Computes total
    let total = (page - 1) * perPage + users.length;
    if (nextToken) {
      total++; // Tells react admin that there is at least one more page
    }

    return {
      data: users,
      total,
    };
  }

  static async listCognitoGroups(
    params
  ){
    // Only 1 filter is available with Cognito groups
    const { listGroupsForUser, listGroups } = params.filter;
    let queryName = ''
    let queryVariables = {}

    if (listGroups){
      queryName =   "listGroups";
      queryVariables = {};  
      //console.log('listGroups')
    }
    
    
    // Filter listGroupsForUser
    // Returns Cognito groups by user
    console.log("List Groups for User->", listGroupsForUser)
    if (listGroupsForUser) {
      queryName = "listGroupsForUser";
      queryVariables = {
        username: listGroupsForUser.username,
      };
    }
    else{
      queryName = "listGroups";
    }
    //console.log(queryVariables)

    const { page, perPage } = params.pagination;

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });

    const token = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof token === "undefined") {
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    let queryResult = { Groups: [], NextToken: undefined };

    // Executes the query
    try {
      queryResult = await AdminQueries.get(`/${queryName}`, {
        ...queryVariables,
        limit: perPage,
        token,
      });
    } catch (e) {
      // Returns 400 when user not found
      if (!listGroupsForUser || e.response?.status !== 400) {
        throw e;
      }
    }

    const groups = queryResult.Groups.map(AdminQueries.parseGroup);
    const nextToken = queryResult.NextToken || null;

    // Saves next token
    Pagination.saveNextToken(nextToken, querySignature, page);

    // Computes total
    let total = (page - 1) * perPage + groups.length;
    if (nextToken) {
      total++; // Tells react admin that there is at least one more page
    }

    return {
      data: groups,
      total,
    };
  }

  static async getCognitoUser(
    params
  ){
    // Executes the query
    console.log("Get Cognito User Params->", params)
    const userData = await AdminQueries.get("/getUser", {
      username: params.id,
    });

    const user = AdminQueries.parseUser(userData);

    return {
      data: user,
    };
  }

  static async getManyCognitoUsers(
    params
  ) {
    const users = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        const userData = await AdminQueries.get("/getUser", {
          username: id,
        });

        const user = AdminQueries.parseUser(userData);

        users.push(user);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: users,
    };
  }

  static parseUser(user) {
    const { Username, Attributes, UserAttributes, ...fields } = user;

    const attributes = AdminQueries.parseAttributes(
      Attributes || UserAttributes
    );

    return { ...fields, ...attributes, id: Username };
  }

  static parseGroup(group) {
    const { GroupName, ...fields } = group;

    return { ...fields, id: GroupName };
  }

  static parseAttributes(attributes) {
    return attributes.reduce((attributes, attribute) => {
      const { Name, Value } = attribute;

      return {
        ...attributes,
        [Name]: Value,
      };
    }, {});
  }

  static async addUserToCognitoGroups(
    params
  ) {
    let queryResult = {};
    const  body = {username : params.id, groupname: params.data[0]}
    try {
        queryResult = await AdminQueries.post("/addUserToGroup", {...body}) 
        //console.log(queryResult.message.split(' '))
      } catch (e) {
        console.log(e);
      }

      return {data:{id: params.id, result: queryResult }}
      

  }
  static async removeUserFromCognitoGroups(
    params
  ) {
    let queryResult = {};
    const  body = {username : params.id[0], groupname: params.id[1]}
      try {
        queryResult = await AdminQueries.post("/removeUserFromGroup",  {...body});
      } catch (e) {
        console.log(e);
      }

      return {data:{id: params.id[0], result:queryResult }}
      
  }

  static async post(path, params) {
    const init = {
      body: params,
      headers: { "Content-Type": "application/json" },
    };
    const apiName = "AdminQueries";
    const authToken = (await fetchAuthSession()).tokens?.accessToken?.toString();
    const options = {
      headers: {"Authorization": authToken, "Content-Type": "application/json"},
      body: params,
    };
    try{

      const result = await post({
        apiName: apiName,
        path: path,
        options: options
      })

      const res = await result.response; 
      if (res.statusCode === 200) {
        const data = await  res.body.json(); 
        console.log("DATA", data)
        return data;
      } 
    } catch (error) {
      console.error('Error:', error);
    }
  }


static async get(path, params) {
  const apiName = "AdminQueries";
  const authToken = (await fetchAuthSession()).tokens?.accessToken?.toString();

  delete params.limit;
  delete  params.token;

  const options = {
    headers: {"Authorization": authToken, "Content-Type": "application/json"},
    queryParams: params,
  };
  try {
    const result =  get({
      apiName: apiName,
      path: path,
      options: options,
    });
  
    const res = await result.response; 
    if (res.statusCode === 200) {
      const data = await  res.body.json(); 
      console.log("DATA", data)
      return data;
    } 
  } catch (error) {
    console.error('Error:', error);
  }
  }
}