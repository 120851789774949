import React, { useEffect, useState } from 'react';
import { SelectInput, TextInput, NumberInput, TabbedForm, useRecordContext, useCreate, Button, ReferenceInput, BooleanInput, useRefresh } from 'react-admin';
import { Grid } from '@mui/material';
import { Calibres, Sucursales } from '../../../../utils';
import StockStandalone from '../../Stock/components/StockStandAlone';
import { useFormContext, useWatch } from 'react-hook-form';
import CurrencyInputs from './CurrencyInputs';
import LabeledCustomItem from '../../../../utils/LabeledCustomItem';
import { Proveedores } from '../../../../utils';


export const MaterialEditLayout = (props) => {
  const [isFotopolimero, setIsFotopolimero] = useState(false);

  const record  = useRecordContext();
  useEffect(() => {
    if(record){
      setIsFotopolimero(record.alegraCategory === 1)
    } 
  }, [record])
  const handleCategoryChange = (event) => { 
    console.log('CATEGORY CHANGE', event)
    if (event.target.value === 1){
      setIsFotopolimero(true)
    }else
    {
      setIsFotopolimero(false)
    }
  }

  return (
    <TabbedForm>
      <TabbedForm.Tab label='General'>
        <Grid container direction='row' spacing={5} justifyContent={'space-evenly'}>
          <Grid item   sm={12} md={5} spacing={2} container justifyContent={'space-between'}>
            <Grid item xs={12} md={6}>
              <GetIdField record2={record} />
            </Grid>
            <Grid item xs={12} md={3} justifyContent={'right'}>
              <AlegraID />
            </Grid>
            <Grid item xs={12} md={7}>
              <ReferenceInput source="alegraCategory" reference="alegraItemsCategories">
                <SelectInput optionText="name" fullWidth label='Categoria' onChange={handleCategoryChange}/>
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={4} alignItems={'center'}>
              <BooleanInput source='applyIva' label = 'Aplicar IVA' defaultValue={true}  />
            </Grid>
            <Grid item xs={12} sm={7}>
              <SelectInput source="proveedor" choices={Proveedores} fullWidth />
            </Grid>
            <Grid item xs={12} sm={5}>
              <SelectInput source="bodega" choices={Sucursales}  fullWidth  disabled={props.isEdit}/>
            </Grid>
            <Grid item xs={12} sm={7} >
              <TextInput source="referencia" sx={{ marginTop: "8px" }} fullWidth disabled={props.isEdit}/>
            </Grid>
            {isFotopolimero ?(

              <Grid item xs={12} sm={5}>
                <SelectInput source="calibre" choices={Calibres} fullWidth disabled={props.isEdit}/>
              </Grid>
            ):(<span></span>)
            }
            {isFotopolimero ? (<Grid item sm={0} md={7}/>):(<></>)}
            <Grid item sm={12} md={5} sx={{marginTop:'8px'}}>
              <NumberInput source="valorVenta"  label="Precio Venta" fullWidth defaultValue={isFotopolimero?100:0} />
            </Grid>
          </Grid>
          <CurrencyInputs/>
        </Grid>
      </TabbedForm.Tab>

      <TabbedForm.Tab label='Stock'>
        {record && record?.id && record.alegraId?  <StockStandalone materialID={record.id} /> : <span>Seleccione Primero Referencia, Calibre y Bodega</span>}
      </TabbedForm.Tab>

    </TabbedForm>
  );
};

const GetIdField = () => {
  const referencia = useWatch({ name: 'referencia' });
  const bodega = useWatch({ name: 'bodega' });
  const calibre = useWatch({ name: 'calibre' });
  const idRef = useWatch({ name: 'id' });
  const alegraCategory = useWatch({ name: 'alegraCategory' });
  //const record = useRecordContext();

  
  const { setValue } = useFormContext();
  
  useEffect(() => {
    if(referencia && bodega){
      const findCalibre = Calibres?.find(cal => cal.id === calibre)?.short;
      const findBodega = Sucursales.find(sucursal => sucursal.id === bodega)?.short || bodega;

      if(calibre && alegraCategory === 1){
        setValue('id', `${findBodega}-${referencia}${findCalibre}`)
      }
      else{

        setValue('id', `${findBodega}-${referencia.replace(/\s/g, '_')}`)
      }
    }
    else {
      setValue('id', '')  
    } 
    
  }, [referencia, bodega, calibre, idRef])
  
  return (
    <LabeledCustomItem label='ID' value={idRef} />
  );
}

const AlegraID = (props) => {
  const {getValues, setValue} = useFormContext();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [create, {data: alegraItem,  isLoading, error }] = useCreate();
  const vals = getValues();
  useEffect(() => {
    if(alegraItem){
      setValue('alegraId', alegraItem.id)
      refresh()
    }

  }, [alegraItem])
  useEffect(() => {
    if (vals.id && vals?.alegraCategory){
        setButtonDisabled(false)
       }
  }, [vals.id, vals.alegraCategory])  

  
  const generateItem = (values) => {
    let composedRecord2Alegra = {};
    if (vals.alegraCategory !== 1 && vals.alegraCategory !== 3){
      composedRecord2Alegra = {
        name: vals.id,
        productKey: '73151901',
        itemCategory: {id: vals.alegraCategory},
        inventory: {
          unit: "service"
        },
        description: vals.id + " " + vals.proveedor?.toUpperCase() + " " +vals.bodega,
        price: vals.valorVenta || 100,
        tax: vals['applyIva'] ? 3 : 0,
      }

    }
    else{
      composedRecord2Alegra = {
        name: vals.id,
        itemCategory: {id: vals.alegraCategory},
        inventory: {
            warehouses: [{id: Sucursales.find(item=> item.id === vals.bodega)?.alegraID, initialQuantity: 0, minQuantity: 100000}],
            unit: 'centimeterSquared',
            unitCost: vals.valorTotalCop,
            negativeSale: true
          },
        description: vals.id + " " + vals.proveedor?.toUpperCase() + " " +vals.bodega,
        price: vals.valorVenta || 100,
        tax: vals['applyIva'] ? 3 : 0,
      }
    }
    

    create('alegraItems', {data: composedRecord2Alegra })
  }


  
  return (
    isLoading ? <span>Cargando...</span>:
    (vals && vals.alegraId) ?
      //<LabeledCustomItem label='Alegra ID' value={vals.alegraId}  />
      <NumberInput source='alegraId' label='Alegra ID' />
    :
      <Button variant='contained' onClick={()=>generateItem(record)} label = 'Generar en Alegra' sc={{minWidth:'100%', minHeight:'100px'}} disabled = {buttonDisabled}/> 
  )
}

