import { ExposicionList } from './ExposicionList';
import { ExposicionEdit } from './ExposicionEdit';
import { ExposicionCreate } from './ExposicionCreate';
import { ExposicionShow } from './ExposicionShow';

import { UserExposicionList} from './produccionUsers/UserExposicionList'
import { UserExposicionCreate } from './produccionUsers/UserExposicionCreate';
import { UserExposicionEdit } from './produccionUsers//UserExposicionEdit';
import { UserExposicionShow } from './produccionUsers/UserExposicionShow';

import { OrdenSalidaList } from './ordenproduccion/OrdenSalidaList';
import { OrdenSalidaCreate } from './ordenproduccion/OrdenSalidaCreate';
import { OrdenSalidaEdit } from './ordenproduccion/OrdenSalidaEdit';
import { OrdenSalidaShow } from './ordenproduccion/OrdenSalidaShow';

import { CajaList } from './cajas/CajaList'; 
import { CajaCreate } from './cajas/CajaCreate';
import { CajaEdit } from './cajas/CajaEdit';
import { CajaShow } from './cajas/CajaShow';

import { SalidasList } from './salidas/SalidasList';

const Produccion = {
    ExposicionList: ExposicionList,
    ExposicionEdit: ExposicionEdit,
    ExposicionCreate: ExposicionCreate,
    ExposicionShow: ExposicionShow,
    CajaList: CajaList,
    CajaCreate: CajaCreate,
    CajaEdit: CajaEdit,
    CajaShow: CajaShow,
    OrdenSalidaList: OrdenSalidaList,
    OrdenSalidaCreate: OrdenSalidaCreate,
    OrdenSalidaEdit: OrdenSalidaEdit,
    OrdenSalidaShow: OrdenSalidaShow,
    UserExposicionList: UserExposicionList,    
    UserExposicionCreate: UserExposicionCreate,
    UserExposicionEdit: UserExposicionEdit,
    UserExposicionShow: UserExposicionShow,
    SalidasList: SalidasList,
};

export default Produccion