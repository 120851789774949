import React, { useEffect, useState } from "react";
import { TextInput, useRecordContext,
         ReferenceInput, ArrayInput, 
         SimpleFormIterator,  AutocompleteInput,
          FormDataConsumer, RadioButtonGroupInput, DateTimeInput} from "react-admin";

import { Grid, Typography, Box, Chip } from '@mui/material';
import {useFormContext} from 'react-hook-form'
import CreateProductDialog from '../../productos/components/CreateProductDialog'
import SelectOrderType from './SelectOrderType'
import Montaje from './Montaje'
import { useWatch } from 'react-hook-form'
import { getDueTime } from '../../utils/leadTime'
//window.LOG_LEVEL = 'DEBUG';
//const logger = new Logger('foo');






const OptionRenderer =  ( ) => {
    const record = useRecordContext(); 
    return(
        <Grid container direction='row' justifyContent='space-between' spacing={2}>
            <Grid item xs zeroMinWidth>
                <Typography gutterBottom  component={'div'} noWrap style={{'overflowWrap': 'break-word'}}>
                    <Box  sx={{ width:'50px', maxWidth:'40px' ,marginLeft:'2px', marginRight:'5px'}}>{` ${record.nombre}`}</Box> 
                </Typography>
            </Grid>
            <Grid item>
                <Typography component={'div'}>
                    <Box sx={{fontStyle:'italic', fontWeight:'light', fontSize:'11pt'}}>{`${record.id}` }</Box>
                </Typography>
            </Grid>                     
        </Grid>
    )}

const inputText = choice => (choice ? `(${choice.id})   ${choice.nombre}`:null);

const matchSuggestion = (filterValue, choice) =>  choice.id.toUpperCase().match(filterValue.toUpperCase()) || choice.nombre.toUpperCase().match(filterValue.toUpperCase());
///const getOrdenType = (choice)=>choice.datosFacturacion.precioOrden.map(tipoOrden)

const Main = (props)=>{
    const [client, setClient] = useState()
    const { setValue } = useFormContext()

    const setDate=(date)=>(
        setValue('dueTime', date)
    )

    
    return(
    <FormDataConsumer>
        {
            ({formData, getSource,...rest})=>(
        <Grid container direction='column' alignItems='flex-start'>
            <Grid item lg={8} md={12} container spacing={1} direction='row'>
                <Grid item md={10} lg={6}>
                    <ReferenceInput source='clienteID' reference='Clientes' > 
                        <AutocompleteInput label='Cliente' optionText='razonSocial' variant='outlined' size='small'/>
                    </ReferenceInput>
                </Grid>
                <Grid item sm={2}lg={2}>
                    <TextInput  source="odc" label='Orden de Compra' variant="outlined"/>
                </Grid>
            </Grid>
            {(formData.clienteID?(
            <Grid  item lg={12} container spacing={3} direction='row' alignItems="stretch" >
                <Grid  item md={12} lg={8} container direction='row'  spacing={3} alignItems='space-between'>
                    <Grid item sm={10} lg={9}>
                        <ArrayInput source='productoID' label='Producto(s)'helperText='En caso de co-impresión agregue los productos asociados'>
                            <SimpleFormIterator>
                                <ReferenceInput 
                                    fullWidth 
                                    filter={{productosByCliente: {clienteID: formData.clienteID}}} 
                                    reference='Productos'
                                    {...rest}
                                    > 
                                    <AutocompleteInput helperText='Seleccione el producto relacionado con la orden o cree uno nuevo'
                                        variant='outlined'
                                        label='Producto'                             
                                        filterToQuery={searchText => searchText?({productosByCliente:{clienteID: formData.clienteID}}):{}} 
                                        optionText={<OptionRenderer/>} 
                                        inputText={inputText}
                                        matchSuggestion={matchSuggestion}                            
                                        />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid  item sm={2}  lg={3} >
                        <CreateProductDialog  clienteID={formData.clienteID} isCustomerDisabled={true}  />
                    </Grid>
                </Grid>
                <Grid  item md={12} lg={8} container direction='row'  spacing={3} alignItems='space-between'>
                    <Grid item md={7}>
                        <RadioButtonGroupInput source='prioridad' defaultValue='NORMAL' choices={[
                                                                            {id:'NORMAL', name:'Normal'},
                                                                            {id:'PRIMERA_HORA', name: 'Primera Hora'},
                                                                            {id: 'URGENCIA', name: 'Urgencia'},
                                                                            {id: 'EMERGENCIA', name:'Emergencia'}]} />
                    </Grid>
                    <Grid item md={5}>
                            <SelectOrderType clientId={formData.clienteID} client={client} handleSetClient={setClient}/>
                    </Grid>
                </Grid>
                <Grid  item sm={12} lg={8} container direction='row'  spacing={3} alignItems='space-between'>
                    <Grid item md={7}>
                        <ReferenceInput source='ResponsableActualID' reference='usuarios'>
                            <AutocompleteInput label='Responsable' optionText={choice=>choice ? (`${choice.nombres} ${choice.apellidos}`):('') }  size='small'/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item md={5}>
                        <DueTime data={formData} setDate={setDate}/>
                    </Grid>

                </Grid>
            </Grid>
            ):(<span/>))
            }
            {
                formData.tipoOrden && client && client.maquinas ? (

                <Grid item md={12}>
                    <Montaje {...props} formData={formData} client={client}  />
                </Grid>
                ):(<span/>)
            }
    </Grid>
    )}
    </FormDataConsumer>
    )


}


const DueTime=(props)=>{
    const { data, setDate } = props  
    //const { setValue } = useFormContext()
    let dueTime = ''

    const [oldJobType, setOldJobType] = useState('')
    const [oldPrioridad, setOldPrioridad] = useState('')

    const jobType = useWatch({name:'tipoOrden'})
    const prioridad = useWatch({name:'prioridad'})
    useEffect(
        ()=>{
            if(jobType !== oldJobType || prioridad !== oldPrioridad ){
                //console.log('changed')
                dueTime = getDueTime(data.tipoOrden, data.prioridad)
                setDate(dueTime)
                setOldJobType(jobType)
                setOldPrioridad(prioridad)
            }            
        }
    )
  
    return(
        <DateTimeInput source='dueTime' label='Fecha de Entrega' parse={(v)=>new Date(v)}/>
    )

}

export default Main