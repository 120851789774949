
import React, {useState} from "react";
import { useCreate, useChoicesContext, RadioButtonGroupInput, ReferenceInput, SimpleForm, FormDataConsumer,
    useDeleteMany,AutocompleteInput, DateTimeInput, Filter, useNotify,useRefresh,  useUpdate, TopToolbar, Button,
    useListContext,
    useUpdateMany,
} from "react-admin"

import DateRangePickerField from './common/DateRangePickerField';
import esLocale from "@fullcalendar/core/locales/es";
import { useFormContext } from 'react-hook-form';


import {  CompleteCalendar, getFilterValuesFromInterval } from "@react-admin/ra-calendar";

import {  FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import UserIcon from '@mui/icons-material/PersonOutline';


import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { calculateTurno, groupTurnos } from "./turnos/calculateTurno"


const TiposMarcaciones = [
    { id: 'INICIO_TURNO', name: 'Inicio Turno', short: 'IT' },
    { id: 'INICIO_PAUSA', name: 'Inicio Pausa', short: 'IP' },
    { id: 'FIN_PAUSA', name: 'Fin Pausa', short: 'FP' },
    { id: 'FIN_TURNO', name: 'Fin Turno', short: 'FT' }
];

const UserInput = (props) => {
    const { allChoices } = useChoicesContext();
    const { setValue } = useFormContext();
    const filterToQuery = searchText => ({ or: [{ nombres: { matchPhrasePrefix: searchText } }, { apellidos: { matchPhrasePrefix: searchText } }] });

    const handleAutocompleteChange = (event) => {
        let userName = allChoices.filter(r => r.id === event)[0]?.nombres + ' ' + allChoices.filter(r => r.id === event)[0]?.apellidos;

        if (event) {
            setValue('userName', userName);
        }
    }
    return (
        <AutocompleteInput
            label="Usuario"
            optionText={(r) => (`${r?.nombres} ${r?.apellidos}`)}
            sx={{ width: 300 }}
            onChange={handleAutocompleteChange}
            filterToQuery={filterToQuery}
            required
        />
    )
}

const CalendarFilterSidebar = () => {   

    const { data } = useListContext();
    const { minVal , setMinValue} = useState({})
    const { maxVal , setMaxValue } = useState({})
    const [range, setRange] = useState({});
      
      // define a function to update the range state
      const handleRangeChange = (dates) => {
        //console.log("Handling range changeeeee--",  dates.startDate)
        setRange({ startDate: dates.startDate, endDate: dates.endDate });
      };
      

    const uniqueData = removeDuplicates(data, 'usuarioId'); 

    return(
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
        <CardContent>            
            <FilterList label="Fechas">
                <Filter >
                    <DateRangePickerField alwaysOn
                            source="fechaHora"
                            handleRangeChange={handleRangeChange}
                        />
                </Filter>
            </FilterList>
            <FilterList label="Tipos" >
                <FilterListItem label="Inicio Turno" value={{ type: {eq:'INICIO_TURNO'} }} />
                <FilterListItem label="Inicio Pausa" value={{ type: {eq:'INICIO_PAUSA'} }} />
                <FilterListItem label="Fin Pausa" value={{ type: {eq:'FIN_PAUSA'} }} />
                <FilterListItem label="Fin Turno" value={{ type: {eq:'FIN_TURNO'} }} />
            </FilterList>
            <FilterList label="Turno Asignado">
                <FilterListItem label="Si" value={{ turnoID: {exists:true} }} />
                <FilterListItem label="No" value={{ turnoID: {exists:false} }} />
            </FilterList>
            <FilterList label="Usuarios" icon={<UserIcon/>}>{
                    uniqueData?uniqueData.map((item)=>(
                            <FilterListItem key={item.usuarioId} label={item.userName}  value={{ usuarioId: {eq:item.usuarioId} }}  />
                    )): <FilterListItem  label="Sin Usuarios en el Rango" />
                }
            </FilterList>

        </CardContent>
    </Card>
)
}

const removeDuplicates = (data, uniqueKey) => {
    return data ? data.reduce((accumulator, current) => {
        if (!accumulator.some((item) => item[uniqueKey] === current[uniqueKey])) {
            if (current[uniqueKey]) {
                accumulator.push(current);
            }
        }
        return accumulator;
    }, []) : [];
};

const MarcacionActions = () => {
    const {data, loading, setFilters} = useListContext();
    const [create] = useCreate();
    const [update] = useUpdate();
    const refresh = useRefresh();
    const [deleteMany] = useDeleteMany();
    const [updateMany] = useUpdateMany();

    const handleGrouping = (data, allMarcaciones) => {
        let filteredData = [];
        console.log("allMarcaciones inside hanfdle grouping")
        if(!allMarcaciones){
            filteredData = data.filter((item)=>item.turnoID === null)
        } else {
            filteredData = data
        }
        const [turnos, noClasificadas] = groupTurnos(filteredData);
        console.log("turnos")
        console.log(turnos)
        console.log("noClasificadas")
        console.log(noClasificadas)
        let turnosCalculados= []
        turnos.map((turno)=>{  
            const calculatedWorkshift = calculateTurno(turno, "8.2")
            console.log("calculatedWorkshift")
            console.log(calculatedWorkshift)
            create('turnos', {data: calculatedWorkshift})
            for (let index = 0; index < calculatedWorkshift?.marcacionesIds?.length; index++) {
                const element = calculatedWorkshift.marcacionesIds[index];
                console.log(element)
                update('marcacions', {id: element, data: 
                    {
                    id: element,
                    turnoID: calculatedWorkshift.id
                }})
            }
            turnosCalculados.push(calculatedWorkshift)}
        )
        refresh();

    }

    const handleDelete = (data) => {
        const turnoIDs = data.map((item)=>item.turnoID)
        const TurnosInData = [...new Set(turnoIDs)];
        deleteMany('turnos', {ids: TurnosInData})
        let ids = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            ids.push(element.id)
        }
        updateMany('marcacions', {ids: ids , data: {turnoID: null}})
        refresh();
    }

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div style={{ textAlign: 'left', width:'100%' }}>

        <TopToolbar /*align buttons to left*/ style={{justifySelf: "flex-start"}}>
            <Button
                label="Agrupar Todas las Marcaciones"
                onClick={()=>handleGrouping(data, true)}
                disabled={loading}
                variant="outlined"
            />
            <Button
                label="Agrupar Marcaciones Sin Turno"
                onClick={()=>handleGrouping(data, false)}
                disabled={loading}
                variant="outlined"
            />
            <Button
                color="error"
                label="Eliminar Turnos Asignados"
                onClick={()=>handleDelete(data)}
                disabled={loading}
                variant="outlined"
            />
            <Button variant="outlined" onClick={()=>resetFilter} label="Limpiar Filtros">
                
            </Button>
        </TopToolbar>
        </div>
    );
};

export const MarcacionList = () => {
    const refresh = useRefresh();
    const notify = useNotify();

    return (
        <CompleteCalendar
            CalendarProps={{
                locale: esLocale,
                convertToEvent: (event) => ({
                    id: String(event.id),
                    title: event.userName,
                    start: event.fechaHora,
                    backgroundColor: event.turnoID ? "#05e605" : "#e30202",
                }),
            }}
            ListProps={{
                queryOptions: { meta: { searchable: true } },
                filterDefaultValues: getFilterValuesFromInterval(),
                aside: <CalendarFilterSidebar />,
                actions: <MarcacionActions/>,
                perPage: 999,
                sort: { field: 'fechaHora', order: 'ASC' },
            }}
            EditDialogProps={{
                mutationMode: 'pessimistic',    
            }}
            CreateDialogProps={{
                mutationMode: 'pessimistic',
                transform: (data) => ({
                    fechaHora: data.fechaHora ? data.fechaHora : new Date(data.end).toISOString(),
                    usuarioId: data.usuarioId,
                    userName: data.userName,
                    type: data.type
                })
            }}
        >
            <SimpleForm>
                <FormDataConsumer>
                    {({ formData }) => (
                        formData && formData.fechaHora ?
                            <DateTimeInput source="fechaHora" label="Hora" sx={{ width: 300 }} required /> :
                            <DateTimeInput source="end" label="Hora" sx={{ width: 300 }} required />
                    )}
                </FormDataConsumer>
                <ReferenceInput source="usuarioId" reference="usuarios" required queryOptions={{ meta: { searchable: true } }}>
                    <UserInput />
                </ReferenceInput>
                <RadioButtonGroupInput source='type' choices={TiposMarcaciones} required />
            </SimpleForm>
        </CompleteCalendar>
    );
}

    
// export const MarcacionEdit = (props)=>{
//     const editTransform = (data)=> {
//     return(
//         {   ...data,
//             id: data.id,
//             fechaHora: data.fechaHora,
//             usuarioId: data.usuarioId,
//             userName: data.userName || null,
//             type:data.type
//             }
//         )
//     }
//     return(
//     <EditDialog  transform = {editTransform} redirect="list" {...props}>
//         <SimpleForm>
//             <DateTimeInput source="fechaHora" label="Hora" sx={{ width: 300 }} required/>
//             <ReferenceInput source="usuarioId"  reference="usuarios" >
//                 <UserInput />
//             </ReferenceInput>
//             <RadioButtonGroupInput source='type' choices={TiposMarcaciones} required/>
//         </SimpleForm>
//     </EditDialog>        
//     )
//}










// export const MarcacionList = ()=>{
//     return(
//     <>
//         <List
//             filterDefaultValues={customGetFilterValues()}
//             aside = {<CalendarFilterSidebar />}
//             perPage={1000}
//             actions={<MarcacionActions/>}
//             pagination={false}
//         >
//             <Calendar
//                 locale ={esLocale}
//                 convertToEvent= {(event) => (
                    
//                     {
//                     id: String(event.id),
//                     title: event.userName,
//                     start: event.fechaHora,
//                     backgroundColor: event.turnoID ? "#05e605":"#e30202",
//                     //backgroundColor: colorForCategory(event.category),
//                     //borderColor: colorForCategory(event.category),
//                     //editable: event.can_edit,
//                     })}
//             />
//         </List>
//         {/*Button to save Turnos*/}
//     <MarcacionCreate/>
//     <MarcacionEdit/>
//     </>
//     )
// }

//MarcacionActions is a custom button which calls autogrouping function from ./calculateTurnos.js to be able to create turnos based on marcaciones and adds a turno id to each marcacion
//This button is only enabled when there is a filter applied to the list and the dateRange for the filter  is less than 30 days

/*
export const MarcacionList = ()=>{
    const notify = useNotify();
    const refresh = useRefresh();
  
    const handleEventReceived = (event) => {
        console.log(event)
        notify("Se ha agregado una Marcación");
        refresh();
    };
    return(
        <List >
            <Datagrid >
                <ReferenceField label = 'Usuario' source="usuarioId"  reference="usuarios">
                <>
                    <TextField source="nombres" />{' '}
                    <TextField source="apellidos" />
                </>
                </ReferenceField>
                <DateField source="fechaHora" label="Hora Marcación"   showTime={true}/>
                <ChipCustomField source="type" label="tipo" sortable={false}/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}
*/

//onEventReceived={handleEventReceived} 
