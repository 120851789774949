import * as React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { useInput, Labeled } from 'react-admin';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  maxHeight: '40px',
  size: 'small'
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '& .MuiToggleButton-label': {
    pointerEvents: 'none',
  },
}));

const ButtonGroupInput = (props) => {
    const {
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        labels,
        ...rest
    } = props;
    const { field } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        defaultValue: "",
        validate,
        ...rest,
    });

    const handleToggleChange = (event, newValue) => {
        // Parse the stringified value back to an object
        const parsedValue = newValue ? JSON.parse(newValue) : '';

        // Deselect the button if it's clicked again
        if (JSON.stringify(field.value) === newValue) {
            field.onChange(''); // Deselect
        } else {
            field.onChange(parsedValue || ''); // Set to the new value
        }
    };
    
    return (
        <Labeled label="">
            <StyledToggleButtonGroup
                {...field}
                value={JSON.stringify(field.value)}  // Stringify the current value
                onChange={handleToggleChange}
                exclusive
            >
                <StyledToggleButton key={'Emitida'} value={JSON.stringify({estado:{ne: "Facturada"}, and: {estado: {ne: "Anulada"}}})}>
                    Pendientes
                </StyledToggleButton>
                <StyledToggleButton key={"Facutrada"} value={JSON.stringify({estado:{eq:'Facturada'}})}>
                    Facturadas
                </StyledToggleButton>
                <StyledToggleButton key={"Anuladas"} value={JSON.stringify({estado:{eq:'Anulada'}})}>
                    Anuladas
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        </Labeled>
    );
};

export default ButtonGroupInput;
