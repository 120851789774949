import { 
    Create, useNotify, 
    EditGuesser, useRefresh,
    Datagrid, TextField,
    TextInput,
    SimpleForm,
    ReferenceField,
    Edit
} from "react-admin"

import { get } from 'lodash';
import { ListLive, RealTimeList } from "@react-admin/ra-realtime";



export const HuellasList = ()=>{
    const notify = useNotify();
    const refresh = useRefresh();
  
    const handleEventReceived = (event) => {
        const count = get(event, "payload.ids.length", 1);
        notify("ra-realtime.notification.list.refreshed", "info", { count });
        refresh();
    };
    return(
        <ListLive onEventReceived={handleEventReceived} >
            <Datagrid >
                <ReferenceField source="userID" reference="usuarios" label="Usuario" link="show">
                    <TextField source="nombres" />
                    <span>{" "}</span>
                    <TextField source="apellidos" />
                </ReferenceField>
                <TextField source="finger" />
                <TextField source="device" />
            </Datagrid>
        </ListLive>
    )
}