import React from 'react';
import { List, TextField, NumberInput, DateField, Datagrid, ReferenceField,SimpleForm, ReferenceInput ,DateInput, SelectInput, Edit, Create} from 'react-admin';




export const PeriodosList = () => {
    return (
        <List resource="periodos" aside='edit'>
            <Datagrid bulkActionButtons={false} rowClick='edit'>
                <TextField source="id" />
                <ReferenceField source="userId" reference="usuarios" label="Usuario" >
                    <TextField source="nombres" />
                    <span>{" "}</span>
                    <TextField source="apellidos" />
                </ReferenceField>
                <DateField source="inicio" showTime={false} />
                <DateField source="fin" showTime={false} />
            </Datagrid>
        </List>
    );
}

export const PeriodosEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceField source="userId" reference="usuarios" label="Usuario" >
                    <TextField optionText="nombres" />
                    <TextField optionText="apellidos" />
                </ReferenceField>
                <NumberInput source="totalHoras" />
                <NumberInput source="totalPausas" />
                <NumberInput source="totalHorasLaboradas" />
                <NumberInput source="totalTiempoLibre" />
                <NumberInput source="totalHERecargos" />
                <NumberInput source="diasLaborables" />
                <NumberInput source="diasLaborados" />
                <NumberInput source="horasLaborables" />
                <NumberInput source="horasLaboradas" />
                <NumberInput source="diasIncapacidad" />
                <NumberInput source="diasVacaciones" />
                <NumberInput source="diasLicenciaRemunerada" />
                <NumberInput source="diasLicenciaNoRemunerada" />
                <DateInput source="inicio" showTime={false} />
                <DateInput source="fin" showTime={false} />
                
            </SimpleForm>
        </Edit>
    );
}


/*
type Periodo @model @auth(rules: [{allow: public}]){
  id: ID!
  inicio: AWSDateTime
  fin: AWSDateTime
  userId: ID @index(name: "byUser")
  totalHoras: Float
  totalPausas: Float
  totalHorasLaboradas: Float
  totalTiempoLibre: Float
  totalHERecargos:Float
  diasLaborables: Int
  diasLaborados: Int
  horasLaborables: Float
  horasLaboradas: Float
  diasIncapacidad: Int 
  diasVacaciones: Int
  diasLicenciaRemunerada: Int
  diasLicenciaNoRemunerada: Int
  Turnos : [Turno] @hasMany(indexName: "byPeriodo", fields: ["id"])
}

type  HorasAcumuladas @model @auth(rules: [{allow: public}]){
  tiempoLibre: Float
  tiempoPendiente: Float
  tiempoExtra: Float
  userId: ID! @primaryKey
}
*/