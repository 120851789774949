/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateContactoCliente = /* GraphQL */ `
  subscription OnCreateContactoCliente(
    $filter: ModelSubscriptionContactoClienteFilterInput
  ) {
    onCreateContactoCliente(filter: $filter) {
      id
      nombre
      email
      cargo
      enviarFactura
      rol
      cel
      clienteID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContactoCliente = /* GraphQL */ `
  subscription OnUpdateContactoCliente(
    $filter: ModelSubscriptionContactoClienteFilterInput
  ) {
    onUpdateContactoCliente(filter: $filter) {
      id
      nombre
      email
      cargo
      enviarFactura
      rol
      cel
      clienteID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContactoCliente = /* GraphQL */ `
  subscription OnDeleteContactoCliente(
    $filter: ModelSubscriptionContactoClienteFilterInput
  ) {
    onDeleteContactoCliente(filter: $filter) {
      id
      nombre
      email
      cargo
      enviarFactura
      rol
      cel
      clienteID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUsuario = /* GraphQL */ `
  subscription OnCreateUsuario($filter: ModelSubscriptionUsuarioFilterInput) {
    onCreateUsuario(filter: $filter) {
      id
      cedula
      nombres
      apellidos
      nombreCompleto
      email
      roles
      cognitoUserId
      devices
      sedes
      huellas {
        items {
          id
          userID
          userName
          finger
          device
          fingerDeviceID
          registered_at
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      pin
      alegraVendedorId
      fingerDevices
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUsuario = /* GraphQL */ `
  subscription OnUpdateUsuario($filter: ModelSubscriptionUsuarioFilterInput) {
    onUpdateUsuario(filter: $filter) {
      id
      cedula
      nombres
      apellidos
      nombreCompleto
      email
      roles
      cognitoUserId
      devices
      sedes
      huellas {
        items {
          id
          userID
          userName
          finger
          device
          fingerDeviceID
          registered_at
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      pin
      alegraVendedorId
      fingerDevices
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUsuario = /* GraphQL */ `
  subscription OnDeleteUsuario($filter: ModelSubscriptionUsuarioFilterInput) {
    onDeleteUsuario(filter: $filter) {
      id
      cedula
      nombres
      apellidos
      nombreCompleto
      email
      roles
      cognitoUserId
      devices
      sedes
      huellas {
        items {
          id
          userID
          userName
          finger
          device
          fingerDeviceID
          registered_at
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      pin
      alegraVendedorId
      fingerDevices
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHuella = /* GraphQL */ `
  subscription OnCreateHuella($filter: ModelSubscriptionHuellaFilterInput) {
    onCreateHuella(filter: $filter) {
      id
      userID
      userName
      finger
      device
      fingerDeviceID
      registered_at
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHuella = /* GraphQL */ `
  subscription OnUpdateHuella($filter: ModelSubscriptionHuellaFilterInput) {
    onUpdateHuella(filter: $filter) {
      id
      userID
      userName
      finger
      device
      fingerDeviceID
      registered_at
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHuella = /* GraphQL */ `
  subscription OnDeleteHuella($filter: ModelSubscriptionHuellaFilterInput) {
    onDeleteHuella(filter: $filter) {
      id
      userID
      userName
      finger
      device
      fingerDeviceID
      registered_at
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCliente = /* GraphQL */ `
  subscription OnCreateCliente($filter: ModelSubscriptionClienteFilterInput) {
    onCreateCliente(filter: $filter) {
      id
      alegraId
      nit_cedula
      codigo
      ciudad
      sector
      marcas {
        items {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      digitoVerificacion
      estado
      daysToPay
      daysAvgToPay
      maquinas {
        nombre
        tipoDesarrollo
        tipoMaquina
        paso
        anchoBanda
        velocidad
        plancha
        lpis
      }
      razonSocial
      departamento
      pais
      cotiza
      sucursalEncargada
      direccion
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      tipoDocumento
      datosFacturacion {
        formaPago
        iva
        variacionTRM
        validarCxC
        retefuenteType
        retefuentePorc
        reteIvaBool
        reteIcaPorc
        reteIcaBool
        exportacion
        precioOrden {
          tipoOrden
          unidades
          valor
        }
        regimenAdq
        sobremedida
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        retencionesIDs
        tipoPersonaAdq
        emailFE
      }
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      ordenes {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          odc
          clienteID
          prioridad
          tipoOrden
          cooimpreso
          calibre
          ResponsableActualID
          estado
          maquina
          montaje {
            pistas
            repeticiones
            rodillo
            radio
            paso
            gapPistas
            gapRepeticiones
            anchoBobina
            desarrollo
            truncado
            enfrentado
            cantidadTruncado
            prodAncho
            prodAlto
            tipoCorte
            posicionSalida
            embobinado
            email
          }
          numeroOPant
          versionOPant
          dueTime
          productoID
          producto {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          nombreAnt
          rip {
            calibre
            elongacion
            lpi
            emulsion
            colores {
              nombre
              libro
              angulo
              index
              punto
              lpi
              bumpUp
              microCell
            }
            marcas
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      indicativoPais
      indicativoCiudad
      telefono1
      telefono2
      observaciones
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCliente = /* GraphQL */ `
  subscription OnUpdateCliente($filter: ModelSubscriptionClienteFilterInput) {
    onUpdateCliente(filter: $filter) {
      id
      alegraId
      nit_cedula
      codigo
      ciudad
      sector
      marcas {
        items {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      digitoVerificacion
      estado
      daysToPay
      daysAvgToPay
      maquinas {
        nombre
        tipoDesarrollo
        tipoMaquina
        paso
        anchoBanda
        velocidad
        plancha
        lpis
      }
      razonSocial
      departamento
      pais
      cotiza
      sucursalEncargada
      direccion
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      tipoDocumento
      datosFacturacion {
        formaPago
        iva
        variacionTRM
        validarCxC
        retefuenteType
        retefuentePorc
        reteIvaBool
        reteIcaPorc
        reteIcaBool
        exportacion
        precioOrden {
          tipoOrden
          unidades
          valor
        }
        regimenAdq
        sobremedida
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        retencionesIDs
        tipoPersonaAdq
        emailFE
      }
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      ordenes {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          odc
          clienteID
          prioridad
          tipoOrden
          cooimpreso
          calibre
          ResponsableActualID
          estado
          maquina
          montaje {
            pistas
            repeticiones
            rodillo
            radio
            paso
            gapPistas
            gapRepeticiones
            anchoBobina
            desarrollo
            truncado
            enfrentado
            cantidadTruncado
            prodAncho
            prodAlto
            tipoCorte
            posicionSalida
            embobinado
            email
          }
          numeroOPant
          versionOPant
          dueTime
          productoID
          producto {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          nombreAnt
          rip {
            calibre
            elongacion
            lpi
            emulsion
            colores {
              nombre
              libro
              angulo
              index
              punto
              lpi
              bumpUp
              microCell
            }
            marcas
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      indicativoPais
      indicativoCiudad
      telefono1
      telefono2
      observaciones
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCliente = /* GraphQL */ `
  subscription OnDeleteCliente($filter: ModelSubscriptionClienteFilterInput) {
    onDeleteCliente(filter: $filter) {
      id
      alegraId
      nit_cedula
      codigo
      ciudad
      sector
      marcas {
        items {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      digitoVerificacion
      estado
      daysToPay
      daysAvgToPay
      maquinas {
        nombre
        tipoDesarrollo
        tipoMaquina
        paso
        anchoBanda
        velocidad
        plancha
        lpis
      }
      razonSocial
      departamento
      pais
      cotiza
      sucursalEncargada
      direccion
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      tipoDocumento
      datosFacturacion {
        formaPago
        iva
        variacionTRM
        validarCxC
        retefuenteType
        retefuentePorc
        reteIvaBool
        reteIcaPorc
        reteIcaBool
        exportacion
        precioOrden {
          tipoOrden
          unidades
          valor
        }
        regimenAdq
        sobremedida
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        retencionesIDs
        tipoPersonaAdq
        emailFE
      }
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      ordenes {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          odc
          clienteID
          prioridad
          tipoOrden
          cooimpreso
          calibre
          ResponsableActualID
          estado
          maquina
          montaje {
            pistas
            repeticiones
            rodillo
            radio
            paso
            gapPistas
            gapRepeticiones
            anchoBobina
            desarrollo
            truncado
            enfrentado
            cantidadTruncado
            prodAncho
            prodAlto
            tipoCorte
            posicionSalida
            embobinado
            email
          }
          numeroOPant
          versionOPant
          dueTime
          productoID
          producto {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          nombreAnt
          rip {
            calibre
            elongacion
            lpi
            emulsion
            colores {
              nombre
              libro
              angulo
              index
              punto
              lpi
              bumpUp
              microCell
            }
            marcas
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      indicativoPais
      indicativoCiudad
      telefono1
      telefono2
      observaciones
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMarca = /* GraphQL */ `
  subscription OnCreateMarca($filter: ModelSubscriptionMarcaFilterInput) {
    onCreateMarca(filter: $filter) {
      id
      nombre
      productor
      clienteID
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarca = /* GraphQL */ `
  subscription OnUpdateMarca($filter: ModelSubscriptionMarcaFilterInput) {
    onUpdateMarca(filter: $filter) {
      id
      nombre
      productor
      clienteID
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarca = /* GraphQL */ `
  subscription OnDeleteMarca($filter: ModelSubscriptionMarcaFilterInput) {
    onDeleteMarca(filter: $filter) {
      id
      nombre
      productor
      clienteID
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProducto = /* GraphQL */ `
  subscription OnCreateProducto($filter: ModelSubscriptionProductoFilterInput) {
    onCreateProducto(filter: $filter) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      clienteID
      nombre
      marca {
        id
        nombre
        productor
        clienteID
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      marcaID
      ordenes {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productoNuevo
      productoExistente
      productoRefID
      mainFile {
        bucket
        region
        key
      }
      mainFileThumbnail {
        bucket
        region
        key
      }
      auxFiles {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      marcaProductosId
    }
  }
`;
export const onUpdateProducto = /* GraphQL */ `
  subscription OnUpdateProducto($filter: ModelSubscriptionProductoFilterInput) {
    onUpdateProducto(filter: $filter) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      clienteID
      nombre
      marca {
        id
        nombre
        productor
        clienteID
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      marcaID
      ordenes {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productoNuevo
      productoExistente
      productoRefID
      mainFile {
        bucket
        region
        key
      }
      mainFileThumbnail {
        bucket
        region
        key
      }
      auxFiles {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      marcaProductosId
    }
  }
`;
export const onDeleteProducto = /* GraphQL */ `
  subscription OnDeleteProducto($filter: ModelSubscriptionProductoFilterInput) {
    onDeleteProducto(filter: $filter) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      clienteID
      nombre
      marca {
        id
        nombre
        productor
        clienteID
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      marcaID
      ordenes {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productoNuevo
      productoExistente
      productoRefID
      mainFile {
        bucket
        region
        key
      }
      mainFileThumbnail {
        bucket
        region
        key
      }
      auxFiles {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      marcaProductosId
    }
  }
`;
export const onCreateOrden = /* GraphQL */ `
  subscription OnCreateOrden($filter: ModelSubscriptionOrdenFilterInput) {
    onCreateOrden(filter: $filter) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      odc
      clienteID
      prioridad
      tipoOrden
      cooimpreso
      calibre
      ResponsableActualID
      estado
      maquina
      montaje {
        pistas
        repeticiones
        rodillo
        radio
        paso
        gapPistas
        gapRepeticiones
        anchoBobina
        desarrollo
        truncado
        enfrentado
        cantidadTruncado
        prodAncho
        prodAlto
        tipoCorte
        posicionSalida
        embobinado
        email
      }
      numeroOPant
      versionOPant
      dueTime
      productoID
      producto {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      nombreAnt
      rip {
        calibre
        elongacion
        lpi
        emulsion
        colores {
          nombre
          libro
          angulo
          index
          punto
          lpi
          bumpUp
          microCell
        }
        marcas
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrden = /* GraphQL */ `
  subscription OnUpdateOrden($filter: ModelSubscriptionOrdenFilterInput) {
    onUpdateOrden(filter: $filter) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      odc
      clienteID
      prioridad
      tipoOrden
      cooimpreso
      calibre
      ResponsableActualID
      estado
      maquina
      montaje {
        pistas
        repeticiones
        rodillo
        radio
        paso
        gapPistas
        gapRepeticiones
        anchoBobina
        desarrollo
        truncado
        enfrentado
        cantidadTruncado
        prodAncho
        prodAlto
        tipoCorte
        posicionSalida
        embobinado
        email
      }
      numeroOPant
      versionOPant
      dueTime
      productoID
      producto {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      nombreAnt
      rip {
        calibre
        elongacion
        lpi
        emulsion
        colores {
          nombre
          libro
          angulo
          index
          punto
          lpi
          bumpUp
          microCell
        }
        marcas
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrden = /* GraphQL */ `
  subscription OnDeleteOrden($filter: ModelSubscriptionOrdenFilterInput) {
    onDeleteOrden(filter: $filter) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      odc
      clienteID
      prioridad
      tipoOrden
      cooimpreso
      calibre
      ResponsableActualID
      estado
      maquina
      montaje {
        pistas
        repeticiones
        rodillo
        radio
        paso
        gapPistas
        gapRepeticiones
        anchoBobina
        desarrollo
        truncado
        enfrentado
        cantidadTruncado
        prodAncho
        prodAlto
        tipoCorte
        posicionSalida
        embobinado
        email
      }
      numeroOPant
      versionOPant
      dueTime
      productoID
      producto {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      nombreAnt
      rip {
        calibre
        elongacion
        lpi
        emulsion
        colores {
          nombre
          libro
          angulo
          index
          punto
          lpi
          bumpUp
          microCell
        }
        marcas
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRemission = /* GraphQL */ `
  subscription OnCreateRemission(
    $filter: ModelSubscriptionRemissionFilterInput
  ) {
    onCreateRemission(filter: $filter) {
      id
      alegraId
      alegraNumeracion
      formaPago
      vendedorID
      emails
      ciudadId
      vendedorAlegraId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      observaciones
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRemission = /* GraphQL */ `
  subscription OnUpdateRemission(
    $filter: ModelSubscriptionRemissionFilterInput
  ) {
    onUpdateRemission(filter: $filter) {
      id
      alegraId
      alegraNumeracion
      formaPago
      vendedorID
      emails
      ciudadId
      vendedorAlegraId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      observaciones
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRemission = /* GraphQL */ `
  subscription OnDeleteRemission(
    $filter: ModelSubscriptionRemissionFilterInput
  ) {
    onDeleteRemission(filter: $filter) {
      id
      alegraId
      alegraNumeracion
      formaPago
      vendedorID
      emails
      ciudadId
      vendedorAlegraId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      observaciones
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCotizacion = /* GraphQL */ `
  subscription OnCreateCotizacion(
    $filter: ModelSubscriptionCotizacionFilterInput
  ) {
    onCreateCotizacion(filter: $filter) {
      id
      alegraId
      emails
      consecutivo
      vendedorID
      ciudadId
      vendedorAlegraId
      pagoTotal
      valorPago
      valorPendiente
      estado
      formaPago
      fechaCrea
      fechaVence
      fechaEdita
      fechaPagado
      fechaAbono
      abonos {
        valor
        fecha
        usuarioID
      }
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      observaciones
      usuarioID
      usuarioEdita
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCotizacion = /* GraphQL */ `
  subscription OnUpdateCotizacion(
    $filter: ModelSubscriptionCotizacionFilterInput
  ) {
    onUpdateCotizacion(filter: $filter) {
      id
      alegraId
      emails
      consecutivo
      vendedorID
      ciudadId
      vendedorAlegraId
      pagoTotal
      valorPago
      valorPendiente
      estado
      formaPago
      fechaCrea
      fechaVence
      fechaEdita
      fechaPagado
      fechaAbono
      abonos {
        valor
        fecha
        usuarioID
      }
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      observaciones
      usuarioID
      usuarioEdita
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCotizacion = /* GraphQL */ `
  subscription OnDeleteCotizacion(
    $filter: ModelSubscriptionCotizacionFilterInput
  ) {
    onDeleteCotizacion(filter: $filter) {
      id
      alegraId
      emails
      consecutivo
      vendedorID
      ciudadId
      vendedorAlegraId
      pagoTotal
      valorPago
      valorPendiente
      estado
      formaPago
      fechaCrea
      fechaVence
      fechaEdita
      fechaPagado
      fechaAbono
      abonos {
        valor
        fecha
        usuarioID
      }
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      observaciones
      usuarioID
      usuarioEdita
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductFact = /* GraphQL */ `
  subscription OnCreateProductFact(
    $filter: ModelSubscriptionProductFactFilterInput
  ) {
    onCreateProductFact(filter: $filter) {
      id
      idItem
      ciudad
      clienteID
      clienteName
      remisionID
      remisionNro
      remision {
        id
        alegraId
        alegraNumeracion
        formaPago
        vendedorID
        emails
        ciudadId
        vendedorAlegraId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        observaciones
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cotizaNro
      cotizaID
      cotiza {
        id
        alegraId
        emails
        consecutivo
        vendedorID
        ciudadId
        vendedorAlegraId
        pagoTotal
        valorPago
        valorPendiente
        estado
        formaPago
        fechaCrea
        fechaVence
        fechaEdita
        fechaPagado
        fechaAbono
        abonos {
          valor
          fecha
          usuarioID
        }
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        observaciones
        usuarioID
        usuarioEdita
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      facturaNro
      facturaID
      factura {
        id
        remisionesIDs
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        fecha
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        createdAt
        updatedAt
      }
      odc
      alegraItemId
      orden
      iva
      type
      plateType
      material
      calibre
      ordenVersion
      descripcion
      valorUnitario
      version
      ancho
      largo
      cantidad
      cajaNro
      caja
      area
      valorTotal
      vendedorID
      pagado
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductFact = /* GraphQL */ `
  subscription OnUpdateProductFact(
    $filter: ModelSubscriptionProductFactFilterInput
  ) {
    onUpdateProductFact(filter: $filter) {
      id
      idItem
      ciudad
      clienteID
      clienteName
      remisionID
      remisionNro
      remision {
        id
        alegraId
        alegraNumeracion
        formaPago
        vendedorID
        emails
        ciudadId
        vendedorAlegraId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        observaciones
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cotizaNro
      cotizaID
      cotiza {
        id
        alegraId
        emails
        consecutivo
        vendedorID
        ciudadId
        vendedorAlegraId
        pagoTotal
        valorPago
        valorPendiente
        estado
        formaPago
        fechaCrea
        fechaVence
        fechaEdita
        fechaPagado
        fechaAbono
        abonos {
          valor
          fecha
          usuarioID
        }
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        observaciones
        usuarioID
        usuarioEdita
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      facturaNro
      facturaID
      factura {
        id
        remisionesIDs
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        fecha
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        createdAt
        updatedAt
      }
      odc
      alegraItemId
      orden
      iva
      type
      plateType
      material
      calibre
      ordenVersion
      descripcion
      valorUnitario
      version
      ancho
      largo
      cantidad
      cajaNro
      caja
      area
      valorTotal
      vendedorID
      pagado
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductFact = /* GraphQL */ `
  subscription OnDeleteProductFact(
    $filter: ModelSubscriptionProductFactFilterInput
  ) {
    onDeleteProductFact(filter: $filter) {
      id
      idItem
      ciudad
      clienteID
      clienteName
      remisionID
      remisionNro
      remision {
        id
        alegraId
        alegraNumeracion
        formaPago
        vendedorID
        emails
        ciudadId
        vendedorAlegraId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        observaciones
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cotizaNro
      cotizaID
      cotiza {
        id
        alegraId
        emails
        consecutivo
        vendedorID
        ciudadId
        vendedorAlegraId
        pagoTotal
        valorPago
        valorPendiente
        estado
        formaPago
        fechaCrea
        fechaVence
        fechaEdita
        fechaPagado
        fechaAbono
        abonos {
          valor
          fecha
          usuarioID
        }
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        observaciones
        usuarioID
        usuarioEdita
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      facturaNro
      facturaID
      factura {
        id
        remisionesIDs
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        fecha
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              fecha
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        createdAt
        updatedAt
      }
      odc
      alegraItemId
      orden
      iva
      type
      plateType
      material
      calibre
      ordenVersion
      descripcion
      valorUnitario
      version
      ancho
      largo
      cantidad
      cajaNro
      caja
      area
      valorTotal
      vendedorID
      pagado
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFactura = /* GraphQL */ `
  subscription OnCreateFactura($filter: ModelSubscriptionFacturaFilterInput) {
    onCreateFactura(filter: $filter) {
      id
      remisionesIDs
      alegraId
      formaPago
      emails
      alegraNumeracion
      ciudadId
      vendedorID
      vendedorAlegraId
      clienteId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      applyIva
      clienteName
      observaciones
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      fecha
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      retenciones {
        id
        name
        type
        percentage
        valor
        valorBase
      }
      totalSinRetenciones
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFactura = /* GraphQL */ `
  subscription OnUpdateFactura($filter: ModelSubscriptionFacturaFilterInput) {
    onUpdateFactura(filter: $filter) {
      id
      remisionesIDs
      alegraId
      formaPago
      emails
      alegraNumeracion
      ciudadId
      vendedorID
      vendedorAlegraId
      clienteId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      applyIva
      clienteName
      observaciones
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      fecha
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      retenciones {
        id
        name
        type
        percentage
        valor
        valorBase
      }
      totalSinRetenciones
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFactura = /* GraphQL */ `
  subscription OnDeleteFactura($filter: ModelSubscriptionFacturaFilterInput) {
    onDeleteFactura(filter: $filter) {
      id
      remisionesIDs
      alegraId
      formaPago
      emails
      alegraNumeracion
      ciudadId
      vendedorID
      vendedorAlegraId
      clienteId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      applyIva
      clienteName
      observaciones
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      fecha
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            fecha
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      retenciones {
        id
        name
        type
        percentage
        valor
        valorBase
      }
      totalSinRetenciones
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onCreateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onUpdateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo($filter: ModelSubscriptionTodoFilterInput) {
    onDeleteTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMarcacion = /* GraphQL */ `
  subscription OnCreateMarcacion(
    $filter: ModelSubscriptionMarcacionFilterInput
  ) {
    onCreateMarcacion(filter: $filter) {
      id
      fechaHora
      usuarioId
      type
      huellaId
      userName
      device
      turnoID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarcacion = /* GraphQL */ `
  subscription OnUpdateMarcacion(
    $filter: ModelSubscriptionMarcacionFilterInput
  ) {
    onUpdateMarcacion(filter: $filter) {
      id
      fechaHora
      usuarioId
      type
      huellaId
      userName
      device
      turnoID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarcacion = /* GraphQL */ `
  subscription OnDeleteMarcacion(
    $filter: ModelSubscriptionMarcacionFilterInput
  ) {
    onDeleteMarcacion(filter: $filter) {
      id
      fechaHora
      usuarioId
      type
      huellaId
      userName
      device
      turnoID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTurno = /* GraphQL */ `
  subscription OnCreateTurno($filter: ModelSubscriptionTurnoFilterInput) {
    onCreateTurno(filter: $filter) {
      id
      marcacionesIds
      Marcaciones {
        items {
          id
          fechaHora
          usuarioId
          type
          huellaId
          userName
          device
          turnoID
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      userName
      inicio
      fin
      tPausas
      totalHoras
      horasLaboradas
      hOrdinaria
      rNocturno
      rDominicalNocturno
      hExtraDiurna
      hOrdinariaDominical
      hExtraDominicalDiurna
      hExtraDominicalNocturna
      hExtraNocturna
      hExtrasTotal
      hLibre
      hPendiente
      TotalHorasExtrasConRecargos
      liquidado
      periodoID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTurno = /* GraphQL */ `
  subscription OnUpdateTurno($filter: ModelSubscriptionTurnoFilterInput) {
    onUpdateTurno(filter: $filter) {
      id
      marcacionesIds
      Marcaciones {
        items {
          id
          fechaHora
          usuarioId
          type
          huellaId
          userName
          device
          turnoID
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      userName
      inicio
      fin
      tPausas
      totalHoras
      horasLaboradas
      hOrdinaria
      rNocturno
      rDominicalNocturno
      hExtraDiurna
      hOrdinariaDominical
      hExtraDominicalDiurna
      hExtraDominicalNocturna
      hExtraNocturna
      hExtrasTotal
      hLibre
      hPendiente
      TotalHorasExtrasConRecargos
      liquidado
      periodoID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTurno = /* GraphQL */ `
  subscription OnDeleteTurno($filter: ModelSubscriptionTurnoFilterInput) {
    onDeleteTurno(filter: $filter) {
      id
      marcacionesIds
      Marcaciones {
        items {
          id
          fechaHora
          usuarioId
          type
          huellaId
          userName
          device
          turnoID
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      userName
      inicio
      fin
      tPausas
      totalHoras
      horasLaboradas
      hOrdinaria
      rNocturno
      rDominicalNocturno
      hExtraDiurna
      hOrdinariaDominical
      hExtraDominicalDiurna
      hExtraDominicalNocturna
      hExtraNocturna
      hExtrasTotal
      hLibre
      hPendiente
      TotalHorasExtrasConRecargos
      liquidado
      periodoID
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePeriodo = /* GraphQL */ `
  subscription OnCreatePeriodo($filter: ModelSubscriptionPeriodoFilterInput) {
    onCreatePeriodo(filter: $filter) {
      id
      inicio
      fin
      userId
      totalHoras
      totalPausas
      totalHorasLaboradas
      totalTiempoLibre
      totalHERecargos
      diasLaborables
      diasLaborados
      horasLaborables
      horasLaboradas
      diasIncapacidad
      diasVacaciones
      diasLicenciaRemunerada
      diasLicenciaNoRemunerada
      Turnos {
        items {
          id
          marcacionesIds
          Marcaciones {
            items {
              id
              fechaHora
              usuarioId
              type
              huellaId
              userName
              device
              turnoID
              createdAt
              updatedAt
            }
            nextToken
          }
          userId
          userName
          inicio
          fin
          tPausas
          totalHoras
          horasLaboradas
          hOrdinaria
          rNocturno
          rDominicalNocturno
          hExtraDiurna
          hOrdinariaDominical
          hExtraDominicalDiurna
          hExtraDominicalNocturna
          hExtraNocturna
          hExtrasTotal
          hLibre
          hPendiente
          TotalHorasExtrasConRecargos
          liquidado
          periodoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePeriodo = /* GraphQL */ `
  subscription OnUpdatePeriodo($filter: ModelSubscriptionPeriodoFilterInput) {
    onUpdatePeriodo(filter: $filter) {
      id
      inicio
      fin
      userId
      totalHoras
      totalPausas
      totalHorasLaboradas
      totalTiempoLibre
      totalHERecargos
      diasLaborables
      diasLaborados
      horasLaborables
      horasLaboradas
      diasIncapacidad
      diasVacaciones
      diasLicenciaRemunerada
      diasLicenciaNoRemunerada
      Turnos {
        items {
          id
          marcacionesIds
          Marcaciones {
            items {
              id
              fechaHora
              usuarioId
              type
              huellaId
              userName
              device
              turnoID
              createdAt
              updatedAt
            }
            nextToken
          }
          userId
          userName
          inicio
          fin
          tPausas
          totalHoras
          horasLaboradas
          hOrdinaria
          rNocturno
          rDominicalNocturno
          hExtraDiurna
          hOrdinariaDominical
          hExtraDominicalDiurna
          hExtraDominicalNocturna
          hExtraNocturna
          hExtrasTotal
          hLibre
          hPendiente
          TotalHorasExtrasConRecargos
          liquidado
          periodoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePeriodo = /* GraphQL */ `
  subscription OnDeletePeriodo($filter: ModelSubscriptionPeriodoFilterInput) {
    onDeletePeriodo(filter: $filter) {
      id
      inicio
      fin
      userId
      totalHoras
      totalPausas
      totalHorasLaboradas
      totalTiempoLibre
      totalHERecargos
      diasLaborables
      diasLaborados
      horasLaborables
      horasLaboradas
      diasIncapacidad
      diasVacaciones
      diasLicenciaRemunerada
      diasLicenciaNoRemunerada
      Turnos {
        items {
          id
          marcacionesIds
          Marcaciones {
            items {
              id
              fechaHora
              usuarioId
              type
              huellaId
              userName
              device
              turnoID
              createdAt
              updatedAt
            }
            nextToken
          }
          userId
          userName
          inicio
          fin
          tPausas
          totalHoras
          horasLaboradas
          hOrdinaria
          rNocturno
          rDominicalNocturno
          hExtraDiurna
          hOrdinariaDominical
          hExtraDominicalDiurna
          hExtraDominicalNocturna
          hExtraNocturna
          hExtrasTotal
          hLibre
          hPendiente
          TotalHorasExtrasConRecargos
          liquidado
          periodoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHorasAcumuladas = /* GraphQL */ `
  subscription OnCreateHorasAcumuladas(
    $filter: ModelSubscriptionHorasAcumuladasFilterInput
  ) {
    onCreateHorasAcumuladas(filter: $filter) {
      tiempoLibre
      tiempoPendiente
      tiempoExtra
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHorasAcumuladas = /* GraphQL */ `
  subscription OnUpdateHorasAcumuladas(
    $filter: ModelSubscriptionHorasAcumuladasFilterInput
  ) {
    onUpdateHorasAcumuladas(filter: $filter) {
      tiempoLibre
      tiempoPendiente
      tiempoExtra
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHorasAcumuladas = /* GraphQL */ `
  subscription OnDeleteHorasAcumuladas(
    $filter: ModelSubscriptionHorasAcumuladasFilterInput
  ) {
    onDeleteHorasAcumuladas(filter: $filter) {
      tiempoLibre
      tiempoPendiente
      tiempoExtra
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMaterial = /* GraphQL */ `
  subscription OnCreateMaterial($filter: ModelSubscriptionMaterialFilterInput) {
    onCreateMaterial(filter: $filter) {
      id
      alegraId
      applyIva
      valorVenta
      alegraCategory
      proveedor
      referencia
      calibre
      bodega
      monedaReferencia
      valorUsd
      valorUpchargeUsd
      valorTotalUsd
      valorCop
      valorUpchargeCop
      valorTotalCop
      unidadesUsd
      unidadesCop
      trm
      fechaTrm
      stock {
        items {
          id
          materialID
          alegraItemId
          anchoLamina
          largoLamina
          cantidad
          areaTotal
          areaRestante
          consecutivo
          cajaNumero
          fechaIngreso
          fechaSalida
          retales {
            items {
              id
              stockID
              ancho
              largo
              cantidad
              areaTotal
              fechaIngreso
              fechaSalida
              createdAt
              updatedAt
              stockRetalesId
            }
            nextToken
          }
          lote
          ingresada
          activa
          cerrada
          usuarioIngresa
          usuarioActiva
          usuarioCierra
          createdAt
          updatedAt
          materialStockId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMaterial = /* GraphQL */ `
  subscription OnUpdateMaterial($filter: ModelSubscriptionMaterialFilterInput) {
    onUpdateMaterial(filter: $filter) {
      id
      alegraId
      applyIva
      valorVenta
      alegraCategory
      proveedor
      referencia
      calibre
      bodega
      monedaReferencia
      valorUsd
      valorUpchargeUsd
      valorTotalUsd
      valorCop
      valorUpchargeCop
      valorTotalCop
      unidadesUsd
      unidadesCop
      trm
      fechaTrm
      stock {
        items {
          id
          materialID
          alegraItemId
          anchoLamina
          largoLamina
          cantidad
          areaTotal
          areaRestante
          consecutivo
          cajaNumero
          fechaIngreso
          fechaSalida
          retales {
            items {
              id
              stockID
              ancho
              largo
              cantidad
              areaTotal
              fechaIngreso
              fechaSalida
              createdAt
              updatedAt
              stockRetalesId
            }
            nextToken
          }
          lote
          ingresada
          activa
          cerrada
          usuarioIngresa
          usuarioActiva
          usuarioCierra
          createdAt
          updatedAt
          materialStockId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMaterial = /* GraphQL */ `
  subscription OnDeleteMaterial($filter: ModelSubscriptionMaterialFilterInput) {
    onDeleteMaterial(filter: $filter) {
      id
      alegraId
      applyIva
      valorVenta
      alegraCategory
      proveedor
      referencia
      calibre
      bodega
      monedaReferencia
      valorUsd
      valorUpchargeUsd
      valorTotalUsd
      valorCop
      valorUpchargeCop
      valorTotalCop
      unidadesUsd
      unidadesCop
      trm
      fechaTrm
      stock {
        items {
          id
          materialID
          alegraItemId
          anchoLamina
          largoLamina
          cantidad
          areaTotal
          areaRestante
          consecutivo
          cajaNumero
          fechaIngreso
          fechaSalida
          retales {
            items {
              id
              stockID
              ancho
              largo
              cantidad
              areaTotal
              fechaIngreso
              fechaSalida
              createdAt
              updatedAt
              stockRetalesId
            }
            nextToken
          }
          lote
          ingresada
          activa
          cerrada
          usuarioIngresa
          usuarioActiva
          usuarioCierra
          createdAt
          updatedAt
          materialStockId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStock = /* GraphQL */ `
  subscription OnCreateStock($filter: ModelSubscriptionStockFilterInput) {
    onCreateStock(filter: $filter) {
      id
      materialID
      alegraItemId
      anchoLamina
      largoLamina
      cantidad
      areaTotal
      areaRestante
      consecutivo
      cajaNumero
      fechaIngreso
      fechaSalida
      retales {
        items {
          id
          stockID
          stock {
            id
            materialID
            alegraItemId
            anchoLamina
            largoLamina
            cantidad
            areaTotal
            areaRestante
            consecutivo
            cajaNumero
            fechaIngreso
            fechaSalida
            retales {
              nextToken
            }
            lote
            ingresada
            activa
            cerrada
            usuarioIngresa
            usuarioActiva
            usuarioCierra
            createdAt
            updatedAt
            materialStockId
          }
          ancho
          largo
          cantidad
          areaTotal
          fechaIngreso
          fechaSalida
          createdAt
          updatedAt
          stockRetalesId
        }
        nextToken
      }
      lote
      ingresada
      activa
      cerrada
      usuarioIngresa
      usuarioActiva
      usuarioCierra
      createdAt
      updatedAt
      materialStockId
    }
  }
`;
export const onUpdateStock = /* GraphQL */ `
  subscription OnUpdateStock($filter: ModelSubscriptionStockFilterInput) {
    onUpdateStock(filter: $filter) {
      id
      materialID
      alegraItemId
      anchoLamina
      largoLamina
      cantidad
      areaTotal
      areaRestante
      consecutivo
      cajaNumero
      fechaIngreso
      fechaSalida
      retales {
        items {
          id
          stockID
          stock {
            id
            materialID
            alegraItemId
            anchoLamina
            largoLamina
            cantidad
            areaTotal
            areaRestante
            consecutivo
            cajaNumero
            fechaIngreso
            fechaSalida
            retales {
              nextToken
            }
            lote
            ingresada
            activa
            cerrada
            usuarioIngresa
            usuarioActiva
            usuarioCierra
            createdAt
            updatedAt
            materialStockId
          }
          ancho
          largo
          cantidad
          areaTotal
          fechaIngreso
          fechaSalida
          createdAt
          updatedAt
          stockRetalesId
        }
        nextToken
      }
      lote
      ingresada
      activa
      cerrada
      usuarioIngresa
      usuarioActiva
      usuarioCierra
      createdAt
      updatedAt
      materialStockId
    }
  }
`;
export const onDeleteStock = /* GraphQL */ `
  subscription OnDeleteStock($filter: ModelSubscriptionStockFilterInput) {
    onDeleteStock(filter: $filter) {
      id
      materialID
      alegraItemId
      anchoLamina
      largoLamina
      cantidad
      areaTotal
      areaRestante
      consecutivo
      cajaNumero
      fechaIngreso
      fechaSalida
      retales {
        items {
          id
          stockID
          stock {
            id
            materialID
            alegraItemId
            anchoLamina
            largoLamina
            cantidad
            areaTotal
            areaRestante
            consecutivo
            cajaNumero
            fechaIngreso
            fechaSalida
            retales {
              nextToken
            }
            lote
            ingresada
            activa
            cerrada
            usuarioIngresa
            usuarioActiva
            usuarioCierra
            createdAt
            updatedAt
            materialStockId
          }
          ancho
          largo
          cantidad
          areaTotal
          fechaIngreso
          fechaSalida
          createdAt
          updatedAt
          stockRetalesId
        }
        nextToken
      }
      lote
      ingresada
      activa
      cerrada
      usuarioIngresa
      usuarioActiva
      usuarioCierra
      createdAt
      updatedAt
      materialStockId
    }
  }
`;
export const onCreateRetal = /* GraphQL */ `
  subscription OnCreateRetal($filter: ModelSubscriptionRetalFilterInput) {
    onCreateRetal(filter: $filter) {
      id
      stockID
      stock {
        id
        materialID
        alegraItemId
        anchoLamina
        largoLamina
        cantidad
        areaTotal
        areaRestante
        consecutivo
        cajaNumero
        fechaIngreso
        fechaSalida
        retales {
          items {
            id
            stockID
            stock {
              id
              materialID
              alegraItemId
              anchoLamina
              largoLamina
              cantidad
              areaTotal
              areaRestante
              consecutivo
              cajaNumero
              fechaIngreso
              fechaSalida
              lote
              ingresada
              activa
              cerrada
              usuarioIngresa
              usuarioActiva
              usuarioCierra
              createdAt
              updatedAt
              materialStockId
            }
            ancho
            largo
            cantidad
            areaTotal
            fechaIngreso
            fechaSalida
            createdAt
            updatedAt
            stockRetalesId
          }
          nextToken
        }
        lote
        ingresada
        activa
        cerrada
        usuarioIngresa
        usuarioActiva
        usuarioCierra
        createdAt
        updatedAt
        materialStockId
      }
      ancho
      largo
      cantidad
      areaTotal
      fechaIngreso
      fechaSalida
      createdAt
      updatedAt
      stockRetalesId
    }
  }
`;
export const onUpdateRetal = /* GraphQL */ `
  subscription OnUpdateRetal($filter: ModelSubscriptionRetalFilterInput) {
    onUpdateRetal(filter: $filter) {
      id
      stockID
      stock {
        id
        materialID
        alegraItemId
        anchoLamina
        largoLamina
        cantidad
        areaTotal
        areaRestante
        consecutivo
        cajaNumero
        fechaIngreso
        fechaSalida
        retales {
          items {
            id
            stockID
            stock {
              id
              materialID
              alegraItemId
              anchoLamina
              largoLamina
              cantidad
              areaTotal
              areaRestante
              consecutivo
              cajaNumero
              fechaIngreso
              fechaSalida
              lote
              ingresada
              activa
              cerrada
              usuarioIngresa
              usuarioActiva
              usuarioCierra
              createdAt
              updatedAt
              materialStockId
            }
            ancho
            largo
            cantidad
            areaTotal
            fechaIngreso
            fechaSalida
            createdAt
            updatedAt
            stockRetalesId
          }
          nextToken
        }
        lote
        ingresada
        activa
        cerrada
        usuarioIngresa
        usuarioActiva
        usuarioCierra
        createdAt
        updatedAt
        materialStockId
      }
      ancho
      largo
      cantidad
      areaTotal
      fechaIngreso
      fechaSalida
      createdAt
      updatedAt
      stockRetalesId
    }
  }
`;
export const onDeleteRetal = /* GraphQL */ `
  subscription OnDeleteRetal($filter: ModelSubscriptionRetalFilterInput) {
    onDeleteRetal(filter: $filter) {
      id
      stockID
      stock {
        id
        materialID
        alegraItemId
        anchoLamina
        largoLamina
        cantidad
        areaTotal
        areaRestante
        consecutivo
        cajaNumero
        fechaIngreso
        fechaSalida
        retales {
          items {
            id
            stockID
            stock {
              id
              materialID
              alegraItemId
              anchoLamina
              largoLamina
              cantidad
              areaTotal
              areaRestante
              consecutivo
              cajaNumero
              fechaIngreso
              fechaSalida
              lote
              ingresada
              activa
              cerrada
              usuarioIngresa
              usuarioActiva
              usuarioCierra
              createdAt
              updatedAt
              materialStockId
            }
            ancho
            largo
            cantidad
            areaTotal
            fechaIngreso
            fechaSalida
            createdAt
            updatedAt
            stockRetalesId
          }
          nextToken
        }
        lote
        ingresada
        activa
        cerrada
        usuarioIngresa
        usuarioActiva
        usuarioCierra
        createdAt
        updatedAt
        materialStockId
      }
      ancho
      largo
      cantidad
      areaTotal
      fechaIngreso
      fechaSalida
      createdAt
      updatedAt
      stockRetalesId
    }
  }
`;
export const onCreateExposicion = /* GraphQL */ `
  subscription OnCreateExposicion(
    $filter: ModelSubscriptionExposicionFilterInput
  ) {
    onCreateExposicion(filter: $filter) {
      id
      plateName
      dateExposed
      plateType
      ciudad
      calibre
      len {
        lenId
        lenWidth
        lenHeight
        name
        uuid
        area
        image {
          bucket
          region
          key
        }
        op
        version
      }
      areaTotalLen
      imageExp {
        bucket
        region
        key
      }
      expoName
      taras
      responsablePin
      responsableId
      responsableName
      responsable {
        id
        cedula
        nombres
        apellidos
        nombreCompleto
        email
        roles
        cognitoUserId
        devices
        sedes
        huellas {
          items {
            id
            userID
            userName
            finger
            device
            fingerDeviceID
            registered_at
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pin
        alegraVendedorId
        fingerDevices
        createdAt
        updatedAt
      }
      responsableAnulacion
      anulacionName
      anulada
      responsableReposicion
      reposicionName
      reposicion
      referencia
      cajas
      cajaId
      caja {
        id
        ancho
        largo
        cantidadLaminas
        areaTotal
        calibre
        consecutivo
        areaRestante
        createdAt
        updatedAt
      }
      motivo
      pendienteSalida
      isPlate
      desperdicio
      desperdicioPerc
      anchoRetal
      largoRetal
      anchoLamina
      largoLamina
      ordenesSalida {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            responsable {
              id
              cedula
              nombres
              apellidos
              nombreCompleto
              email
              roles
              cognitoUserId
              devices
              sedes
              pin
              alegraVendedorId
              fingerDevices
              createdAt
              updatedAt
            }
            responsableAnulacion
            anulacionName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExposicion = /* GraphQL */ `
  subscription OnUpdateExposicion(
    $filter: ModelSubscriptionExposicionFilterInput
  ) {
    onUpdateExposicion(filter: $filter) {
      id
      plateName
      dateExposed
      plateType
      ciudad
      calibre
      len {
        lenId
        lenWidth
        lenHeight
        name
        uuid
        area
        image {
          bucket
          region
          key
        }
        op
        version
      }
      areaTotalLen
      imageExp {
        bucket
        region
        key
      }
      expoName
      taras
      responsablePin
      responsableId
      responsableName
      responsable {
        id
        cedula
        nombres
        apellidos
        nombreCompleto
        email
        roles
        cognitoUserId
        devices
        sedes
        huellas {
          items {
            id
            userID
            userName
            finger
            device
            fingerDeviceID
            registered_at
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pin
        alegraVendedorId
        fingerDevices
        createdAt
        updatedAt
      }
      responsableAnulacion
      anulacionName
      anulada
      responsableReposicion
      reposicionName
      reposicion
      referencia
      cajas
      cajaId
      caja {
        id
        ancho
        largo
        cantidadLaminas
        areaTotal
        calibre
        consecutivo
        areaRestante
        createdAt
        updatedAt
      }
      motivo
      pendienteSalida
      isPlate
      desperdicio
      desperdicioPerc
      anchoRetal
      largoRetal
      anchoLamina
      largoLamina
      ordenesSalida {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            responsable {
              id
              cedula
              nombres
              apellidos
              nombreCompleto
              email
              roles
              cognitoUserId
              devices
              sedes
              pin
              alegraVendedorId
              fingerDevices
              createdAt
              updatedAt
            }
            responsableAnulacion
            anulacionName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExposicion = /* GraphQL */ `
  subscription OnDeleteExposicion(
    $filter: ModelSubscriptionExposicionFilterInput
  ) {
    onDeleteExposicion(filter: $filter) {
      id
      plateName
      dateExposed
      plateType
      ciudad
      calibre
      len {
        lenId
        lenWidth
        lenHeight
        name
        uuid
        area
        image {
          bucket
          region
          key
        }
        op
        version
      }
      areaTotalLen
      imageExp {
        bucket
        region
        key
      }
      expoName
      taras
      responsablePin
      responsableId
      responsableName
      responsable {
        id
        cedula
        nombres
        apellidos
        nombreCompleto
        email
        roles
        cognitoUserId
        devices
        sedes
        huellas {
          items {
            id
            userID
            userName
            finger
            device
            fingerDeviceID
            registered_at
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pin
        alegraVendedorId
        fingerDevices
        createdAt
        updatedAt
      }
      responsableAnulacion
      anulacionName
      anulada
      responsableReposicion
      reposicionName
      reposicion
      referencia
      cajas
      cajaId
      caja {
        id
        ancho
        largo
        cantidadLaminas
        areaTotal
        calibre
        consecutivo
        areaRestante
        createdAt
        updatedAt
      }
      motivo
      pendienteSalida
      isPlate
      desperdicio
      desperdicioPerc
      anchoRetal
      largoRetal
      anchoLamina
      largoLamina
      ordenesSalida {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            responsable {
              id
              cedula
              nombres
              apellidos
              nombreCompleto
              email
              roles
              cognitoUserId
              devices
              sedes
              pin
              alegraVendedorId
              fingerDevices
              createdAt
              updatedAt
            }
            responsableAnulacion
            anulacionName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSalidaOrden = /* GraphQL */ `
  subscription OnCreateSalidaOrden(
    $filter: ModelSubscriptionSalidaOrdenFilterInput
  ) {
    onCreateSalidaOrden(filter: $filter) {
      id
      opSalida
      versionSalida
      clienteSalidaId
      clienteSalidaName
      trabajoSalida
      ciudadSalida
      totalPlanchas
      tipoTrabajo
      fechaSalida
      vendedorId
      vendedorName
      materialOrden {
        anchoSalida
        largoSalida
        cantidadPlanchas
        cajaExpo
        areaSalida
        totalOrden
        observaciones
        valorUnitarioOrden
      }
      refMaterial
      refPlanchas
      lenQty
      calibreOrden
      planchasXColor
      odc
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSalidaOrden = /* GraphQL */ `
  subscription OnUpdateSalidaOrden(
    $filter: ModelSubscriptionSalidaOrdenFilterInput
  ) {
    onUpdateSalidaOrden(filter: $filter) {
      id
      opSalida
      versionSalida
      clienteSalidaId
      clienteSalidaName
      trabajoSalida
      ciudadSalida
      totalPlanchas
      tipoTrabajo
      fechaSalida
      vendedorId
      vendedorName
      materialOrden {
        anchoSalida
        largoSalida
        cantidadPlanchas
        cajaExpo
        areaSalida
        totalOrden
        observaciones
        valorUnitarioOrden
      }
      refMaterial
      refPlanchas
      lenQty
      calibreOrden
      planchasXColor
      odc
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSalidaOrden = /* GraphQL */ `
  subscription OnDeleteSalidaOrden(
    $filter: ModelSubscriptionSalidaOrdenFilterInput
  ) {
    onDeleteSalidaOrden(filter: $filter) {
      id
      opSalida
      versionSalida
      clienteSalidaId
      clienteSalidaName
      trabajoSalida
      ciudadSalida
      totalPlanchas
      tipoTrabajo
      fechaSalida
      vendedorId
      vendedorName
      materialOrden {
        anchoSalida
        largoSalida
        cantidadPlanchas
        cajaExpo
        areaSalida
        totalOrden
        observaciones
        valorUnitarioOrden
      }
      refMaterial
      refPlanchas
      lenQty
      calibreOrden
      planchasXColor
      odc
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCaja = /* GraphQL */ `
  subscription OnCreateCaja($filter: ModelSubscriptionCajaFilterInput) {
    onCreateCaja(filter: $filter) {
      id
      ancho
      largo
      cantidadLaminas
      areaTotal
      calibre
      consecutivo
      areaRestante
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCaja = /* GraphQL */ `
  subscription OnUpdateCaja($filter: ModelSubscriptionCajaFilterInput) {
    onUpdateCaja(filter: $filter) {
      id
      ancho
      largo
      cantidadLaminas
      areaTotal
      calibre
      consecutivo
      areaRestante
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCaja = /* GraphQL */ `
  subscription OnDeleteCaja($filter: ModelSubscriptionCajaFilterInput) {
    onDeleteCaja(filter: $filter) {
      id
      ancho
      largo
      cantidadLaminas
      areaTotal
      calibre
      consecutivo
      areaRestante
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrdenSalida = /* GraphQL */ `
  subscription OnCreateOrdenSalida(
    $filter: ModelSubscriptionOrdenSalidaFilterInput
  ) {
    onCreateOrdenSalida(filter: $filter) {
      id
      op
      version
      clienteOrdenId
      clienteOrden
      nameJob
      ciudadOrden
      tipoJob
      vendedorId
      vendedor
      salidaProd
      exposiciones {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            responsable {
              id
              cedula
              nombres
              apellidos
              nombreCompleto
              email
              roles
              cognitoUserId
              devices
              sedes
              pin
              alegraVendedorId
              fingerDevices
              createdAt
              updatedAt
            }
            responsableAnulacion
            anulacionName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ordenMaterial {
        uuid
        opAncho
        opLargo
        cantidad
        caja
        area
        total
      }
      comprobanteProd {
        bucket
        region
        key
      }
      observaciones
      totalPlanchasOrden
      calibreOrden
      totalPlanchasExp
      calibresExp
      motivoDiferencia
      motivoReposicion
      reposicion
      responsableRepo
      refMaterial
      refPlanchas
      qtyLen
      planchasXColor
      ordenCompra
      expID
      infoRetal
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrdenSalida = /* GraphQL */ `
  subscription OnUpdateOrdenSalida(
    $filter: ModelSubscriptionOrdenSalidaFilterInput
  ) {
    onUpdateOrdenSalida(filter: $filter) {
      id
      op
      version
      clienteOrdenId
      clienteOrden
      nameJob
      ciudadOrden
      tipoJob
      vendedorId
      vendedor
      salidaProd
      exposiciones {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            responsable {
              id
              cedula
              nombres
              apellidos
              nombreCompleto
              email
              roles
              cognitoUserId
              devices
              sedes
              pin
              alegraVendedorId
              fingerDevices
              createdAt
              updatedAt
            }
            responsableAnulacion
            anulacionName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ordenMaterial {
        uuid
        opAncho
        opLargo
        cantidad
        caja
        area
        total
      }
      comprobanteProd {
        bucket
        region
        key
      }
      observaciones
      totalPlanchasOrden
      calibreOrden
      totalPlanchasExp
      calibresExp
      motivoDiferencia
      motivoReposicion
      reposicion
      responsableRepo
      refMaterial
      refPlanchas
      qtyLen
      planchasXColor
      ordenCompra
      expID
      infoRetal
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrdenSalida = /* GraphQL */ `
  subscription OnDeleteOrdenSalida(
    $filter: ModelSubscriptionOrdenSalidaFilterInput
  ) {
    onDeleteOrdenSalida(filter: $filter) {
      id
      op
      version
      clienteOrdenId
      clienteOrden
      nameJob
      ciudadOrden
      tipoJob
      vendedorId
      vendedor
      salidaProd
      exposiciones {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            responsable {
              id
              cedula
              nombres
              apellidos
              nombreCompleto
              email
              roles
              cognitoUserId
              devices
              sedes
              pin
              alegraVendedorId
              fingerDevices
              createdAt
              updatedAt
            }
            responsableAnulacion
            anulacionName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ordenMaterial {
        uuid
        opAncho
        opLargo
        cantidad
        caja
        area
        total
      }
      comprobanteProd {
        bucket
        region
        key
      }
      observaciones
      totalPlanchasOrden
      calibreOrden
      totalPlanchasExp
      calibresExp
      motivoDiferencia
      motivoReposicion
      reposicion
      responsableRepo
      refMaterial
      refPlanchas
      qtyLen
      planchasXColor
      ordenCompra
      expID
      infoRetal
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrdenProducto = /* GraphQL */ `
  subscription OnCreateOrdenProducto(
    $filter: ModelSubscriptionOrdenProductoFilterInput
  ) {
    onCreateOrdenProducto(filter: $filter) {
      id
      productoID
      ordenID
      producto {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        clienteID
        nombre
        marca {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        marcaID
        ordenes {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        productoNuevo
        productoExistente
        productoRefID
        mainFile {
          bucket
          region
          key
        }
        mainFileThumbnail {
          bucket
          region
          key
        }
        auxFiles {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        marcaProductosId
      }
      orden {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        odc
        clienteID
        prioridad
        tipoOrden
        cooimpreso
        calibre
        ResponsableActualID
        estado
        maquina
        montaje {
          pistas
          repeticiones
          rodillo
          radio
          paso
          gapPistas
          gapRepeticiones
          anchoBobina
          desarrollo
          truncado
          enfrentado
          cantidadTruncado
          prodAncho
          prodAlto
          tipoCorte
          posicionSalida
          embobinado
          email
        }
        numeroOPant
        versionOPant
        dueTime
        productoID
        producto {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        nombreAnt
        rip {
          calibre
          elongacion
          lpi
          emulsion
          colores {
            nombre
            libro
            angulo
            index
            punto
            lpi
            bumpUp
            microCell
          }
          marcas
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrdenProducto = /* GraphQL */ `
  subscription OnUpdateOrdenProducto(
    $filter: ModelSubscriptionOrdenProductoFilterInput
  ) {
    onUpdateOrdenProducto(filter: $filter) {
      id
      productoID
      ordenID
      producto {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        clienteID
        nombre
        marca {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        marcaID
        ordenes {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        productoNuevo
        productoExistente
        productoRefID
        mainFile {
          bucket
          region
          key
        }
        mainFileThumbnail {
          bucket
          region
          key
        }
        auxFiles {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        marcaProductosId
      }
      orden {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        odc
        clienteID
        prioridad
        tipoOrden
        cooimpreso
        calibre
        ResponsableActualID
        estado
        maquina
        montaje {
          pistas
          repeticiones
          rodillo
          radio
          paso
          gapPistas
          gapRepeticiones
          anchoBobina
          desarrollo
          truncado
          enfrentado
          cantidadTruncado
          prodAncho
          prodAlto
          tipoCorte
          posicionSalida
          embobinado
          email
        }
        numeroOPant
        versionOPant
        dueTime
        productoID
        producto {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        nombreAnt
        rip {
          calibre
          elongacion
          lpi
          emulsion
          colores {
            nombre
            libro
            angulo
            index
            punto
            lpi
            bumpUp
            microCell
          }
          marcas
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrdenProducto = /* GraphQL */ `
  subscription OnDeleteOrdenProducto(
    $filter: ModelSubscriptionOrdenProductoFilterInput
  ) {
    onDeleteOrdenProducto(filter: $filter) {
      id
      productoID
      ordenID
      producto {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        clienteID
        nombre
        marca {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        marcaID
        ordenes {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        productoNuevo
        productoExistente
        productoRefID
        mainFile {
          bucket
          region
          key
        }
        mainFileThumbnail {
          bucket
          region
          key
        }
        auxFiles {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        marcaProductosId
      }
      orden {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        odc
        clienteID
        prioridad
        tipoOrden
        cooimpreso
        calibre
        ResponsableActualID
        estado
        maquina
        montaje {
          pistas
          repeticiones
          rodillo
          radio
          paso
          gapPistas
          gapRepeticiones
          anchoBobina
          desarrollo
          truncado
          enfrentado
          cantidadTruncado
          prodAncho
          prodAlto
          tipoCorte
          posicionSalida
          embobinado
          email
        }
        numeroOPant
        versionOPant
        dueTime
        productoID
        producto {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        nombreAnt
        rip {
          calibre
          elongacion
          lpi
          emulsion
          colores {
            nombre
            libro
            angulo
            index
            punto
            lpi
            bumpUp
            microCell
          }
          marcas
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrdenExposicion = /* GraphQL */ `
  subscription OnCreateOrdenExposicion(
    $filter: ModelSubscriptionOrdenExposicionFilterInput
  ) {
    onCreateOrdenExposicion(filter: $filter) {
      id
      exposicionID
      ordenSalidaID
      exposicion {
        id
        plateName
        dateExposed
        plateType
        ciudad
        calibre
        len {
          lenId
          lenWidth
          lenHeight
          name
          uuid
          area
          image {
            bucket
            region
            key
          }
          op
          version
        }
        areaTotalLen
        imageExp {
          bucket
          region
          key
        }
        expoName
        taras
        responsablePin
        responsableId
        responsableName
        responsable {
          id
          cedula
          nombres
          apellidos
          nombreCompleto
          email
          roles
          cognitoUserId
          devices
          sedes
          huellas {
            items {
              id
              userID
              userName
              finger
              device
              fingerDeviceID
              registered_at
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          pin
          alegraVendedorId
          fingerDevices
          createdAt
          updatedAt
        }
        responsableAnulacion
        anulacionName
        anulada
        responsableReposicion
        reposicionName
        reposicion
        referencia
        cajas
        cajaId
        caja {
          id
          ancho
          largo
          cantidadLaminas
          areaTotal
          calibre
          consecutivo
          areaRestante
          createdAt
          updatedAt
        }
        motivo
        pendienteSalida
        isPlate
        desperdicio
        desperdicioPerc
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
        ordenesSalida {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              responsableAnulacion
              anulacionName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              infoRetal
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      ordenSalida {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              responsableAnulacion
              anulacionName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              infoRetal
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrdenExposicion = /* GraphQL */ `
  subscription OnUpdateOrdenExposicion(
    $filter: ModelSubscriptionOrdenExposicionFilterInput
  ) {
    onUpdateOrdenExposicion(filter: $filter) {
      id
      exposicionID
      ordenSalidaID
      exposicion {
        id
        plateName
        dateExposed
        plateType
        ciudad
        calibre
        len {
          lenId
          lenWidth
          lenHeight
          name
          uuid
          area
          image {
            bucket
            region
            key
          }
          op
          version
        }
        areaTotalLen
        imageExp {
          bucket
          region
          key
        }
        expoName
        taras
        responsablePin
        responsableId
        responsableName
        responsable {
          id
          cedula
          nombres
          apellidos
          nombreCompleto
          email
          roles
          cognitoUserId
          devices
          sedes
          huellas {
            items {
              id
              userID
              userName
              finger
              device
              fingerDeviceID
              registered_at
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          pin
          alegraVendedorId
          fingerDevices
          createdAt
          updatedAt
        }
        responsableAnulacion
        anulacionName
        anulada
        responsableReposicion
        reposicionName
        reposicion
        referencia
        cajas
        cajaId
        caja {
          id
          ancho
          largo
          cantidadLaminas
          areaTotal
          calibre
          consecutivo
          areaRestante
          createdAt
          updatedAt
        }
        motivo
        pendienteSalida
        isPlate
        desperdicio
        desperdicioPerc
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
        ordenesSalida {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              responsableAnulacion
              anulacionName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              infoRetal
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      ordenSalida {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              responsableAnulacion
              anulacionName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              infoRetal
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrdenExposicion = /* GraphQL */ `
  subscription OnDeleteOrdenExposicion(
    $filter: ModelSubscriptionOrdenExposicionFilterInput
  ) {
    onDeleteOrdenExposicion(filter: $filter) {
      id
      exposicionID
      ordenSalidaID
      exposicion {
        id
        plateName
        dateExposed
        plateType
        ciudad
        calibre
        len {
          lenId
          lenWidth
          lenHeight
          name
          uuid
          area
          image {
            bucket
            region
            key
          }
          op
          version
        }
        areaTotalLen
        imageExp {
          bucket
          region
          key
        }
        expoName
        taras
        responsablePin
        responsableId
        responsableName
        responsable {
          id
          cedula
          nombres
          apellidos
          nombreCompleto
          email
          roles
          cognitoUserId
          devices
          sedes
          huellas {
            items {
              id
              userID
              userName
              finger
              device
              fingerDeviceID
              registered_at
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          pin
          alegraVendedorId
          fingerDevices
          createdAt
          updatedAt
        }
        responsableAnulacion
        anulacionName
        anulada
        responsableReposicion
        reposicionName
        reposicion
        referencia
        cajas
        cajaId
        caja {
          id
          ancho
          largo
          cantidadLaminas
          areaTotal
          calibre
          consecutivo
          areaRestante
          createdAt
          updatedAt
        }
        motivo
        pendienteSalida
        isPlate
        desperdicio
        desperdicioPerc
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
        ordenesSalida {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              responsableAnulacion
              anulacionName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              infoRetal
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      ordenSalida {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              responsableAnulacion
              anulacionName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              infoRetal
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
