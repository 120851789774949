import React, { useState } from 'react';
import { useInput, useListContext } from 'react-admin';
import { DateRangePicker } from 'react-date-range';
import { da, es } from 'react-date-range/dist/locale';
import { Dialog, Button } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangePickerField = ({ source, label, handleRangeChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, filterValues, setFilters } = useListContext();

  const [dateRange, setDateRange] = useState({
    startDate: filterValues.fechaHora?.gte ? new Date(filterValues.fechaHora.gte) : null,
    endDate: filterValues.fechaHora?.lte ? new Date(filterValues.fechaHora.lte) : null,
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    handleRangeChange(ranges.selection)
    console.log("Handle Select", ranges.selection)
  };

  
  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (dateRange.startDate && dateRange.endDate) {
      console.log("handleClose", dateRange.startDate, dateRange.endDate)
      console.log("FILTERVALUES",filterValues)
      handleRangeChange(dateRange.startDate, dateRange.endDate)
      setFilters({...filterValues,
        start_gte:  dateRange.startDate.toISOString(), 
        start_lte:  new Date(dateRange.endDate.getTime()+(24*60*60*1000)).toISOString(), 
        fechaHora:{
        gte: dateRange.startDate.toISOString(),
        lte: dateRange.endDate.toISOString(),
      }});
    }
  };

  const handleReset = () => {
    setDateRange({
      startDate: null,
      endDate: null,
      key: 'selection',
    });
  };

  return (
    <>
      <div onClick={handleClick} >
        {label && <label>{label}</label>}
        <input
          type="text"
          value={`${dateRange.startDate?.toLocaleDateString()} - ${dateRange.endDate?.toLocaleDateString()}`}
          readOnly
        />
      </div>
      {isOpen && (
        <Dialog open={isOpen} onClose={handleClose} style={{ width: '1400px', height: '600px'}}>
          <DateRangePicker
            ranges={[dateRange]}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
            locale={es}
            direction="horizontal"
            className="date-range-picker"
            style={{margin: '20px'}}
          />
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleClose}>Submit</Button>
        </Dialog>
      )}
    </>
  );
};

export default DateRangePickerField;

