import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { signIn } from 'aws-amplify/auth';
import { useLogin, useNotify } from 'react-admin';
import { Authenticator, View, Text, Heading, Button, useTheme, useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from '../../aws-exports';
import { Hub } from 'aws-amplify/utils';
import '@aws-amplify/ui-react/styles.css';

import {ReactComponent as Logo} from '../assets/LogoGrande2.svg';
import './loginPage.css'

import { translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
import { da } from 'react-date-range/dist/locale';


Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Sign In': 'Ingresar',
    'Sign Up': 'Regístrate',
  },
});



const amplifyAuthenticatorStyle ={
    "display": "flex",
    "backgroundColor": "black",
    "justify-content": "center",
    "primary": 'black',
    "alignItems": "center",
    "flex": 1,
    "height": "100vh",
  }


export default function LoginPage() {
    const login = useLogin();
    const notify = useNotify();
    const services = {
        async handleSignIn(formData){
            let { username, password } = formData;
            
            return signIn({ username, password })
            .then((user) => {
                notify('Inicio de sesión exitoso');
            })
        }

        // async handleSignUp(formData) {
        // let { username, password, attributes } = formData;
        // // custom username
        // console.log("FormData",formData)
        // username = username.toLowerCase();
        // attributes.email = attributes.email.toLowerCase();
        // return signUp({
        //     username,
        //     password,
        //     options:{userAttributes: {...attributes}},

        //   });
        // },
    };


    useEffect(() => {
      const listener = (data) => {
        console.log( "Listener DAta:", data.payload.event);
            switch (data.payload.event) {
              case 'signedIn':
                  login({}).catch(error => {
                      console.error('Error during login redirect:', error);
                  });
                  break;
              // Handle other authentication events if needed
          }
      };

      // Start listening and capture the unsubscribe function
      const unsubscribe = Hub.listen('auth', listener);

      // Cleanup function to stop listening
      return () => {
          unsubscribe(); // Use the unsubscribe function returned by Hub.listen() to stop listening
      };
  }, [login, notify]); // Dependencies for useEffect


  return (
    <Authenticator
      theme={amplifyAuthenticatorStyle}
      initialState="signIn"
      formFields={formFields} 
      components={components}
      />
  );
}

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Logo width='200px'/>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral['80']}`}>
          &copy; Grafiflex 2022. All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Inicia Sesión
        </Heading>

      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();
      const { tokens } = useTheme();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
            color={`${tokens.colors.neutral['80']}`}>
            Restaurar Contraseña
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Crea una cuenta nueva
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      const { tokens } = useTheme();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
            color={`${tokens.colors.neutral['80']}`}
          >
            Volver a Inicio de Sesión
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Ingresa código de verificación:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Ingresa tu Correo:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Correo',
    },
  },
  signUp: {
    email: {
      labelHidden:  true,
      order:1
    },
    name: {      
      labelHidden:  true,
      placeholder:'Nombre',
      order: 2
    },
    family_name: {
      labelHidden:  true,
      placeholder:'Apellido(s)',
      order: 3
    },
    password: {
      labelHidden: true,
      placeholder:'Contraseña',
      order: 5
    },
    confirm_password: {
      labelHidden: true,
      placeholder:'Confirmar Contraseña',
      order: 6
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Ingresar contraseña:',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: 'Correo Electrónico',
      placeholder: 'Ingrese el correo:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Ingresa el codigo de confirmación:',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Confirma la contraseña:',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};