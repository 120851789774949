
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ShowButton,
  } from 'react-admin';
import { responsiveFontSizes } from '@mui/material';
import {  Grid } from '@mui/material';

export const UserExposicionList = () => {

    return (
        <List style={{responsiveFontSizes}} rowClick={false}>
            <Datagrid rowClick="edit">
                {/*<TextField source='id' label='ID' style={{fontWeight:'bold'}}/>*/}
                <TextField source='name' label='USUARIO PRODUCCIÓN' style={{fontWeight:'bold'}}/>
                <Grid sort={true} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <ShowButton style={{fontWeight:'bold'}}/>
                </Grid>
            </Datagrid>
        </List>
    )
};