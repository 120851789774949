import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  Create,
  Edit,
  Show,
  useRecordContext,
  SimpleShowLayout,
  SelectField,
  BooleanInput,
} from 'react-admin';
import { Calibres, Proveedores } from '../../../utils';
import { MaterialEditLayout } from './components/MaterialEditLayout';

const MaterialFilters = [
  <SearchInput source = 'q' alwaysOn/>,  
];

export const MaterialList = () => (
  <List filters={MaterialFilters} queryOptions={{meta:{searchable:'true', filterable: ['id', 'bodega', 'proveedor' ]}}} perPage={50}>
    <Datagrid rowClick='edit'>
      <TextField source='id' label='Material' />
      <SelectField source="proveedor" choices={Proveedores}/>
      <TextField source="referencia" />
      <SelectField source="calibre" choices={Calibres} />
      <TextField source="bodega" />
      <TextField source="alegraId" />
    </Datagrid>
  </List>
);

export const MaterialCreate = () => {

  return (
  <Create redirect='list'>
      <MaterialEditLayout isEdit={false} />
  </Create>
)};

const EditTitle = () => {
  const record = useRecordContext();
  const calibre = Calibres?.find(calibre => calibre.id === record?.calibre)?.name || record?.calibre;

  return <span>Material {record ? `"${record.id}"` : ''}</span>;
};

const editTranform = (data) => {
  //remove stock from data
  delete data.stock;
  return data;
}

export const MaterialEdit = () =>{
  return (
    <Edit transform={editTranform} title={<EditTitle/>} mutationMode="pessimistic">
        <MaterialEditLayout  isEdit={true} />
    </Edit>
  );
} 

export const MaterialShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="proveedor" />
      <TextField source="referencia" />
      <TextField source="calibre" />
      <TextField source="bodega" />
    </SimpleShowLayout>
  </Show>
);

export default {MaterialCreate, MaterialEdit, MaterialList, MaterialShow};

