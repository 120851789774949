import React, { Fragment } from 'react';
import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ActionDelete from '@mui/icons-material/Delete';
import { Confirm } from 'react-admin';


const  DeleteRowItemButton = (props) => {
    const { deleteItem } = props;
    const [openConfirm, setOpen] = useState(false);

    const handleDialogOpen = (e) => {
        e.preventDefault();
        setOpen(true);
    };
    const handleDialogClose = () => {
        setOpen(false);
    }
    const handleDelete = () => {
        deleteItem();
        setOpen(false);
    }

    return (
        <Fragment>
            <Tooltip title={'Eliminar Item'}>
                <IconButton
                    onClick={handleDialogOpen}
                    key="button"
                    size="small"
                >
                    <ActionDelete color="error" />
                </IconButton>
            </Tooltip>
            <Confirm
                isOpen={openConfirm}
                content="Esta Acción no puede revertirse"
                title="Seguro deseas Eliminar este Item?"
                onConfirm={handleDelete}
                confirmColor='warning'
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};


export default DeleteRowItemButton;