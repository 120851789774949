import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import IconCancel from '@mui/icons-material/Cancel';
import IconContentAdd from '@mui/icons-material/Add';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@mui/material';
import { Button, 
    Form, 
    Labeled, 
    useNotify, 
    useGetOne, 
    useUpdate, 
    ChipField, 
    useGetList,
    SaveButton, 
    NumberInput, 
    useDataProvider, 
    useRecordContext, 
} from 'react-admin';

export const CajaDialog = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [update, { isLoading, error }] = useUpdate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const calcularAreaTotal = (values) => {
        return values.ancho * values.largo * values.cantidadLaminas;
    };

    const handleSubmit = async (values) => {

        // Realizar otras operaciones con las cajas si es necesario
        const areaTotalCalculada = calcularAreaTotal(values);
        console.log("Área Total Calculada:", areaTotalCalculada)
        
        const areaRestante = record.areaRestante;
        console.log("Área Restante:", areaRestante)

        const caja = +1;

        //const areaRestanteResult = areaTotalCalculada - areaRestante;
        //console.log("Área Restante Resultante:", areaRestanteResult)

        // Utilizar el valor obtenido en la actualización

        update('cajas', {
            id: record.id,
            data: {
                id: record.id,
                ancho: values.ancho,
                largo: values.largo,
                cantidadLaminas: values.cantidadLaminas,
                areaTotal: areaTotalCalculada,
                //areaRestante: areaRestanteResult,
            },
            previousData: record,
        },
        {
            onSuccess: () => {
                setShowDialog(false);
                notify('Área total y área restante actualizadas');
            },
            onFailure: () => {
                notify('Error al actualizar el área total y el área restante.', { type: 'error' });
            },
        });

        console.log("ANTES DE ACTUALIZAR:",  record)
        console.log("DESPUÉS DE ACTUALIZAR:", values)
    };

    return(
        <>
            <Button label="Agregar Área Total" variant="contained" onClick={handleClick}>
                <IconContentAdd />
            </Button>
            <Dialog fullWidth maxwidth="md" open={showDialog} onClose={handleCloseClick} aria-label="Crear Medida">
                <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{`Calcular Área Total`}</Typography>
                </DialogTitle>
                <Form resource="cajas" id="create-cajas" onSubmit={handleSubmit}>
                    <>
                        <DialogContent>
                            <CajaLayout />
                        </DialogContent>
                        <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                                <IconCancel/>
                            </Button>
                            <SaveButton/>
                        </DialogActions>
                    </>
                </Form>
            </Dialog>
        </>
    );
};

const CajaLayout = () => {
    
    return (
      <>
        <Grid item container flexDirection="row" justifyContent="space-evenly" alignItems="center">
          <Grid item xs={12}>
            <Grid flexDirection="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <NumberInput fullWidth maxwidth="xs" source="ancho" label="Ancho"/>
              </Grid>
              <Grid item xs={12}>
                <NumberInput fullWidth maxwidth="xs" source="largo" label="Largo"/>
              </Grid>
              <Grid item xs={12}>
                <NumberInput fullWidth maxwidth="xs" source="cantidadLaminas" label="Cantidad Láminas"/>
              </Grid>
              <Grid item xs={12} justifyContent="center">
                <Labeled>
                  <ChipField fullWidth maxwidth="lg" source="areaTotal" label="Área Total"/>
                </Labeled>         
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}
  
