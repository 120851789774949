import { DateTimeInput, Edit, Box, TextInput, TextField, FileInput, ArrayInput, NumberInput,
     BoxIterator, SimpleForm, ArrayField, SingleFieldList, ChipField, SimpleFormIterator, required,
      ImageInput, TopToolbar, ListButton, ShowButton, CreateButton} from 'react-admin';
import Grid from '@mui/material/Grid';
import { AmplifyFileInput, AmplifyImageInput } from '../Amplify';




const editTransform = (data) => {

   console.log(data)
   delete data?.imageExp?._url
    return(
        {   
            id: data.id,
            plateName: data.plateName,
            dateExposed: data.dateExposed,
            plateType: data.plateType,
            len: data.len,
            imageExp: {
                bucket: data.imageExp.bucket,
                region: data.imageExp.region,
                key: data.imageExp.key,
            },
            expoName: data.expoName,
            cajas: data.cajas,
            taras: data.taras,
            responsablePin: data.responsablePin,
            cajaId: data.cajaId,
            motivo: data.motivo,
        }
    )
}    

const PostEditionsActions = () => (
    <TopToolbar>
        <ShowButton/>
    </TopToolbar>
)

export const ExposicionEdit = () => {


    return(
        <Edit actions={<PostEditionsActions/>} transform={editTransform}>
            <SimpleForm>
                {/* first row*/}
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between'>
                    {/*
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="warning" multiline maxRows={1} source="id" label='ID' disabled/>
                    </Grid>
                    */}
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" multiline maxRows={6} source="expoName" label='Nombre Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" multiline maxRows={2} source="plateName" label='Nombre Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <DateTimeInput fullWidth color="primary" source="dateExposed" label='Fecha Exposicion'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" multiline maxRows={4} source="plateType" label='Tipo Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={12}>                    
                        <AmplifyImageInput source="imageExp"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <ArrayInput source="len" label="LEN"> 
                            <SimpleFormIterator inline linkType={false}>
                                <TextInput source="uuid" label="Id Len"/>
                                <TextInput source="name" label="Nombre Len"/>
                                <NumberInput source="lenWidth" label="Ancho Len"/>
                                <NumberInput source="lenHeight" label="Altura Len"/>
                                <NumberInput source="area" label="Área"/>
                                <AmplifyImageInput source="image"/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={12}>                    
                        <TextInput fullWidth color="primary" multiline maxRows={6} source="responsablePin" label='PIN del Responsable'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={12}>                    
                        <TextInput fullWidth color="primary" multiline maxRows={6} source="responsable" label='Nombre del Responsable'/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

            {/*<Grid xs={8}}}>
                <ArrayInput source="len">
                    <BoxIterator inline>
                        <TextInput fullWidth source="name" helperText={false} />
                        <NumberInput source="price" helperText={false} />
                        <NumberInput source="quantity" helperText={false} />
                    </BoxIterator>
                </ArrayInput>
            </Grid>*/}
/*
    return(
        <Edit transform={editTransform}>
            <Box className={classes.root}>
                    <Grid xs={8} }}>
                        <TextInput fullWidth source="id" className={classes.idInput} placeholder="Id producto" label="Id" />
                        <TextInput fullWidth source="plateName" className={classes.input} placeholder="Nombre plancha" label="Nombre plancha" />
                    </Grid>
                    <Grid xs={8}}}>
                        <TextInput fullWidth source="dateExposed" className={classes.input} placeholder='Ingrese fecha' label='Fecha Exposicion'/>
                        <TextInput fullWidth source="plateType" className={classes.idInput} placeholder='Tipo de Plancha' label='Tipo plancha'/>
                    </Grid>
                    <Grid xs={8} }}>
                        <TextInput fullWidth source="len" className={classes.input} placeholder='Len' label='Len'/>
                        <TextInput fullWidth source="imageExp" className={classes.idInput} placeholder='Imagen' label='Imagen'/>
                    </Grid>
                </Box>
        </Edit>
        )
    }

    import { DateInput, Edit, Box, TextInput fullWidth } from 'react-admin';
const EditTransform = (data)=>{return(
    {   id: data.id,
        plateName: data.plateName,
        dateExposed: data.dateExposed,
        plateType: data.plateType,
        len: data.len,
        imageExp: data.imageExp
        }
    )
}    

export const ExposicionEdit = () => (
    <Edit transform={EditTransform}>
        <Box>
            <TextInput fullWidth source="id" />
            <TextInput fullWidth source="plateName" />
            <TextInput fullWidth source="dateExposed" />
            <TextInput fullWidth source="plateType" />
            <TextInput fullWidth source="len" />
            <TextInput fullWidth source="imageExp" />
        </Box>
    </Edit>
);




    */
    

