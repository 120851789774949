



import { uploadData } from "@aws-amplify/storage";
import React from "react";
import { useInput } from "react-admin";
import { v4 as uuid } from "uuid";
import { DataProvider } from "../providers/DataProvider";
import { post } from '@aws-amplify/api';


const getThumbnail = async ( fileType, key, key_prefix, fileName, bucket ) => {
  try {
    const requestBody = {
      fileType,
      key,
      key_prefix,
      fileName,
      bucket,
    };
    const response = await post({apiName:'thumbnail',path:'/thumbnail', options: {
      headers: {
        'Content-Type': 'application/json',
      }, 
      body: requestBody
    }});
    if (response.files) {
      return response.files;
    }
  } catch (error) {
    console.error('Error calling API for thumbnail:', error);
  }
};

export function useStorageThumbnailInput({
  source,
  multiple = false,
  onDropAcceptedCallback,
  storageOptions = {},
}) {
  const { field } = useInput({ source });

  React.useEffect(() => {
    if (Array.isArray(field.value) && field.value.length === 0) {
        field.onChange(undefined);
    }
  }, [field]);

  async function onDropAccepted(files, event) {
    try {
      const values = await Promise.all(
        files.map(async (file) => {
          const result = (await uploadData(
            `${uuid().replace(/-/g, "")}_${file.name}`,
            file,
            storageOptions
          )) 

        await getThumbnail( file.type.split("/")[1], result.key, 'public', file.name, DataProvider.storageBucket )
       // console.log(file.type.split("/")[1], `thumb/${thumb}`,  DataProvider.storageRegion,  file.name, DataProvider.storageBucket )
          return {
            bucket: DataProvider.storageBucket,
            region: DataProvider.storageRegion,
            key: result.key,
          };
        })
      );

      if (!multiple) {
        field.onChange(values[0]);
        return;
      }

      if (field.value) {
        field.onChange([...field.value, ...values]);
      } else {
        field.onChange(values);
      }
    } catch (e) {
      console.log(e);
      field.onChange(undefined);
    }

    if (onDropAcceptedCallback) {
      onDropAcceptedCallback(files, event);
    }
  }

  return { onDropAccepted };
}

