//
import React, { useEffect } from 'react';
import { NumberInput } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';


const AreaInputCustom = (props) => {
    const ancho = useWatch({name: 'anchoLamina'});
    const largo = useWatch({name: 'largoLamina'});
    const cantidad = useWatch({name: 'cantidad'});
    const { setValue } = useFormContext();
       
    useEffect(() => {
        setValue('areaTotal', ancho * largo * cantidad);
        setValue('areaRestante', ancho * largo * cantidad);
    }, [props.changedData])
   
  
    return (
      <NumberInput source={props.source} fullWidth  />
    )
  }
  export default AreaInputCustom