import React from 'react';
import {
    Datagrid,
    DateField,
    NumberField,
    TextField,
    BooleanField,
    ReferenceField,
    List,
    SearchInput,
    FunctionField,
} from 'react-admin';

const StockFilters = [
    <SearchInput source = 'q' alwaysOn/>,
  ];

export const StockList = () => {
return (
    <List filters={StockFilters} queryOptions={{meta:{searchable:'true', filterable: ['materialID']}}} perPage={50} sort={{field: 'consecutivo', order: 'DESC'}}>
        <Datagrid rowClick='edit'>
            <TextField source="id" />
            <DateField source="createdAt" label='Fecha Creación' />
            <NumberField source="consecutivo" />
            <NumberField source="anchoLamina" />
            <NumberField source="largoLamina" />
            <NumberField source="cantidad" />
            <NumberField source="areaTotal" />
            <NumberField source="areaRestante" />
            <BooleanField source="activa" />
            <BooleanField source="ingresada" />
            <ReferenceField source="usuarioActiva" reference='usuarios'>
                <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
            </ReferenceField>
            <ReferenceField source="usuarioIngresa" reference='usuarios'>
                <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
            </ReferenceField>
            <ReferenceField source="usuarioCierra" reference='usuarios'>
                <FunctionField render={(r)=>(`${r.nombres} ${r.apellidos}`)} />
            </ReferenceField>
        </Datagrid>
    </List>
);
};

