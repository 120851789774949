import React from "react"
import { SimpleForm, Edit } from 'react-admin'
import Main from './containers/Main'

const editTransform = ({ cliente, ResponsableActual, Responsables, producto,  ...data }) => {
    return(
    {
    ...data,
    })}

const OrdenEdit = props => (
    <Edit {...props} transform={editTransform}>
        <SimpleForm>
            <Main {...props}/>
        </SimpleForm>
    </Edit>
  );


  export default OrdenEdit