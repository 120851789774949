import React from "react";
import { Create, SimpleForm } from "react-admin";
import Main from './containers/Main'






const OrdenCreate = (props) => {
    return(
    <Create>
        <SimpleForm  >
                <Main {...props}/> 
        </SimpleForm> 
    </Create>
    )
};


export default OrdenCreate


