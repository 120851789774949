import { 
    Labeled,
    TextField,
    ChipField,
    ArrayField,
    NumberField,
    SimpleShowLayout,
    useRecordContext,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { ShowInDialogButton } from "@react-admin/ra-form-layout";


export const OrdenSalidaShow = () => {

    const record = useRecordContext();
    const opName = record.op || 'ORDEN DE SALIDA'.toUpperCase();
    
    return(
        <ShowInDialogButton title={opName} maxWidth='xl' redirect="list">
            <SimpleShowLayout >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <NumberField source="version"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <NumberField source="totalPlanchasOrden" label='Total Planchas Orden'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <NumberField source="totalPlanchasExp" label='Total Planchas Exposición'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <NumberField source="calibreOrden" label='Calibre Orden'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <ArrayField source='calibresExp' label="Calibres Exposición">
                                <TextField source="calibresExp" />
                            </ArrayField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <TextField source="responsableRepo" label='Responsable Reposición'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <TextField source="motivoReposicion" label='Motivo Reposicion'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <TextField source="motivoDiferencia" label='Motivo Diferencia'/>
                        </Labeled>
                    </Grid>
                    {/*
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Labeled fontSize='1.5rem'>
                            <ArrayField source='expID' label="expID">
                                <ChipField source="expID" />
                            </ArrayField>
                        </Labeled>
                    </Grid>
                    */}
                </Grid>
            </SimpleShowLayout>
        </ShowInDialogButton>
    )
};
