import React, { useState, useEffect } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconContentAdd from '@mui/icons-material/Add';
import Tabs from '@mui/material/Tabs';
import { 
  Form, 
  Button, 
  TextInput, 
  useUpdate, 
  useNotify, 
  SaveButton, 
  useRecordContext, 
} from 'react-admin';


export const AddDiferenciaDialog = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const [update, { isLoading, error }] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };


  const totalPlanchasOrden = record.totalPlanchasOrden;
  const totalPlanchasExp = record.totalPlanchasExp;




  const handleSubmit = async (values) => {

    update('ordenSalidas', {
      id: record.id,
      data: {
        id: record.id,
        motivoDiferencia: values.motivoDiferencia !== '' ? values.motivoDiferencia : record.motivoDiferencia,
      },
      previousData: record, 
    },
    {
      onSuccess: ({data}) => {

        setShowDialog(false);
        notify('Motivo agregado correctamente')
      }
    },
    {
      onFailure: ({error}) => {
        notify(error.message, 'error')
      }
    })

    

    console.log("record", record)
    console.log("values", values)
  };
  
  if (error) {
    return <Button style={{ color: 'white', fontWeight: 'bold', background: 'red' }}>Error {error.message}</Button>;
  }
 

  const filterDisabled = record && totalPlanchasExp <= totalPlanchasOrden;
  const filterAble = record && totalPlanchasExp > totalPlanchasOrden;

  return (
    filterAble ? (
      <>
        <Button label="Añadir Diferencia" variant="contained" onClick={handleClick} disabled={filterDisabled}>
          <IconContentAdd/>
        </Button>
        <Dialog fullWidth maxwidth="md" open={showDialog} onClose={handleCloseClick} aria-label="Motivo Agregado">
          <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ fontWeight: 'bold' }}>{`Agregar Motivo Diferencia`}</Typography>
          </DialogTitle>
          <Form resource="ordenSalidas" id="create-ordenSalid" onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <Grid container flexDirection="row" justifyContent="space-evenly">
                  <Grid item xs={6} sm={12} md={8} lg={8}>
                    <TextInput resettable multiline fullWidth maxwidth="lg" maxRows={10} source="motivoDiferencia" label="Motivo Diferencia"/>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <IconCancel/>
                </Button>
                <SaveButton/>
              </DialogActions>
            </>
          </Form>
        </Dialog>
      </>
    ) : (
      <span />
    )
  );  

};