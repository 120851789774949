import { 
    Edit,
    required,
    TextInput,
    SimpleForm, 
    NumberInput,
    SelectInput,
    ReferenceInput,
    useRecordContext,
    AutocompleteArrayInput,
    AutocompleteInput,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { responsiveFontSizes } from '@mui/material';
import { Calibre } from '../../utils';


export const OrdenSalidaEdit = () => {

    const record = useRecordContext();

    const editTransform = (data, record) => {   
        
        console.log(`data es: ${data}`) 
        console.log('data es:', data.exposiciones) 
        console.log('record:', record) 
        return(
            {
                id: data.id,
                op: data.op,
                version: data.version,
                totalPlanchasOrden: data.totalPlanchasOrden,
                calibreOrden: data.calibreOrden,
                totalPlanchasExp: data.totalPlanchasExp,
                calibresExp: data.calibresExp,
                motivoDiferencia: data.motivoDiferencia,
            }
        )
    }
    

    return(
        <Edit transform={editTransform} style={{responsiveFontSizes}}>
            <SimpleForm>
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between' >
                    <Grid container direction='row' columnSpacing={2} justifyContent='space-between' >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <NumberInput fullWidth multiline maxRows={1} source="op" label='Orden Producción'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <NumberInput fullWidth multiline maxRows={1} source="version" label='Versión'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <NumberInput fullWidth multiline maxRows={6} source="totalPlanchasOrden" label='Total Planchas Orden'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <NumberInput fullWidth multiline maxRows={6} source="totalPlanchasExp" label='Total Planchas Exposición'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <AutocompleteInput fullWidth variant='outlined' source='calibreOrden' label='Calibre(s) Orden' choices={Calibre}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <AutocompleteArrayInput fullWidth variant='outlined' source='calibresExp' label='Calibre(s) Exposición' choices={Calibre}/>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};
