import React, { useState } from 'react';
import { 
  Form,
  Button,
  TextInput,
  useUpdate,
  useNotify,
  SaveButton,
  ReferenceInput,
  useRecordContext,
  AutocompleteInput,
} from 'react-admin';
import IconCancel from '@mui/icons-material/Cancel';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@mui/material';

export const SolicitarReposicionDialog = () => {
  
  const notify = useNotify();
  const record = useRecordContext();
  const responsableRepo = record.responsableRepo;
  const [update, { isLoading, error }] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {

    update('ordenSalidas', {
      id: record.id,
      data: {
        id: record.id,
        motivoReposicion: values.motivoReposicion !== '' ? values.motivoReposicion : record.motivoReposicion,
        responsableRepo: values.responsableRepo !== '' ? values.responsableRepo : record.responsableRepo,
        nombreReponsableRepo: responsableRepo.nombres !== '' ? responsableRepo.nombres : record.nombreReponsableRepo,
        reposicion: true,
      },
      previousData: record,
    },
    {
      onSuccess: () => {
        setShowDialog(false);
        notify('Reposición Completada.')
      }
    },
    {
      onFailure: () => {
        notify('Error al agregar la reposición completada.', { type: 'error' })
      }
    })
    console.log('After', record)
    console.log('Before', values)
  };
  
  if (error) {
    return (
      <Button variant="contained" onClick={handleClick} color="error">
        Error {error.message}
      </Button>
    )
  }

  const filterAble = responsableRepo === null ;
  const filterDisabled = responsableRepo !== null;

  return (
     filterAble ? ( 
      <>
        <Button size="small" label="Solicitar Resposición" variant="outlined" onClick={handleClick} disabled={filterDisabled}>
          <AssignmentLateOutlinedIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Agregué Reposición">
          <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ fontWeight: 'bold' }}>{`Realizar Reposición`}</Typography>
          </DialogTitle>
          <Form resource="ordenSalidas" id="create-ordenSalida" onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}> 
                    <TextInput size="large" fullWidth source='motivoReposicion' label='Motivo Reposición' multiline maxRows={4}/>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput label='Nombre' source='responsableRepo' reference="usuarios" >
                      <AutocompleteInput 
                        fullWidth
                        label="Nombre"
                        optionText='nombres'
                        onChange={(value)=>(console.log("nombreInput --->", value))}
                      />
                    </ReferenceInput>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <IconCancel/>
                </Button>
                <SaveButton/>
              </DialogActions>
            </>
          </Form>
        </Dialog>
      </>
    ) : (
      <span/>
    )
  );
};