
import React, { useEffect } from "react";

import { useGetList, Loading, AutocompleteArrayInput,useDelete,useUpdate,
     PasswordInput, ReferenceInput, Labeled, FunctionField,
     TextInput} from "react-admin";
import { Grid, TextField } from "@mui/material";    
import { useFormContext, useWatch} from 'react-hook-form'

import { mapUserGroupNames } from "../../../utils";
import  LabeledCustomItem  from "../../../utils/LabeledCustomItem";



const CognitoUserGroups = (props)=>{
    const {source} = props
    const {setValue} = useFormContext()
    const cognitoUserId = useWatch({name:'cognitoUserId'})
    const [update, { isLoading }] = useUpdate();
    const [deleteOne ] = useDelete();
    const roles = useWatch({name: 'roles'});

    let fetchGroups = false

    if( cognitoUserId ){
        fetchGroups=true
    }


    const choices = useGetList(
           'cognitoGroups',
           {filter:{listGroups:{}},
           pagination:{ page: 1, perPage: 10 }}, 
       );
        
    const groups = useGetList(
    'cognitoGroups',
    {
        filter:{ listGroupsForUser: { username:cognitoUserId }},
        pagination:{ page: 1, perPage: 10 }
    },
    {
        enabled: fetchGroups
    } 
    )
    let  formattedGroups = []
    let notIntersection  = []

    React.useEffect(()=>{
        if(groups.status !== 'idle' && !groups.isLoading && groups.data){
            const  gg = groups.data  ? groups.data.map(group => group.id):[]
            setValue('roles', gg)
        }
            
    }, [groups.data])


    let choicesGroups = choices.data

    const AddOrDeleteCognitoGroup=(e, cognitoUserId, groups )=>{
        formattedGroups =  groups.data.map(group => group.id) || []
        if (e.length>formattedGroups.length){
            notIntersection = e.filter(x => !formattedGroups.includes(x))

            update(
                'cognitoGroups',
                { id:cognitoUserId, data: notIntersection, previousData:formattedGroups }
            );
        }
        if (e.length<formattedGroups.length){
            notIntersection = formattedGroups.filter(x => !e.includes(x))
            deleteOne(
                'cognitoGroups',
                { id:[cognitoUserId,notIntersection[0]], previousData:formattedGroups }
            );
        }
    }
    if( isLoading ) return (<Loading/>)
    return(   
        <Grid item md={6} container  justifyContent='space-between'  spacing={4}>
            <Grid item md={6}>
                <AutocompleteArrayInput 
                    source={source}
                    choices={choicesGroups}
                    optionText={text=> text && text.id ? mapUserGroupNames.find(x => x.id === text.id) ? mapUserGroupNames.find(x => x.id === text.id).name : '' : []}
                    onChange={e=>AddOrDeleteCognitoGroup(e, cognitoUserId, groups )}
                    size='small'
                    />
            </Grid>
            <Grid item md={4}>
                {
                    (roles && roles.includes('produccion')) ?
                    (<PasswordInput
                        label="PIN"
                        source="pin"
                        inputProps={{ autocomplete: 'current-password' }}
                        />):(roles && roles.includes('diseno_externo')) ? (
                            <VendedorAlegra/>
                        ):(
                            <span></span>
                        )
                }
            </Grid>
        </Grid>
    )
}

export default CognitoUserGroups

const VendedorAlegra = (props) => {

    const alegraVendedorId = useWatch({name: 'alegraVendedorId'});
    const cedula = useWatch({name: 'cedula'});
    
    const { data } = useGetList('alegraSellers', {page: 1, perPage: 1000}, {enabled: true});

    const { setValue } = useFormContext();
    useEffect(() => {
        if (data && data.length > 0 && cedula) {
            const seller = data.find(seller =>  seller?.identification?.toString() === cedula?.toString())||'';
            if(seller && seller.id !== ''){
                setValue('alegraVendedorId', seller.id);
            }
        }
    }, [data, cedula])

    return (
        <LabeledCustomItem label='Vendedor Alegra' value={alegraVendedorId || "Vendedor no Encontrado"}/>
    )
}