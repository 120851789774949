import { ArrayField, ChipField, Datagrid, DateField, List, NumberField, SingleFieldList, TextField, Pagination } from 'react-admin';

const ListPagination = () => <Pagination rowsPerPageOptions={[5, 15, 30]} />;


const AlegrainvoiceList = () => (
    <List 
        pagination = {<ListPagination />} 
        sort={{ field: 'datetime', order: 'DESC' }}
    >
        <Datagrid rowClick="edit">
            <TextField source="numberTemplate.fullNumber" label="Numero" />
            <TextField source="client.name" />
            <DateField source="dueDate" label='Vencimiento'/>
            <DateField source="datetime" label='Creacion'/>
            <TextField source="status" label = 'Estado' />
            <NumberField source="subtotal" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
            <NumberField source="tax" label='Iva' locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
            <NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
            <NumberField source="totalPaid" label='Total Cobrado' locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
            <NumberField source="balance" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
        </Datagrid>
    </List>
);
export default AlegrainvoiceList;



/* <TextField source="numberTemplate.fullNumber" label="Numero" />
<TextField source="id" />
<DateField source="dueDate" />
<DateField source="datetime" />
<TextField source="observations" />
<TextField source="status" />
<TextField source="client.name" />
<NumberField source="subtotal" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
<NumberField source="tax" label='Iva' locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
<NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
<NumberField source="totalPaid" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
<NumberField source="balance" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }}/>
<TextField source="warehouse.id" />
<TextField source="term" />
<TextField source="type" />
<TextField source="operationType" />
<TextField source="paymentForm" />
<NumberField source="seller.id" />
<NumberField source="priceList.id" />
<ArrayField source="retentionsSuggested"><SingleFieldList><ChipField source="id" /></SingleFieldList></ArrayField>
<TextField source="stamp.legalStatus" />
<DateField source="costCenter.code" />
<TextField source="printingTemplate.id" />
<TextField source="paymentMethod" /> */