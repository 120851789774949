import * as React from 'react';
import { Grid} from '@mui/material';
import ImageView from './ImageView';
import { LenContent } from './LenContent';
import Divider from '@mui/material/Divider';
import ShowExposicionLayout from './ShowExposicionLayout';
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import { SimpleShowLayout, TextField, NumberField, Labeled, ReferenceField, useRecordContext, SelectField } from 'react-admin';
import { Calibre } from '../../utils';



export const ShowExposicionDialogRA = () => {

  const record = useRecordContext();

  const expoName = ( record?.expoName || 'EXPOSICIÓN' ).toUpperCase().replace(/[_-]/g, ' ');

  return (
    <ShowInDialogButton title={expoName} maxWidth='xl'>
      <SimpleShowLayout>
        <Grid item container flexDirection='row' justifyContent="space-evenly" style={{fontSize:'20px'}}>
          <Grid sx={{'& img':{minHeight:250}}}>
            <ImageView/>
          </Grid>
          <Divider orientation="vertical" flexItem/>
          <Grid item >
            <ShowExposicionLayout/>
          </Grid>
          <Divider orientation='vertical' flexItem style={{marginLeft:'-15rem'}}/>
          <Grid item sx={{'& img':{minWidth:250, minHeight:250}}} flexDirection='column' justifyContent='space-evenly' xs={12} sm={12} md={6} lg={1.5}>
            <Grid item>
              <Labeled>
                <ReferenceField label='RESPONSABLE' source='responsableId' reference='userExposicions'>
                  <TextField label='Responsable' source='name' style={{fontWeight:'bold'}}/>
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item>
                <Labeled>
                  <ReferenceField source='cajaId' reference="cajas" style={{display:'flex', flexDirection:'column', justifyContent:'center'}} label='CAJA'>
                    <NumberField source="consecutivo" style={{fontWeight:'bold'}}/>
                  </ReferenceField> 
                </Labeled>
              </Grid>
            <Grid item>
              <Labeled>
                <NumberField source="taras" label="TARA" style={{fontWeight:'bold'}}/>
              </Labeled>
            </Grid>
          </Grid>
          <Grid item flexDirection='column' justifyContent='space-evenly' xs={6} sm={6} md={6} lg={1.5}>
            <Labeled>
              <TextField source='motivo' label='MOTIVO ANULACIÓN' style={{ fontWeight: 'bold', textAlign: 'left' }} />
            </Labeled>
          </Grid>
        </Grid>
        <Grid columns={{ xs: 4, sm: 8, md: 12 }} flexDirection='row' justifyContent="space-evenly">
          <Grid maxWidth='xl'>
            <LenContent/>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </ShowInDialogButton>
  )
};
