import { Box, IconButton } from '@mui/material';
import React from 'react';
import { NumberInput } from 'react-admin';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFormContext } from 'react-hook-form';


const TrmInput = (props) => {
    const { setValue } = useFormContext();
    
    const fetchTRM = async () => {
        console.log("fetching trm");
        //Fetch TRM  in this url https://www.datos.gov.co/resource/32sa-8pi3.json using fetch library

  
        }
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', }} >
        <NumberInput {...props} />
        <IconButton
          onClick={ fetchTRM}
          sx={{marginBottom: "15px"}}
        >
            <RefreshIcon />
        </IconButton>
      </Box>
    )
  }

  export default TrmInput;