// function to get userIds from marcaciones
import { getHolidaysByYear } from "../../utils/festivos";
import { v4 as uuidv4 } from "uuid";
function getUserIds(marcaciones) {
  let userIds = [];

  marcaciones&&marcaciones.forEach((marcacion) => {
    if (!userIds.includes(marcacion.usuarioId)) {
      userIds.push(marcacion.usuarioId);
    }
  });
  return userIds;
}

export function groupTurnos(marcaciones) {
  console.log("inside group turnos")
  let minutesInHour = 60;
  let turno = {
    id: null,
    name: null,
    marcaciones : [],
    marcacionIncompleta: 0,
  };

  let marcacionesNoClasificadas= [];
  let userIds = getUserIds(marcaciones);
  console.log(userIds)

  let turnos = [];
  // Initialize the state machine
  const workshiftState = {
    currentState: "OUTSIDE_WORKSHIFT",
    lastMarcacion: null,
    currentTurnoStart: null,
    currentTurnoDayStart: null,
    currentPausaStart: null,
    currentPausaDayStart: null,
    currentTurnoID: null,
    currentTurnoConsecutive : 0,
  };
  let  marcacionesIds = [];

  for(let i = 0; i < userIds.length; i++){
    let marcacionesByUser = marcaciones.filter((marcacion) => marcacion.usuarioId === userIds[i]);
      // Sort marcaciones by fechaHora
    marcacionesByUser.sort((a, b) => new Date(a.fechaHora) - new Date(b.fechaHora));

    // Iterate over marcacionesbyUser
    marcacionesByUser.forEach((marcacion) => {
      //console.log(marcacion)
      const dateTime = new Date(marcacion.fechaHora);
      const day = dateTime.getDay();
      const time = dateTime.getHours() * minutesInHour + dateTime.getMinutes();
      const month = dateTime.getMonth()+1;
      const lastMarcacion = new Date(workshiftState.lastMarcacion)
      const timeToLastMarcacion = Math.abs(lastMarcacion.getTime() - dateTime.getTime()) / 3600000


      switch (marcacion.type) {
        case "INICIO_TURNO":
          turno = {};
          marcacionesIds = [];
            turno.id =  uuidv4();
            workshiftState.currentState = "INSIDE_WORKSHIFT";
            workshiftState.currentTurnoStart = time;
            workshiftState.currentTurnoDayStart = day;
            marcacionesIds.push(marcacion);
            workshiftState.lastMarcacion = marcacion.fechaHora
            //console.log("pushedMarcacion")

          break;

        case "FIN_TURNO":
          if (workshiftState.currentState === "INSIDE_WORKSHIFT") {
            workshiftState.currentState = "OUTSIDE_WORKSHIFT";
            marcacionesIds.push(marcacion);
          }

          turno["marcaciones"] = marcacionesIds;

          if (marcacionesIds.length>1){
            if(marcacionesIds.some(marcacion=>  marcacion.type === "INICIO_TURNO" ) && marcacionesIds.some(marcacion=>  marcacion.type === "FIN_TURNO" )){
              //console.log("turno")
              //console.log(turno)
              turnos.push(turno);
            }
            else{
              //console.log("turno Incompleto")
              turno.marcacionIncompleta = 1;
              //console.log(turno);
              turnos.push(turno);
            }

          }
          else{
            marcacionesNoClasificadas.push(marcacion)
          }
          turno = {};
          marcacionesIds = [];
          break;

        case "INICIO_PAUSA":
          if (workshiftState.currentState === "INSIDE_WORKSHIFT") {
            marcacionesIds.push(marcacion);
            workshiftState.currentPausaStart = time;
            workshiftState.currentPausaDayStart = day;
          }
          else{
            marcacionesNoClasificadas.push(marcacion)
          }
          break;

        case "FIN_PAUSA":
          if (workshiftState.currentState === "INSIDE_WORKSHIFT" && workshiftState.currentPausaStart) {

            marcacionesIds.push(marcacion);
          }
          else{
            marcacionesNoClasificadas.push(marcacion)
          }
          break;
      }
    });
  }

  return [turnos,marcacionesNoClasificadas];
}

const calculateHours = (start, end) => {
  //This function calculates the hours between two times in ISO String date iso form, in minutes, 
  //and returns the result in hours
  const startHour = start.getTime();
  const endHour = end.getTime();
  const diff = endHour - startHour;
  const minutes = diff / (1000 * 60);
  const hours = minutes / 60;
  return hours;

}
  
export  function calculateTurno(turno, turnoEmpleado, autorizarHorasExtras){
//Initialize Object at 0

  let turnoCalculado = {
    id: '',
    marcacionesIds: [],
    userId: '',
    userName: '',
    inicio: '',
    fin: '',
    tPausas: 0,
    totalHoras: 0,
    horasLaboradas: 0,
    hExtrasTotal: 0,
    hOrdinaria: 0,
    hLibre: 0,
    hPendiente: 0,
    hExtraDiurna: 0,
    hExtraNocturna: 0,
    hOrdinariaDominical: 0,
    hExtraDominicalDiurna: 0,
    hExtraDominicalNocturna: 0,
    rNocturno: 0,
    rDominicalNocturno: 0,
    TotalHorasExtrasConRecargos: 0,
  };
    console.log("inside calculateTurno")
    const marcacionesIds = [];
    for(let i = 0; i<turno.marcaciones.length; i++){
      marcacionesIds.push(turno.marcaciones[i].id)
    }
    //const timeZoneOffset = //300; // 5 hours (for colombia) * 60min = 300 minutes; change this to your timezone offset or get it from the browser or from user settings
    const id = turno.id
    const horaInicio = turno.marcaciones.find(marcacion => marcacion.type === "INICIO_TURNO").fechaHora;
    const horaFin = turno.marcaciones.find(marcacion => marcacion.type === "FIN_TURNO").fechaHora;
  
    const localHoraInicio = new Date(horaInicio)
    const localHoraFin = new Date(horaFin)
    
    // const localHoraInicio = new Date(horaInicio.getTime() + (timeZoneOffset * 60 * 1000));
    // const localHoraFin = new Date(horaFin.getTime() + (timeZoneOffset * 60 * 1000));

 



    
    
    const startDay = new Date(localHoraInicio).getDate();
    const turnosDescription = [{
      nombre : "8.2",
      horaSemana : 8.2,
      horaSabado : 5,
      descanso : 1,
    },
    {
      nombre : "8",
      horaSemana : 8.3,
      horaSabado : 8.3,
      descanso : 0.5
    },
    {
      nombre : "5",
      horaSemana : 5,
      horaSabado : 0,
      descanso : 0
    },
    {
      nombre : "4",
      horaSemana : 4,
      horaSabado : 4,
      descanso : 0
    }
  ]

  let HOplan = turnosDescription.find(turno => turno.nombre === turnoEmpleado).horaSemana;
  let HDescanso = turnosDescription.find(turno => turno.nombre === turnoEmpleado).descanso;
  console.log("----------------->>>>>>>>>>>>>startDay")
  console.log(startDay)
  const startDay2 = new Date(localHoraInicio).getDay();

  if(startDay2 === 6){
    HOplan = turnosDescription.find(turno => turno.nombre === turnoEmpleado).horaSabado;
    console.log("ITS SATURDAY")
    console.log(HOplan)
  }
    let totalHoras = calculateHours( localHoraInicio, localHoraFin );

    const pausas = turno.marcaciones.filter(marcacion => marcacion.type === "INICIO_PAUSA" || marcacion.type === "FIN_PAUSA");
    let pausaSum = 0;
    let marcaPausa = 0

    const pausaPairs = [];
    let currentPausaStart = null;
  if(pausas.length>1 && pausas.length<10){
    for (const marcacion of pausas) {
      if (marcacion.type === "INICIO_PAUSA") {
        currentPausaStart = new Date(marcacion.fechaHora);
      } else if (marcacion.type === "FIN_PAUSA" && currentPausaStart !== null) {
        const pausaEnd = new Date(marcacion.fechaHora);
        const pausaDuration = calculateHours(currentPausaStart, pausaEnd);
        pausaPairs.push({ start: currentPausaStart, end: pausaEnd, duration: pausaDuration });
        currentPausaStart = null;
        marcaPausa=1;
      }
    }
    pausaSum = pausaPairs.reduce((acc, pausa) => acc + pausa.duration, 0);

  }else{
    pausaSum = totalHoras > 6 ? 1 : 0;//Only discount lunch time if the user worked more than 6 hours

  }
  console.log("Pausas", pausaPairs)
  console.log("PausasSum", pausaSum)
  let horasLaboradas = totalHoras - pausaSum;

  let horasNocturnas = calculateNocturnalHours( localHoraInicio, localHoraFin );

  let totalHorasDiurna = horasLaboradas - horasNocturnas;
  
  let isHolidayOrSunday = isHoliday(localHoraInicio) || localHoraInicio.getDay() === 0 || isHoliday(localHoraFin) || localHoraFin.getDay() === 0;

  
  let [ horasDominicales, horasDominicalesNocturnas ] = [0,0];
  
  if(isHolidayOrSunday){
    
    [ horasDominicales, horasDominicalesNocturnas ] = calculateDominicalHours( localHoraInicio,localHoraFin );
    console.log("horas dominicales", horasDominicales)
    
  }
    
    const horaExtraTotal = horasLaboradas > HOplan ?  horasLaboradas - HOplan  : 0;
    const horaExtraDiurna = totalHorasDiurna > HOplan ? totalHorasDiurna - HOplan : 0;
    const horaExtraNocturna = horaExtraTotal - horaExtraDiurna;



    // if(pausas?.length>=2){
    //   for (let i = 0; i < pausas.length; i++) {
    //     console.log("PAUSAS", pausas[i], i)
    //     if(pausas[i].type === "INICIO_PAUSA"){
    //       if (pausas[i+1].type === "FIN_PAUSA"){
    //         try{
    //           pausaSum +=  calculateHours(new Date(pausas[i].fechaHora), new Date(pausas[i+1].fechaHora)) ;
    //           marcaPausa=1;
    //         }
    //         catch(error){
    //           console.log(error)
    //         }
    //       } 
    //     }
    //     i++;
    //   }
    // }
    // else{
    //   pausaSum = totalHorasDiurna > 6 ? 1 : 0;//Only discount lunch time if the user worked more than 6 hours

    // }


    let horaOrdinaria = totalHorasDiurna  > HOplan  ? ( HOplan ):  totalHorasDiurna ;
    horaOrdinaria = horasDominicales > 0 ? 0 : horaOrdinaria;
    horasNocturnas = horasDominicalesNocturnas > 0 ? 0 : horasNocturnas;

    turnoCalculado = {
      id: id,
      marcacionesIds: marcacionesIds,
      userId: turno.marcaciones[0].usuarioId,
      userName: turno.marcaciones[0].userName,
      inicio: localHoraInicio.toISOString(),
      fin: localHoraFin.toISOString(),
      tPausas: +pausaSum.toFixed(1),
      totalHoras: +totalHoras.toFixed(1),
      horasLaboradas: +horasLaboradas.toFixed(1),
      hExtrasTotal: +horaExtraTotal.toFixed(1),
      hOrdinaria: !isHolidayOrSunday? +horaOrdinaria.toFixed(1) : 0,
      hLibre: 0, //HDescanso-pausaSum > 0 ? ( marcaPausa > 0 ? +( HDescanso-pausaSum ).toFixed(1) : 0 ) : 0 ,
      hPendiente: !isHolidayOrSunday&&horasLaboradas-HOplan<0 ? (horasLaboradas-HOplan).toFixed(1) : 0,
      hExtraDiurna: !isHolidayOrSunday? +horaExtraDiurna.toFixed(1):0,
      hExtraNocturna: !isHolidayOrSunday? +horaExtraNocturna.toFixed(1) : 0,
      hOrdinariaDominical: horasDominicales <  HOplan ? horasDominicales :  horasDominicales - horaExtraDiurna.toFixed(1),
      hExtraDominicalDiurna: horasDominicales > 0 ? +horaExtraDiurna.toFixed(1) : 0,
      hExtraDominicalNocturna:  horasDominicalesNocturnas>0 ? +horaExtraNocturna.toFixed(1): 0,
      rNocturno: !isHolidayOrSunday? horasNocturnas-horaExtraNocturna>0 ? +(horasNocturnas-horaExtraNocturna).toFixed(1) : 0 : 0,
      rDominicalNocturno: +(horasDominicalesNocturnas-horaExtraNocturna>0?horasDominicalesNocturnas-horaExtraNocturna:0).toFixed(1),
      TotalHorasExtrasConRecargos:0,
    };

    
    const rHoraExtraDiurna = 1.25;
    const rNocturnoOrdinario = 0.35; // La hora ordinaria no se computa por que se paga en el total mes ordinario solo el recargo
    const rHoraExtraNocturna = 1.75;
    const rDominicalOrdinario = 1.75
    const rHoraExtraDiurnoDominical = 2;
    const rNocturnoDominicalOrdinario = 1.1;
    const rHoraExtraDominicalNocturna = 2.5;

    turnoCalculado["TotalHorasExtrasConRecargos"] = ((turnoCalculado.hExtraDiurna * rHoraExtraDiurna) +
                                                    (turnoCalculado.rNocturno * rNocturnoOrdinario) +
                                                    (turnoCalculado.hExtraNocturna * rHoraExtraNocturna) +
                                                    (turnoCalculado.hOrdinariaDominical * rDominicalOrdinario) +
                                                    (turnoCalculado.hExtraDominicalDiurna * rHoraExtraDiurnoDominical) +
                                                    (turnoCalculado.rDominicalNocturno * rNocturnoDominicalOrdinario) +
                                                    (turnoCalculado.hExtraDominicalNocturna * rHoraExtraDominicalNocturna) +
                                                    (turnoCalculado.hPendiente*1)).toFixed(1);
    console.log(turnoCalculado)

    return turnoCalculado;
  }



function calculateNocturnalHours(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const startHour = start.getTime();
  const endHour = end.getTime();
  const startDay = start.getDate();
  const endDay = end.getDate();
  let nocturnalHours = 0;


  const FiveToday = new Date(startDate.setHours(5, 0, 0, 0)).getTime();
  const NineToday = new Date(startDate.setHours(21, 0, 0, 0)).getTime();
  const FiveTomorrow = new Date(startDate.setHours(5, 0, 0, 0)).getTime() + (24 * 60 * 60 * 1000);
  

  if (startDay === endDay) {
    console.log("INSIDE NOCTURNAL HOURS 1")
    if (startHour > FiveToday && endHour < NineToday) {
    return   0
    }
    if (startHour <= FiveToday) {
      console.log("INSIDE NOCTURNAL HOURS 6")
      nocturnalHours += FiveToday - startHour;
    }
    if (endHour >= NineToday) {
      nocturnalHours += endHour - NineToday;
      console.log("INSIDE NOCTURNAL HOURS 2")
    }
  } else {
    if(startHour <= FiveToday){
      console.log("INSIDE NOCTURNAL HOURS 7")

      nocturnalHours += FiveToday - startHour;
    }
    if(startHour>=NineToday){
      console.log("INSIDE NOCTURNAL HOURS 3")
        if(endHour>=FiveTomorrow){
          console.log("INSIDE NOCTURNAL HOURS 4")
          nocturnalHours +=  startHour-FiveTomorrow;
        }
        else{
          console.log("INSIDE NOCTURNAL HOURS 8")

          nocturnalHours += startHour-endHour;
        }
    }
    if(endHour >= NineToday){
      console.log("INSIDE NOCTURNAL HOURS 9")

      if(endHour <= FiveTomorrow){
        console.log("INSIDE NOCTURNAL HOURS 10")

        nocturnalHours += endHour - NineToday;
        }
      else{
          console.log("INSIDE NOCTURNAL HOURS 11")

          nocturnalHours += FiveTomorrow - NineToday;
        }
      }
    }

  nocturnalHours /= (1000 * 60 * 60);

  return nocturnalHours;
}


function calculateDominicalHours(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const startDateTime = start.getTime();
  const startDay = start.getDay();

  const endDateTime = end.getTime();
  const endDay = end.getDay();

  let dominicalHours = 0;
  let dominicalNocturnalHours = 0;
  // check wheter start or end date is a sunday
    console.log("Starting calculating DOMINICAL HOURS ----------------------->>>>>>>>>>>>>")
  if (startDay === 0 || endDay === 0 || isHoliday(start) || isHoliday(end)) {
    //check if both dates are sundays
    if ( startDay === 0 && endDay === 0 ) {
      console.log("both sundays");
      dominicalHours += calculateHours(start, end);
      dominicalNocturnalHours += calculateNocturnalHours(start, end);
    }
    else{
      //check if start date is sunday
      if (startDay === 0) {
        console.log("start sunday");
        const midNigth = new Date(startDate.setHours(23, 59, 59, 0));
        dominicalHours += (midNigth.getTime() - startDateTime)/(1000*60*60);
        if(startDateTime < new Date(startDate.setHours(21, 0, 0, 0)).getTime()){
          dominicalNocturnalHours += calculateNocturnalHours(start, midNigth);
        }
        else{
          dominicalNocturnalHours = dominicalHours
        }
      }
      //check if end date is sunday
      if (endDay === 0) {
        console.log("end sunday");
        const midNigth = new Date(endDate.setHours(0, 0, 0, 0));
        dominicalHours += (endDateTime - midNigth.getTime())/(1000*60*60);
        if(endDateTime > new Date(endDate.setHours(5, 0, 0, 0)).getTime()){
          dominicalNocturnalHours += calculateNocturnalHours(midNigth, end);
        }else 
        {
          dominicalNocturnalHours = dominicalHours
        }
      }
    }
    if (isHoliday(start) && isHoliday(end)) {
      console.log("both holidays");
      dominicalHours =+ calculateHours(start, end);
      console.log(dominicalHours);
      dominicalNocturnalHours =+ calculateNocturnalHours(start, end);
    }
    else{
      if (isHoliday(start)) {
        console.log("start holiday");
        const midNigth = new Date(startDate.setHours(23, 59, 59, 0));
        dominicalHours += (midNigth.getTime() - startDateTime)/(1000*60*60);
        if(startDateTime < new Date(startDate.setHours(21, 0, 0, 0)).getTime()){
          dominicalNocturnalHours += calculateNocturnalHours(start, midNigth);
        }
        else{
          dominicalNocturnalHours = dominicalHours
        }
      }
      if (isHoliday(end)) {
        const midNigth = new Date(endDate.setHours(0, 0, 0, 0));
        dominicalHours += (endDateTime - midNigth.getTime())/(1000*60*60);
        if(endDateTime > new Date(endDate.setHours(5, 0, 0, 0)).getTime()){
          dominicalNocturnalHours += calculateNocturnalHours(midNigth, end);
        }else 
        {
          dominicalNocturnalHours = dominicalHours
        }
      }
    } 
  }
  console.log("Dominical hours before removing nocturnal",dominicalHours);
  dominicalHours = dominicalHours - dominicalNocturnalHours;
  console.log("Dominical hours after removing nocturnal",dominicalHours);
  
  
  console.log("END calculating DOMINICAL HOURS ----------------------->>>>>>>>>>>>>")
  return [dominicalHours  , dominicalNocturnalHours ];
}

function isHoliday(date) {
  // List of holiday dates in 'yyyy-mm-dd' format
  let holidays = []

  //This is because the holidys are only for colombia, if we want to use this in other countries we need to change this
  const localDateTime = new Date(date.getTime() + (-300 * 60 * 1000));

  getHolidaysByYear(localDateTime.toISOString().substring(0, 4)).map(holiday => {
    let holidayArray = holiday.date.split("/")
    holidays.push(holidayArray[2] + "-" + holidayArray[1] + "-" + holidayArray[0])
    }
  )
  const formattedDate = localDateTime.toISOString().substring(0, 10); // Convert date to 'yyyy-mm-dd' format
  return holidays.includes(formattedDate);
}

