import { 
    Edit,
    NumberInput,
    SimpleForm, 
    TextInput,
    required,
    SelectInput,
} from 'react-admin';
import { responsiveFontSizes } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Calibre } from '../../utils';


const editTransform = (data) => {    

    console.log(data.calibre)
    return(
        {
            id: data.id,
            proveedor: data.proveedor,
            ancho: data.ancho,
            largo: data.largo,
            areaTotal: data.areaTotal,
            cantidadLaminas: data.cantidadLaminas,
            calibre: data.calibre ? data.calibre : null,
            consecutivo: data.consecutivo,
            areaRestante: data.areaRestante,
        }
    )
}

export const CajaEdit = () => {


    return (
        <Edit transform={editTransform}>
          <SimpleForm>
            <Grid container direction="row" columnSpacing={2} justifyContent="space-between">
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextInput fullWidth multiline maxRows={1} source="proveedor" label="PROVEEDOR"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput fullWidth multiline maxRows={1} source="ancho" label="ANCHO"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput fullWidth multiline maxRows={1} source="largo" label="LARGO"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput fullWidth multiline maxRows={1} source="areaTotal" label="ÁREA TOTAL"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput fullWidth multiline maxRows={1} source="cantidadLaminas" label="CANTIDAD LÁMINAS"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput fullWidth multiline maxRows={1} source="consecutivo" label="CONSECUTIVO"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NumberInput fullWidth multiline maxRows={1} source="areaRestante" label="ÁREA RESTANTE"/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <SelectInput fullWidth source="calibre" style={{marginTop: '-0rem'}} label="Calibre(s)" choices={Calibre}/>
              </Grid>
            </Grid>
          </SimpleForm>
        </Edit>
    );
};