import React from 'react'
import {
        SelectInput,
        AutocompleteInput, 
        NumberInput
        } 
        from 'react-admin'
import { useFormContext } from 'react-hook-form' 
import { Box, Grid, Typography } from '@mui/material';
import MountingView from '../components/MountingView'
import { Card } from '@mui/material'
import { Lineatura, Calibres } from '../../utils'
import PosicionSelector from '../components/PosicionSelector'
import WindingSelector from '../components/WindingSelector'
import  RepIcon  from '../components/img/rep-icon.png'
import  PistasIcon  from '../components/img/pistas-icon.png'
import  Wind1Icon  from '../components/img/winding1-icon.png'
import  Wind2Icon  from '../components/img/winding2-icon.png'
import  Wind3Icon  from '../components/img/winding3-icon.png'
import  Wind4Icon  from '../components/img/winding4-icon.png'
import  Wind5Icon  from '../components/img/winding5-icon.png'
import  Wind6Icon  from '../components/img/winding6-icon.png'
import  Wind7Icon  from '../components/img/winding7-icon.png'
import  Wind8Icon  from '../components/img/winding8-icon.png'
import Rip from './Rip'




/*
const tabs2Display = {
    ARTE:['TIPOEMPAQUE', 'MONTAJE' ],
    FINALIZACION: ['TIPOEMPAQUE', 'MONTAJE'],
    SHERPA:['SHERPA'],
    COPIADO: ['RIP'],
    REPETICION: ['LEN'],
    MONTAJE:['LEN']
}

type Montaje {
  pistas: Int
  repeticiones: Int
  rodillo: Float
  paso: Float
  gapPistas: Float
  gapRepeticiones: Float
  anchoBobina: Float
  desarrollo: Float
  truncado: Boolean
  enfrentado: Int
  cantidadTruncado: Float
  prodAncho: Float
  prodAlto: String
  tipoCorte: TipoCorte
  posicionSalida: String
}
*/

const Main = (props)=>{
    const {client, formData} = props
    const isGapRDisabled=true
    const { setValue } = useFormContext()

    const PositionIcons = [ Wind1Icon, Wind2Icon, Wind3Icon, Wind4Icon,Wind5Icon, Wind6Icon, Wind7Icon , Wind8Icon ]

    const handleFormChange = (event,formData) =>{
        let rodillo = event.target.name === 'montaje.rodillo'? event.target.value : formData.montaje.rodillo || 96
        let desarrollo = event.target.name === 'montaje.rodillo'? (event.target.value*25.4/8).toFixed(2) : formData.montaje.desarrollo || 304.8
        let repeticiones = event.target.name === 'montaje.repeticiones' ? event.target.value : formData.montaje.repeticiones || 2
        let Alto = event.target.name === 'montaje.prodAlto' ? event.target.value : formData.montaje.prodAlto || 100
        let Ancho = event.target.name === 'montaje.prodAncho' ? event.target.value : formData.montaje.prodAncho || 100
        let posicion = event.target.name === 'montaje.posicionSalida' ? event.target.value : formData.montaje.posicionSalida || '1'
        
        let gapR = 0
        if(posicion && rodillo > 0 && repeticiones>0){
            if (posicion === '1' || posicion === '2'){
                if( Alto ){
                    gapR = (desarrollo/repeticiones)-Alto
                }
            }
            if (posicion === '3' || posicion === '4'){
                if( Ancho ){
                    gapR = (desarrollo/repeticiones)-Ancho
                }
            }
        }

        setValue('montaje.desarrollo', desarrollo)
        setValue('montaje.gapRepeticiones', gapR.toFixed(2))
    }

    return (
        <Grid container direction='column' spacing={2}  justifyContent='space-evenly'>    
            <Grid item lg={12} >
                <Rip formData={formData} client={client} />
            </Grid>
            <Grid item sm={12} md={9} lg={12} >
                <Card sx={{padding:'10px'}}>
                    <Grid container  direction="row"
                            justifyContent="space-around"
                            alignItems="center">
                        <Grid item md={7} container direction='column'>
                            <Grid item >
                                <Typography variant ='h6' >Parámetros del Producto</Typography>
                            </Grid>
                            <Grid item container direction='row' >
                                <Grid item md={3} sx={{marginRight:'10px'}}  >
                                    <NumberInput label='Ancho' source='montaje.prodAncho' variant='outlined' onChange={(e)=>handleFormChange(e, formData)}/>
                                </Grid>
                                <Grid item md={3}>
                                    <NumberInput label='Alto' source='montaje.prodAlto' variant='outlined' sx={{marginRight:'10px'}} onChange={(e)=>handleFormChange(e, formData)}/>
                                </Grid>
                                <Grid item md={2}>
                                    <NumberInput label='Radio' source='montaje.radio' variant='outlined'/>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography label='Alto' variant='h6' > Parámetros de Montaje</Typography>
                            </Grid>
                            <Grid item container  direction='row' minWidth='100%'  justifyContent='flex-start' >
                                <Grid item md={3}  >
                                    <NumberInput source='montaje.rodillo' label='Rodillo' sx={{marginRight:'10px'}} onChange={(e)=>handleFormChange(e, formData)} />
                                </Grid>
                                <Grid  item md={3}>
                                    <NumberInput source="montaje.desarrollo"  label="Desarrollo" disabled={isGapRDisabled} variant='outlined' />
                                </Grid>
                            </Grid>
                            <Grid item container alignItems='flex-end' justifyContent='space-between'>
                                <Grid item md={5}> 
                                    <PosicionSelector   label='Posición' 
                                                        source ='montaje.posicionSalida' 
                                                        formData={formData}
                                                        defaultValue={'1'}
                                                        {...props}
                                                        onChange={(e)=>handleFormChange(e, formData)}
                                                        />
                                </Grid>
                                <Grid item md={3}> 
                                    <WindingSelector   label='Embobinado' 
                                                        source ='montaje.embobinado' 
                                                        formData={formData}
                                                        {...props}
                                                        />
                                </Grid>
                                <Grid item md={3} sx={{marginRight:'10px'}} >
                                    <Box sx={{margin:'10px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width='70px'height='55px'>
                                            <g>
                                                <image href={formData.montaje?(PositionIcons[(((Math.abs(parseInt(formData.montaje.posicionSalida)*(parseInt(formData.montaje.embobinado)-2)))+(((parseInt(formData.montaje.embobinado)-1)*(parseInt(formData.montaje.posicionSalida)+4))))-1)]):(PositionIcons[1])} x='0' y='0' height={'100%'} />
                                            </g>
                                        </svg>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container direction='row' justifyContent='space-between'>
                                <Grid item md={3} sx={{marginRight:'10px'}} >
                                    <NumberInput label='Repeticiones' source='montaje.repeticiones' onChange={(e)=>handleFormChange(e, formData)} />
                                </Grid>
                                <Grid item  md={3}>
                                    <NumberInput label='Gap Avance' source='montaje.gapRepeticiones' variant='outlined' disabled />
                                </Grid>
                                <Grid item md={3} sx={{marginRight:'10px'}} >
                                    <Box sx={{margin:'10px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width='70px'height='55px'>
                                            <g>
                                                <image href={RepIcon} x='0' y='0' width={'100%'} />
                                            </g>
                                        </svg>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item container direction='row' justifyContent='space-between' >
                                <Grid item md={3} sx={{marginRight:'10px'}} >
                                    <NumberInput  label='Pistas' source='montaje.pistas' variant='outlined'/>
                                </Grid>
                                <Grid item md={3} >
                                    <NumberInput label='Gap Ancho' source='montaje.gapPistas' variant='outlined'/>
                                </Grid>
                                <Grid item md={3} sx={{marginRight:'10px'}} >
                                    <Box sx={{margin:'10px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width='70px'height='55px'>
                                            <g>
                                                <image href={PistasIcon} x='0' y='0' width={'100%'} />
                                            </g>
                                        </svg>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={5}   >
                            <MountingView formData={formData}/>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            
        </Grid>
    )
}

export default Main