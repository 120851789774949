import { options } from "@fullcalendar/core/preact";
import { getUrl } from "aws-amplify/storage";
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";



export function useStorageField({
  source,
  record: file = {},
  storageOptions = {},
}){
  const [fieldProps, setFieldProps] = useState(null);
  const record = useRecordContext();

  useEffect(() => {
    if (!source || !record[source]) {
      return;
    }

    // Case when using the field with an array of files
    if (Array.isArray(record[source])) {
      Promise.all(
        record[source].map(async (value) => {
          if (!value.key) {
            return;
          }

          const res = await getUrl({key:value.key, options: { accessLevel: "guest"}});
          const url =  await res.url.href;

          if (typeof url !== "string") {
            return;
          }

          return {
            ...value,
            _url: url.url.href,
            _title: value.key.split(/_(.+)/)[1],
          };
        })
      ).then((values) => {
        const updatedRecord = { ...record };
        updatedRecord[source] = values.filter((v) => !!v);

        setFieldProps({
          source,
          record: updatedRecord,
          title: "_title",
          src: "_url",
        });
      });

      return;
    }

    if (!record[source].key) {
      return;
    }

    // Default case when rendering a single file
    getUrl({key:record[source].key, options:{accessLevel:'guest'}}).then(async (res) => {
      const url =  await res.url.href;

      if (typeof url !== "string") {
        return;
      }

      const updatedRecord = { ...record };
      updatedRecord[source]._url = url;

      setFieldProps({
        source: `${source}._url`,
        record: updatedRecord,
        title: record[source].key.split(/_(.+)/)[1],
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Field within an AmplifyInput
  useEffect(() => {
    console.log("source", source);
    console.log("file", file);
    if (source || !file.key) {
      console.log("NOT SOURCE OR FILE KEY", source, file.key);
      return;
    }

    getUrl({key:file.key, options:{accessLevel:"guest"}}).then(async (res) => {
      const url =  await res.url.href;
      if (typeof url !== "string") {
        return;
      }

      const updatedFile = { ...file };
      updatedFile._url = url;
      console.log("UPDATED FILE", updatedFile);
      console.log("FILE", file.key.split(/_(.+)/)[1]);
      setFieldProps({
        source: "_url",
        record: updatedFile,
        title: file.key.split(/_(.+)/)[1],
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.key]);

  return fieldProps;
}



// import { getUrl } from "@aws-amplify/storage";
// import React from "react";


// export function useStorageField({
//   source,
//   record = {},
//   storageOptions = {},
// }) {
//   const [fieldProps, setFieldProps] = React.useState(null);

//   React.useEffect(() => {
//   console.log("RECORD", record);
//   console.log("SOURCE", source);
//     async function getUrlFunction(key) {
//       const url = await getUrl({
//           key, 
//           options:{ accessLevel: "guest"}
//     });
//       console.log("URL", await url); 
//       return url.url.href;
//     }

//     // Case when using the field within an AmplifyInput
//     if (!source) {
//       if (!record.key) {
//         console.log("NOT RECORD KEY", record.key);
//         return;
//       }

//       getUrlFunction(record.key)
//         .then((url) => {
//           console.log("CASE WHEN RENDERING FROM AMPLIFY INPUT", url);

//           console.log("URL", url);
//           if (typeof url !== "string") {
//             return;
//           }

//           const updatedRecord = { ...record };
//           console.log("UpdatedRecord", updatedRecord);
//           updatedRecord._url = url;
//           console.log ("UpdatedRecord", updatedRecord);
//           setFieldProps({
//             source: "_url",
//             record: updatedRecord,
//             title: record.key.split(/_(.+)/)[1],
//           });
//         })
//         .catch(() => {});

//       return;
//     }

//     if (!record[source]) {
//       console.log("NOT RECORD SOURCE", record[source]);
//       return;
//     }

//     // Case when using the field with an array of files
//     if (Array.isArray(record[source])) {
//       Promise.all(
//         record[source].map(async (value) => {
//           if (!value.key) {
//             return;
//           }
//           console.log("CASE WHEN RENDERING ARRAY OF FILES", url);


//           const url = await getUrlFunction(value.key);

//           if (typeof url !== "string") {
//             return;
//           }

//           return {
//             ...value,
//             _url: url,
//             _title: value.key.split(/_(.+)/)[1],
//           };
//         })
//       )
//         .then((values) => {
//           const updatedRecord = { ...record };
//           updatedRecord[source] = values.filter((v) => !!v);

//           setFieldProps({
//             source,
//             record: updatedRecord,
//             title: "_title",
//             src: "_url",
//           });
//         })
//         .catch(() => {});

//       return;
//     }

//     if (!record[source].key) {
//       return;
//     }

//     // Default case when rendering a single file
//     getUrlFunction(record[source].key)
//       .then((url) => {
//         if (typeof url !== "string") {
//           return;
//         }
//         console.log("DEFAULT CASE WHEN RENDERING A SINGLE FILE", url);
//         const updatedRecord = { ...record };
//         console.log("UpdatedRecord", updatedRecord);
//         updatedRecord[source]._url = url;

//         setFieldProps({
//           source: `${source}._url`,
//           record: updatedRecord,
//           title: record[source].key.split(/_(.+)/)[1],
//         });
//       })
//       .catch(() => {});
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [record.key]);

//   return fieldProps;
// }
