import * as React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { useInput, Labeled } from 'react-admin';
    
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: '20px',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '& .MuiToggleButton-label': {
    pointerEvents: 'none',
  },
}));

const ButtonGroupInput = (props) => {
    const {
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest
    } = props;
    const { field } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        defaultValue: '1',
        validate,
        ...rest,
    });
    
    return (
        <Labeled label="Posición">
            <StyledToggleButtonGroup exclusive {...field}>
                <StyledToggleButton value='1'>
                    <SvgPosition rot={0} />
                </StyledToggleButton>
                <StyledToggleButton value='2'>
                    <SvgPosition rot={180} />
                </StyledToggleButton>
                <StyledToggleButton value='3'>
                    <SvgPosition rot={90} />
                </StyledToggleButton>
                <StyledToggleButton value='4'>
                    <SvgPosition rot={270} />
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        </Labeled>
    );
};


const SvgPosition = ({rot})=>{
    
    return( 
        <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' pointerEvents="none">
            <g
                aria-label="A"
                transform={`rotate(${rot||0}, 9,9) `}
                >   
                <path
                d="m 16.240234,18 h -2.266601 l -1.56836,-4.458008 H 5.4873047 L 3.9189453,18 H 1.7597656 L 7.5820312,2.0048828 H 10.417969 Z M 11.75,11.71582 8.9462891,3.8632813 6.1318359,11.71582 Z"
                fill='dimGrey' />
            </g>
        </svg>
)}

export default ButtonGroupInput;
