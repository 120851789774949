import React from "react";
import {
  SimpleFormIterator,
  TextInput,
  AutocompleteInput,
  useRecordContext,
  ArrayInput,
  FormDataConsumer,
  SelectInput
} from "react-admin";

import get from 'lodash/get';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {matchSorter} from 'match-sorter'
import { useFormContext, useWatch } from 'react-hook-form' 
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { Box, Grid, Typography } from '@mui/material';
import {PSC} from '../../utils'

const GruposAngulos = {
                    'FlexoPlus7':{'cyan': 22.5,
                                'magenta': 82.5,
                                'yellow': 7.5,
                                'black': 52.5
                                },
                    'FlexoMinus7': {
                                'cyan': 22.5,
                                'magenta': 82.5,
                                'yellow': 7.5,
                                'black': 52.5
                        }
                    }

                    
const findAngle = (inkName, colorValues, inks, grupoAngulosAsignado, index)=>{
    //grupoAngulosAsignado should be a 'FlexoPlus7' || 'FlexoMinus7'
    //inks = formData.rip full list
    // colorValues should be Color Values color space to chek where to place it
    // ink = ink name 

    let ink = inkName.toLowerCase()

    let angle = 37.5
    const processColors = ['cyan', 'magenta', 'yellow', 'black']


    if(ink.toLowerCase() === 'blanco' || ink.toLowerCase() ==='opaque white' )
    {
        return 37.5
    }
    // ink drop 
    if(processColors.indexOf(ink.toLowerCase()) !== -1){
        return GruposAngulos[grupoAngulosAsignado][ink.toLowerCase()]
    }
    if(processColors.indexOf(ink.toLowerCase()) === -1){
        if(inks.length <= 4){
            return GruposAngulos[grupoAngulosAsignado][processColors[index]]
        }
        else{
            if (colorValues.reduce((a,b)=>a+b,0)/3<180){
              return  GruposAngulos[grupoAngulosAsignado][processColors[0]]
            }
            if (colorValues[0]>180){
                return GruposAngulos[grupoAngulosAsignado][processColors[1]]
            }
            if (colorValues[1]>180){
                return GruposAngulos[grupoAngulosAsignado][processColors[0]]
            }
            if (colorValues[2]>180){
                return GruposAngulos[grupoAngulosAsignado][processColors[3]]
            }
        }
    }


}


const inputText = choice => (choice ? `${choice.name}`:null);

const matchSuggestion = (filter, choice) => {
    //console.log(choice.name.toLowerCase().includes(filter.toLowerCase()))
    return (
        choice.name.toLowerCase().includes(filter.toLowerCase())
    );
};

const OptionRenderer =  ( ) => {
    const record = useRecordContext(); 
    return(
        <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Grid item xs zeroMinWidth>
                <Typography gutterBottom  component={'div'} noWrap style={{'overflowWrap': 'break-word'}}>
                    <Box  sx={{ width:'50px', maxWidth:'40px' ,marginLeft:'2px', marginRight:'5px'}}>{`${record.name}`}</Box> 
                </Typography>
            </Grid>
            <Grid item>
                <Typography component={'div'}>
                    <Box sx={{bgcolor:`rgb(${record.R}, ${record.G}, ${record.B})`}}><div style={{width:'20px', height:'20px'}}/></Box>
                </Typography>
            </Grid>                     
        </Grid>
    )}
const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue);



const ColorPicker = (props) => {
     const { defaultLpi } = props
    const colores = useWatch({name:'rip.colores'})
    const { setValue } = useFormContext();

    const handleColorChange = (color, index)=>{        
        if(color){
            let data = getSelectedItems(PSC, color, 'name')
            let { name, angle, opaque, ...colorData  } = data
            //console.log(colorData)
            setValue(`rip.colores.${index}.index`, index)
            setValue(`rip.colores.${index}.colorData`, colorData)
        }
    }

    const getRGB=(coloresArr, index)=>{
        let sR = 200;
        let sG =  200;
        let sB =  200;
        let angle =0
        //console.log(sR,sG,sB)
        if(coloresArr[index] === undefined || coloresArr[index].colorData === undefined || coloresArr[index].colorData.R === undefined) {return `${sR,sG,sB}`}
        
        sR = coloresArr[index].colorData.R || 0;
        sG = coloresArr[index].colorData.G || 0;
        sB = coloresArr[index].colorData.B || 0;    

        const rgb=`${sR},${sG},${sB}`
        if(sR>100 && sG>100 && sB>100)
        { 
            angle = 1
        }
        else
        {
            angle= 2
        }
        setValue(`rip.colores.${index}.angulo`, angle)
        return rgb
        }
return(
    <ArrayInput source='rip.colores' label='Tintas' >
        <SimpleFormIterator>
            <FormDataConsumer>{({getSource, scopedFormData, key})=>(
                <Grid container direction='row' spacing={2} alignItems='space-around7647'>
                    <Grid item md={4} container direction='row' spacing={1}>
                        <Grid item lg={1} sx={{marginRight:'2px'}}>
                            <Box 
                            sx={{
                                bgcolor: `rgb(${getRGB(colores, getSource('nombre').split('.')[2])})`,
                                                width:'30px', height:'38px', borderRadius:'2px',
                                                }}><div style={{width:'20px', height:'20px'}}/>
                            </Box>

                                
                        </Grid>
                        <Grid item lg={10}> 
                            <AutocompleteInput
                                        source={getSource('nombre')} 
                                        record={scopedFormData}
                                        matchSuggestion={matchSuggestion}
                                        focu
                                        choices={PSC} 
                                        shouldRenderSuggestions={(value) => value.trim().length > 0}
                                        optionText={<OptionRenderer/>} 
                                        optionValue={'name'}
                                        inputText={inputText} 
                                        suggestionLimit={100}
                                        noOptionsText='Inicia digitando un color'
                                        size='small' fullWidth
                                        onChange={color=>handleColorChange(color, getSource('nombre').split('.')[2])}
                                        /> 
                                        
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <TextInput source={getSource('angulo')} record={scopedFormData} />
                    </Grid>
                    <Grid item md={2}>
                        <TextInput source={getSource('lpi')} record={scopedFormData} defaultValue={defaultLpi}/>
                    </Grid>
                    <Grid item md={4} >
                        <TextInput source={getSource('bumpUp')} record={scopedFormData} fullWidth/>
                    </Grid>
                </Grid>
                )}
            </FormDataConsumer>
        </SimpleFormIterator>
    </ArrayInput>
    );
}

const getSelectedItems = (
    choices = [],
    value,
    optionValue = 'id',
) => {
    return choices.find(choice => get(choice, optionValue) === value) || '';
};


/*
const ColorForm = () => (
  <RowForm>
    <AutocompleteInput 
                        filterOptions={filterOptions}
                        source='rip.colores.nombre' 
                        matchSuggestion={matchSuggestion}
                        choices={PSC} 
                        shouldRenderSuggestions={(value) => value.trim().length > 1}
                        optionText={<OptionRenderer/>} 
                        optionValue={choice=>choice?choice.nombre:null}
                        inputText={inputText} 
                        suggestionLimit={5000}
                        noOptionsText='Inicia seleccionando un color'
                        size='small' fullWidth/>
        <TextInput source='rip.colores.index'/>  
        <TextInput source="rip.colores.nombre" />
        <TextInput source="rip.colores.angulo" />
        <TextInput source="rip.colores.lpi" />
        <TextInput source="rip.colores.bumpUp" /> 
  </RowForm>
);
*/


export default ColorPicker



/*

type Color {
  nombre: String
  libro: String
  angulo: Float
  index: Int
  punto: String
  lpi: Int
  bumpUp: String
  microCell: String
}

*/
