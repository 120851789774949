import * as React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, ArrayField, FunctionField, Datagrid, NumberField, required, ShowView, Labeled, ImageField, ReferenceField} from 'react-admin';
import { Grid, responsiveFontSizes} from '@mui/material';
import { AmplifyImageField } from '../Amplify';
import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserExposicionByPin } from '../graphql/queries'
import ImageView from './components/ImageView';
import LenView from './components/LenView';
import Divider from '@mui/material/Divider';
import ShowExposicionLayout from './components/ShowExposicionLayout';

export const ExposicionShow = (props) => {
  const { record } = props;
  {/*
  const [responsableName, setResponsableName] = useState('');
  
  const fetchResponsableName = async () => {
    try {
      if (record && record.responsablePin) {
        const response = await API.graphql(
          graphqlOperation(getUserExposicionByPin, { id: record.responsablePin })
        );
  
        console.log('Response from getUserExposicionByPin:', response);
  
        const name = response.data.getUserExposicionByPin.name || 'Nombre del Responsable No Disponible';
        setResponsableName(name);
      }
    } catch (error) {
      console.error('Error fetching UserExposicion:', error);
      setResponsableName('Nombre del Responsable No Disponible');
    }
  };
    
  useEffect(() => {
    if (record && record.responsablePin) {
      console.log('responsablePin:', record.responsablePin);
      fetchResponsableName();
    }
  }, [record]);
  
  */}

  return (
    <Show style={{responsiveFontSizes}}>
      <SimpleShowLayout>
        <Grid container flexDirection='row' justifyContent="space-evenly" spacing={2}>
          <Grid item container xs={7} sm={4} md={2} lg={2} style={{marginLeft:'5rem'}} sx={{'& img':{minWidth:250, minHeight:250}}}>
            <ImageView/>
          </Grid>
          <Divider orientation="vertical" flexItem style={{marginLeft:'1rem'}}/>
          <Grid item container xs={6} sm={3} md={2} lg={2} style={{marginLeft:'3rem'}}>
            <ShowExposicionLayout/>
          </Grid>
          <Divider orientation="vertical" flexItem style={{marginLeft:'5rem'}}/>
          <Grid item container flexDirection='column' justifyContent="space-evenly" style={{marginLeft:'2rem'}} spacing={1} xs={12} sm={12} md={6} lg={4}>
            <Labeled>
              <ReferenceField label='Responsable' source='responsableId' reference='userExposicions' >
                <TextField label="Responsable" source='name' style={{fontWeight:'bold'}}/>
              </ReferenceField>
            </Labeled>
            <Labeled>
              <NumberField source="cajas" label="Cajas" style={{fontWeight:'bold'}}/>
            </Labeled>
            <Labeled>
              <NumberField source="taras" label="Taras" style={{fontWeight:'bold'}}/>
            </Labeled>
            <Labeled>
              <TextField source='motivo' label='Motivo Anulación' style={{fontWeight:'bold'}}/>
            </Labeled>
            <Labeled>
              <NumberField source='cajaId' label='Cajas Usadas' style={{fontWeight:'bold'}}/>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={12}>
            <ArrayField source="len" label="LEN">
              <Datagrid bulkActionButtons={false}>
                <LenView source="image" label="IMAGEN EXPOSICION"/> 
                <TextField source="uuid" label="ID Len"/>
                <TextField source="name" label="Nombre Len"/>
                <NumberField source="lenWidth" label="Ancho Len"/>
                <NumberField source="lenHeight" label="Altura Len"/>
                <NumberField source="area" label="Área Len"/>
              </Datagrid>
            </ArrayField>
          </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
)};
