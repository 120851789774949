
import React, {useEffect } from 'react';
import {
    useListContext,
    useDataProvider,
} from 'react-admin';

import { formatCurrencyString } from '../../utils';
import { Card, Divider, Typography, Stack, Box } from '@mui/material';



const Summary = (props) => {
    const {filterable: Filterable, agg: Aggregations, resource} = props;
    const listContext = useListContext();
    const dataProvider = useDataProvider();
    const [totalPesos, setTotalPesos] = React.useState(0);
    const [totalArea, setTotalArea] = React.useState(0);
    const [totalItems, setTotalItems] = React.useState(0);
    const [totalPending, setTotalPending] = React.useState(0);
    useEffect(() => {

    dataProvider.aggregates(resource, {pagination:{page:1,perPage:1}, sort:{...listContext.sort}, filter:{...listContext.filterValues}, meta:{searchable:true, agg:Aggregations, filterable: Filterable}})
    .then((data)=>(
        setTotalPesos(data.aggregates.filter((item)=>item.name === 'TotalPesos')[0].result?.value || 0),
        setTotalPending(data.aggregates.filter((item)=>item.name === 'Pendiente')[0].result?.value || 0),
        setTotalArea(data.aggregates.filter((item)=>item.name === 'TotalArea')[0].result?.value || 0),
        setTotalItems(data.total)
    ));
    }, [listContext.filterValues, listContext.sort]);
    
    
    return (
        <Box sx={{margin:'10px', marginTop:'65px'}}>
            <Stack direction={'column'} spacing={2} alignContent={'baseline'} sx={{minWidth:'100%'}}   divider={<Divider orientation="horizontal" flexItem />}>
                <Stack  direction={'column'} alignContent={'center'} padding={'10px'}>
                    <Typography variant='subtitle2' align='center' >Total Cotizaciones</Typography>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }} align='center'>{totalItems.toLocaleString('es-CO')}</Typography>
                </Stack>
                <Stack  direction={'column'} alignContent={'center'} padding={'10px'}>
                    <Typography variant='subtitle2' align='center' >Total Area</Typography>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }} align='center'>{totalArea.toLocaleString('es-CO')}</Typography>
                </Stack>
                <Stack direction={'column'}  padding={'10px'}>
                    <Typography variant='subtitle2' align='center'>Pendiente</Typography>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }} align='center'>{formatCurrencyString(totalPending,0)}</Typography>
                </Stack>    
                <Stack direction={'column'}  padding={'10px'}>
                    <Typography variant='subtitle2' align='center'>Total</Typography>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }} align='center'>{formatCurrencyString(totalPesos,0)}</Typography>
                </Stack>    
                
            </Stack>
        </Box>
    )
}
export default Summary;