import React from 'react'
import { 
    Create,
    SimpleForm, 
    TextInput, 
    NumberInput,
    ReferenceInput, 
    SelectInput, 
    AutocompleteInput,
    PasswordInput
} from 'react-admin';
import Grid from '@mui/material/Grid'
import { API, graphqlOperation } from 'aws-amplify';
import { createUserExposicion } from '../../graphql/mutations';
 {
const editTransform = (data) => {
    
    console.log(data)
    const name = 'custom:user'
    const formatted = [
        {
            "Name": "id",
            "Value": data.id
        },
        {
            "Name": "custom:user",
            "Value": data[name]    
        },
        {
            "Name": "pin",
            "Value": data.pin
        },

    ]

    return(formatted)
}
    {/*
    return(
        {
            id: data.id,
            name: data.name,
            pin: data.pin,
        }
        )
    */}
}

export const UserExposicionCreate = () => {

    return(
        <Create redirect='list'>
            <SimpleForm p={2}>
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between'>
                    {/*
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextInput fullWidth source='id' label='ID'/>
                    </Grid>
                    */}
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <TextInput fullWidth source='name' label='Usuario Producción'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <PasswordInput fullWidth source='pin' label='Pin'/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};