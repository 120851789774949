import * as React from 'react';
import { Fragment } from 'react';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
    styled
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslate, useSidebarState } from 'react-admin';

const IconContainer = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(5),
}));

const StyledList = styled(List)(({ theme, open }) => ({
    '& a': {
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        paddingLeft: theme.spacing(open ? 5 : 3),
    },
}));

const SubMenu = ({ handleToggle, isOpen, name, icon, children, dense }) => {
    const translate = useTranslate();
    const [open] = useSidebarState();
    const sidebarIsOpen = open;

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                <MenuItem dense={dense} onClick={handleToggle}>
                    <IconContainer>
                        {isOpen ? <ExpandMore /> : icon}
                    </IconContainer>
                    <Typography variant="inherit" color="textSecondary">
                        {translate(name)}
                    </Typography>
                </MenuItem>
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    <MenuItem dense={dense} onClick={handleToggle}>
                        <IconContainer>
                            {isOpen ? <ExpandMore /> : icon}
                        </IconContainer>
                        <Typography variant="inherit" color="textSecondary">
                            {translate(name)}
                        </Typography>
                    </MenuItem>
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto">
                <StyledList dense={dense} component="div" disablePadding open={sidebarIsOpen}>
                    {children}
                </StyledList>
            </Collapse>
        </Fragment>
    );
};

export default SubMenu;
