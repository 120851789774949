import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactComponent as Logo } from './Logo.svg';

const StyledTypography = styled(Typography)({
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxHeight: '35px',
    marginTop: '10px',
    marginBottom: '5px'
});

const StyledLogo = styled(Logo)({
    flex: 1,
    maxHeight: '35px',
    marginTop: '10px',
    marginBottom: '5px'
});

const Spacer = styled('span')({
    flex: 1,
});

const AppBarComp = props => {
    return (
        <AppBar {...props}>
            <StyledTypography variant="h6" color="inherit" id="react-admin-title" />
            <StyledLogo />
            <Spacer />
        </AppBar>
    );
};

export default AppBarComp;
