
import React, { useEffect, useState } from 'react';
import { BooleanInput, NumberInput, ReferenceInput, AutocompleteInput, TextInput, useGetList, required, NumberField} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import AreaInputCustom from './AreaInputCustom';
import { Grid, Tooltip } from '@mui/material';
import { id } from 'react-date-range/dist/locale';
import { Typography, Box, Divider } from '@mui/material';

const StockCreateLayout = (props) => {
    const [changed, setChanged] = useState(false)
    const user= localStorage.getItem('user')
    const materialID = useWatch({name: 'materialID'});

    const isActivado = useWatch({name: 'activa'});
    const isIngresado = useWatch({name: 'ingresada'});
    const isCerrado = useWatch({name: 'cerrada'});
    const [manualConsecutivo, setManualConsec] = useState(false)
    const { setValue } = useFormContext();
    
    const { data:stock }= useGetList('stocks', {page: 1, perPage: 1, filter: {materialID: {eq:materialID}}, sort: {field: 'consecutivo', order: 'DESC'}, meta:{searchable: 'true'}})

    const [isConsecutivo, setIsConsecutivo] = useState(false)
    
    let maxConsecutivo = 0
    if (stock && stock.length > 0 && materialID && !manualConsecutivo){
      // search for the max consecutivo
      maxConsecutivo = Math.max(...stock.map(o => o.consecutivo));
      if(materialID && maxConsecutivo){
        setValue('consecutivo', maxConsecutivo+1)
        setValue('id', `${materialID}-${maxConsecutivo+1}`)
      }
    }
    

    const handleFormChange = (e) => {
      setChanged(!changed)
    }
  
    const handleActiva = (e) => {
      if (e.target.checked)
      {
        setValue('usuarioActiva', user)
      }
      else
      {
        setValue('usuarioActiva', '')
      }
    }
  
    const handleIngreso = (e) => {
      if (e.target.checked)
      {
        setValue('usuarioIngresa', user)
      }
      else
      {
        setValue('usuarioIngresa', null)
        
      }
    }   
    const handleManualConsec = (e) => {
      if (e.target.checked)
      {
        setManualConsec(true)
      }else{
        setManualConsec(false)
      }
    }
    const handleConsecChange = (e) => {
      setValue('id', `${materialID}-${e.target.value}`)
    }
    const handleCierra = (e) => {
      if (e.target.checked)
      {
        setValue('usuarioCierra', user)
        setValue('activa', false)
        setValue('ingresada', false)
      }
      else
      {
        setValue('usuarioCierra', '')
      }
    }
    const filterToQuery = searchText => ({ id: {contains: searchText.toUpperCase()}});
    
    const handleMaterialChange = (e) => {
      console.log("Materila change", e)
    }

    return (
        <Grid container spacing={2} >
          {
            props.standalone ? 
            <span></span>
            :
            <Grid item sm={12} md={2} >
              <ReferenceInput source="materialID" reference="materials" fullWidth>
                <AutocompleteInput optionText='id' label='Material' filterToQuery={filterToQuery} size='small' onChange={handleMaterialChange}/>
              </ReferenceInput>
            </Grid>
          }
          <Grid item sm={12} md={2} >
              <TextInput source="lote" fullWidth/>
          </Grid>
          <Grid item sm={12} md={2} >
              <BooleanInput source="activa" onChange={handleActiva} fullWidth disabled={isCerrado}/>
          </Grid>
          <Grid item sm={12} md={2} >
              <BooleanInput source="ingresada" onChange={handleIngreso} fullWidth disabled={!isActivado} />
          </Grid>
          <Grid item sm={12} md={2} >
              <BooleanInput source="cerrada" onChange={handleCierra} fullWidth defaultValue={false} disabled={!(!isIngresado === !isActivado)} />
          </Grid>
          <Grid item sm={12} md={2} >
              { manualConsecutivo ? <NumberInput source="consecutivo" onChange={handleConsecChange} validate={required()} /> :
                <ConsecutivoField  material={materialID} stock={maxConsecutivo} />}
          </Grid>
          <Grid item sm={12} md={1} >
              <BooleanInput source="manualConsec" onChange={handleManualConsec} fullWidth defaultValue={false} label='Modificar Consecutivo' />
          </Grid>
          <Grid item sm={12} md={4} >
            <NumberInput source="anchoLamina" onChange={handleFormChange} fullWidth />
          </Grid>
          <Grid item sm={12} md={4} >
            <NumberInput source="largoLamina" onChange={handleFormChange} fullWidth />
          </Grid>
          <Grid item sm={12} md={4} >
            <NumberInput source="cantidad" onChange={handleFormChange} fullWidth />
          </Grid>
          <Grid item sm={12} md={6} >
            <AreaInputCustom source="areaTotal" changedData={changed} />
          </Grid>
          <Grid item sm={12} md={6} >
            <AreaInputCustom source="areaRestante" />
          </Grid>
        </Grid>
    );
  }
  
  const ConsecutivoField = (props) => {
    useEffect(() => {
      console.log('ConsecutivoField', props)
      }, [props.material]
    )
    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
            <Typography variant="caption" style={{ fontSize: '12px' }}>consecutivo:</Typography>
            <Divider  />
            <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bold' }}>{props.stock + 1}</Typography>
          </Box>
        </Box>
    );
  }



  
  export default StockCreateLayout