import React from "react";
import { ImageField, useRecordContext } from "react-admin";
import { useStorageField } from "../hooks/useStorageField";

export const AmplifyImageLenField = ({ source, storageOptions = {}, record, ...props }) => {
  const recordFromContext = useRecordContext(props);
  const recordToUse = record || recordFromContext;
  const fieldProps = useStorageField({ source, record: recordToUse, storageOptions });

  if (!fieldProps) {
    return null;
  }

  return <ImageField {...fieldProps} {...props} />;
};