import React from "react";
import { Grid } from "@mui/material";

import {   SelectInput } from "react-admin";
import { RowForm } from "@react-admin/ra-editable-datagrid";
import { FpsDedos, FpsDevices } from "../../utils";

export const CreateHuellaDialog = (props)=>{
    const record = props.r;
    //console.log(record)
      return(
      <RowForm defaultValues={{ id: record?.id, userID: record?.userID, userName: record?.userName, status:"NO_REGISTRADO"  }} >
          <Grid container spacing={2} direction='row' alignContent={"center"} >
          <Grid item >
              <SelectInput source='device'  label= 'Dispositivo'   required
                choices={FpsDevices}/>
            </Grid>
            <Grid item>
                <SelectInput source='finger' label= 'Dedo a Registrar' required
                    choices={FpsDedos}/>
            </Grid>                                          
          </Grid>                                                                           
      </RowForm> 
     )
 }

