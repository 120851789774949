import React, {useCallback, useState, useEffect} from 'react';
import { List, Datagrid, TextField, DateField, TextInput, useListContext, UrlField, TopToolbar, CreateButton, DateInput ,
    useRecordContext, useNotify, Pagination, useDataProvider, useRefresh, ChipField, WrapperField,
    EditButton,
    NumberField,
    useRedirect
} from 'react-admin';
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { formatCurrencyString } from '../../utils';
import moment from 'moment-timezone';
import {Button} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { ElasticTranscoder } from 'aws-sdk';
import { DeleteRemision } from '../components/DeleteRemision';
import { generatePDF } from '../functions/generateRemisionPDF';
import AlegraIcon from "../components/alegraIcon";
import EditIcon from '@mui/icons-material/Edit';

const FacturaFilters = [
    <TextInput label="Numero" source="alegraNumeracion.wildcard" alwaysOn format={v=>v?.replaceAll('*', '')||''}/>,
    <TextInput source="clienteName.wildcard" label='Cliente' alwaysOn format={v=>v?.replaceAll('*', '')||''}/>,
]

const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;


const FacturaList = () => (
    // <List queryOptions={{meta:{searchable:'true'}}} filters={FacturaFilters} sort={{field: 'fechaCrea', order: 'DESC'}} debounce={500} actions={<ListActions/>} pagination = {<ListPagination />} perPage={50} title={'Facturas'} >
    <List >
        <Datagrid >
            <TextField source="alegraNumeracion" label='Número'/>
            <TextField source="clienteName" label='Cliente' />
            <DateField source="fechaCrea" label='Fecha Creación'/>
            {/* <StatusField /> */}
            <NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 1 }} />
            <NumberField source="totalSinRetenciones" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 1 }} />
            {/* <WrapperField label="Acciones">
                <AlegraButton />
                <ImprimirButton   />
                <EditButtonIcon />
                <DeleteRemision type='icon' />
            </WrapperField> */}
        </Datagrid>
    </List>
);
const AlegraButton = () => {
    const record = useRecordContext();
    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                window.open(record.url, '_blank');
            }}
        >
            <AlegraIcon/>
        </IconButton>
    );
}

const EditButtonIcon = () => {
    const redirect = useRedirect();
    const record = useRecordContext();

    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                redirect(`/facturas/${record.id}`);
            }}
        >
            <EditIcon/>
        </IconButton>
    );
}

const StatusField = () => {       
    const record = useRecordContext();
    if (record.estado === "Cobrada") {
        return <ChipField source="estado" color="success" variant='outlined' />;
    }
    if (record.estado === "Editada") {
        return <ChipField source="estado"  color="info"   variant='outlined' />;
    }
    if (record.estado === "Borrador") {
        return <ChipField source="estado"  color="info"   variant='outlined' />;
    }
    if (record.estado === "Abonada") {
        return <ChipField source="estado" color="warning" variant='outlined' label="Estado" />;
    }
    return <ChipField source="estado"  color="primary" variant='outlined'/>;
};

const ImprimirButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false); 
    
    //console.log('RECORD', record)

    return(
    <IconButton
        variant='outlined'
        onClick={async () => {
            setLoading(true);
            const res = await dataProvider.getOne('clientes', { id: record.clienteId })
            // set client info to generate PDF
            
            if(res.data){
                const client = {name : res.data.razonSocial, identification: res.data.nit_cedula, address:{address: res.data.direccion}, phonePrimary: res.data.telefono1}

                setLoading(false);
                //generatePDF(record, client);
                }
            }

        }
    >{

        loading ?  <DownloadingIcon/> :
        <PrintIcon/>
    }
    </IconButton>
);
}

const ListActions = () => {
    const { data } = useListContext();
    //console.log('DATA', data);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleUpdate = async () => {

        // Filter the data to get only the elements with estado "emitida"
        const emitidaData = data.filter(item => item.estado === "Emitida");
        // Loop through the emitidaData and update the estado to "Facturada" if the status is "closed"
        for (const item of emitidaData) {
            try {
                const response = await dataProvider.getOne('alegraRemissions', { id: item.alegraId });
                if (response?.data.status === "closed") {
                    item.estado = "Facturada";
                    console.log('ITEM', item)
                    const { productFact, ...updatedItem } = item;

                    await dataProvider.update('facturas', {
                        id: updatedItem.id,
                        data: {  ...updatedItem },
                        previousData: updatedItem,
                    });
                    refresh();
                }
                else{
                    console.log('No se debe actualizar el estado', item.alegraNumeracion)
                }
            } catch (error) {
                console.error('ERROR', error);
                notify('Error: no se pudo actualizar el estado', { type: 'error' });
            }
        }
    }



    return(
    <TopToolbar>
        <Button startIcon={<SyncIcon/>}onClick={handleUpdate} size='small'>
            Actualizar Estado
        </Button>
        <CreateButton/>
    </TopToolbar>
);

}





export default FacturaList;