// Objetivo: Extraer la OP y la versión de cada Exposición
const organizeAndSortLen = (lenArray) => {
  const organizedLen = lenArray.reduce((acc, curr) => {
    const { op, version } = extractOpAndVersion(curr.name);
    const key = `${op}-${version}`;
    
    if (!acc[key]) {
      acc[key] = {
        data: [],
        op,
        version,
        totalPlanchasExp: 0,
      };
    }
    
    const formattedCurr = {
      ...curr,
      version: parseInt(version, 10),
    };
    
    acc[key].data.push(formattedCurr);
    acc[key].totalPlanchasExp = acc[key].data.length;
    
    return acc;
  }, {});
  
  return organizedLen;
};

const extractOpAndVersion = (name) => {
  const regex = /(\d{1,6})-(\d{1,3})_/g;
  const match = regex.exec(name);
  
  if (match) {  
    return {
      op: match[1],     
      version: match[2],
    };
  }
  
  return {
    op: '',
    version: '',
  };
};

//que pasa si los lens no tiene, op, ni versiòn, no se cumpla el regex, debe setearse la op y la version

// Objetivo: Comparar los datos de las ordenes de salida con los datos de las exposiciones LEN
const checkLen = async (organizedLen, calibreExp) => {
  try {
    let lenDataArray = [];
    const calibre = calibreExp;
    for (const op of Object.keys(organizedLen, calibre)) {
      const lenData = organizedLen[op];
      const Op = parseInt(lenData.op);
      const version = parseInt(lenData.version);
      const totalPlanchasExp = lenData.totalPlanchasExp;
      const calibreExp = parseInt(calibre);
      lenDataArray.push({ Op, version, totalPlanchasExp, calibreExp });
    }
    //console.log('Datos LEN:', lenDataArray);
    return lenDataArray;
  } catch (error) {
    console.error('Error en checkLen:', error);
    return []; // Devuelve un array vacío en caso de error
  }
};

const checkOp = async (dataOrdenSalidas) => {
  try {
    let dataArray = [];
    for (let i = 0; i < dataOrdenSalidas.length; i++) {
      const op = dataOrdenSalidas[i].op;
      const version = dataOrdenSalidas[i].version;
      const totalPlanchasExp = dataOrdenSalidas[i].totalPlanchasExp;
      const totalPlanchasOrden = dataOrdenSalidas[i].totalPlanchasOrden;
      const calibreOrden = dataOrdenSalidas[i].calibreOrden;
      dataArray.push({ op, version, totalPlanchasExp, totalPlanchasOrden, calibreOrden });
    }
    //console.log('Datos Orden Salidas:', dataArray)
    return dataArray;
  } catch (error) {
    console.error('Error en checkOp', error);
  }
  return [];
};

const compararArrays = (dataArray, lenDataArray) => {

  console.log("DATOS ORDEN SALIDAS--->", dataArray);
  console.log("DATOS LEN EXPOSICIONES--->", lenDataArray);

  
  let ordenWarning = []
  lenDataArray.forEach(lenDataItem => {
    const dataItem = dataArray.find(item => item.op === lenDataItem.Op);
    
    if (!dataItem) {
      console.log(`No se encontró una OP correspondiente para ${lenDataItem.Op} en Orden Salida.`);
      ordenWarning.push({op: lenDataItem.Op, warning: false})
      //sin advertencia
      return;
    }
    const calibreOrden = dataItem.calibreOrden
    const calibreExp = lenDataItem.calibreExp
    
    if(calibreExp !== calibreOrden){
      console.warn(`La OP ${lenDataItem.Op} tiene un calibre ${calibreExp} diferente al de Orden Salida ${calibreOrden}.`);
      ordenWarning.push({op: lenDataItem.Op, warning: true})
      return;
    }
    
    const totalPlanchasExp = dataItem.totalPlanchasExp;
    const totalPlanchasOrden = dataItem.totalPlanchasOrden;
    const qtyLenExposicion = lenDataItem.totalPlanchasExp

    if (totalPlanchasExp >= totalPlanchasOrden) {
      console.warn(`La op ${lenDataItem.Op} ya superó su total planchas.`);
      ordenWarning.push({op: lenDataItem.Op, warning: true})
      //Generar advertencia
      return;
    }

    const planchasFaltantes = totalPlanchasOrden - totalPlanchasExp;

    if (qtyLenExposicion > planchasFaltantes) {
      // Advertencia si qtyLenExposicion es mayor que planchasFaltantes
      //console.warn(`La OP ${lenDataItem.Op} está intentando agregar más planchas de lo permitido. Se intenta agregar ${qtyLenExposicion}, pero se puede exponer ${planchasFaltantes} plancha(s).`);
      console.warn(`Se esta intentando agregar ${qtyLenExposicion} planchas, para la OP ${lenDataItem.Op} y solo se permite ${planchasFaltantes} plancha(s).`);
      ordenWarning.push({ op: lenDataItem.Op, warning: true });
    } else {
      // Se permite agregar planchas porque qtyLenExposicion es menor o igual a planchasFaltantes
      console.log(`La OP ${lenDataItem.Op} ya agrego ${qtyLenExposicion} planchas.`);
      ordenWarning.push({ op: lenDataItem.Op, warning: false });
    }
  });

  return ordenWarning;
};

export { organizeAndSortLen, extractOpAndVersion, checkLen, checkOp, compararArrays };