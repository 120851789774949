import {  getUrl } from "@aws-amplify/storage";
import React from "react";


export function useStorageThumbnailField({
  source,
  record = {},
  storageOptions = {},
}) {
  const [fieldProps, setFieldProps] = React.useState(null);

  React.useEffect(() => {
    async function getUrl(key) {
      const  thumbKey = key.replace(/\.[^/.]+$/, '.jpg');
      return await getUrl(`thumb/${thumbKey}`, storageOptions);
    }

    // Case when using the field within an AmplifyInput
    if (!source) {
      if (!record.key) {
        return;
      }

      getUrl(record.key)
        .then((url) => {
          if (typeof url !== "string") {
            return;
          }

          const updatedRecord = { ...record };
          updatedRecord._url = url;
          //console.log ("UpdatedRecord", updatedRecord);
          setFieldProps({
            source: "_url",
            record: updatedRecord,
            title: record.key.split(/_(.+)/)[1],
          });
          console.log("source", "_url");
          console.log("record", updatedRecord);
          console.log("title", record.key.split(/_(.+)/)[1]);
        })
        .catch(() => {});

      return;
    }

    if (!record[source]) {
      return;
    }

    // Case when using the field with an array of files
    if (Array.isArray(record[source])) {
      Promise.all(
        record[source].map(async (value) => {
          if (!value.key) {
            return;
          }

          const url = await getUrl(value.key);

          if (typeof url !== "string") {
            return;
          }

          return {
            ...value,
            _url: url,
            _title: value.key.split(/_(.+)/)[1],
          };
        })
      )
        .then((values) => {
          const updatedRecord = { ...record };
          updatedRecord[source] = values.filter((v) => !!v);

          setFieldProps({
            source,
            record: updatedRecord,
            title: "_title",
            src: "_url",
          });
        })
        .catch(() => {});

      return;
    }

    if (!record[source].key) {
      return;
    }

    // Default case when rendering a single file
    getUrl(record[source].key)
      .then((url) => {
        if (typeof url !== "string") {
          return;
        }

        const updatedRecord = { ...record };
        updatedRecord[source]._url = url;

        setFieldProps({
          source: `${source}._url`,
          record: updatedRecord,
          title: record[source].key.split(/_(.+)/)[1],
        });

      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.key]);

  return fieldProps;
}
