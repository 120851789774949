import { signIn, signOut, fetchAuthSession, fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { getPermissionsFromRoles } from '@react-admin/ra-rbac';





export class AuthProvider {

   constructor(options) {
    this.authGroups = options?.authGroups || []
  };

  login = async () => {
    return Promise.resolve();
  };

   logout = ()=> {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('user');

    return signOut();
  };

   checkAuth = async () => {
    const session = await fetchAuthSession();
    //console.log("SESSION",session)  
    if (this.authGroups.length === 0) {
      return;
    }
    if (session) {
      const userGroups = session.tokens.accessToken.payload["cognito:groups"];
      const checkPerms= localStorage.getItem("permissions")

      if (!checkPerms) {
        const permissions = getPermissionsFromRoles({
        roleDefinitions: rolesPredet,
        userPermissions: [], // Assuming you might have a way to get these
        userRoles: userGroups,
      });
      localStorage.setItem("permissions", JSON.stringify(permissions));
      localStorage.setItem("token", session.tokens.accessToken.jwtToken);
      localStorage.setItem("user", session.tokens.accessToken.payload["sub"]);

        }
        if (!userGroups) {
          throw new Error("Unauthorized");
        }
    
        for (const group of userGroups) {
          if (this.authGroups.includes(group)) {
            return Promise.resolve();
          }
        }
        throw new Error("Unauthorized");
      };
      

    if (!session) {
      throw {redirectTo: '/login'};
    }
  };


   checkError = (e) => {
    return Promise.resolve();
  };

  getPermissions = async () => {
   // const session = await Auth.currentSession();
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    //return Promise.resolve(permissions);
    //console.log(session)
    //const userRoles = session.getAccessToken.payload['cognito:groups']
    //console.log(permissions)
   // const groups = session.getAccessToken().decodePayload()["cognito:groups"];
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  };
}

const rolesPredet = {
  root: [
          { action: '*' , resource: '*' },
        ],
  admin: [
          { action: '*', resource: 'clientes' },
          { action: '*', resource: 'usuarios' },
          { action: '*', resource: 'AdminMenu' },
          { action: '*', resource: 'inventarioMenu' },
          { action: '*', resource: 'materials' },
          { action: '*', resource: 'stocks' },
          { action: '*', resource: 'Horarios' },
          { action: '*', resource: 'marcacions' },
          { action: '*', resource: 'turnos' },
          { action: '*', resource: 'facturacionMenu' },
          { action: '*', resource: 'sqlSalidas' },
          { action: '*', resource: 'remissions' },
          { action: '*', resource: 'cognitoUsers' },
          { action: '*', resource: 'cotizacions' },
          { action: '*', resource: 'productFacts' },
        ],
  inventarios: [
      { action: '*', resource: 'inventarioMenu' },
      { action: '*', resource: 'materials' },
      { action: '*', resource: 'stocks' },
      { action: ['show', 'list'], resource: 'exposicions' },
      { action: '*', resource: 'produccion' },
  ],
  produccion: [
      { action: '*', resource: 'produccion' },
      { action: '*', resource: 'exposicions' },
      { action: '*', resource: 'ordenSalidas' },
      { action: ['read', 'edit'], resource: 'stocks' },
      { action: '*', resource: 'materials' },
  ],
  facturacion: [
    { action: '*', resource: 'sqlSalidas' },
    { action: '*', resource: 'remissions' },
    { action: '*', resource: 'cotizacions' },
    { action: '*', resource: 'inventarioMenu' },
    { action: '*', resource: 'materials' },
    { action: '*', resource: 'stocks' },
    { action: ['read', 'list'], resource: 'exposicions' },
    { action: '*', resource: 'produccion' },
],
  guest:[
           { type: 'deny' , action: '*', resource: '*' },
    ]
}