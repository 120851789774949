import React from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  SimpleForm,
  ReferenceArrayInput,
  BooleanInput,
  List,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  useShowController,
  useEditController,
  Edit,
  EditButton
} from "react-admin";
import { AmplifyFilter } from "./AmplifyFilter";

export const CognitoUserFilter = (props) => (
  <AmplifyFilter {...props}>
    <TextInput source="getUser.username" label="Username" alwaysOn resettable />
    <TextInput
      source="listUsersInGroup.groupname"
      label="Group"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);

export const CognitoUserList = () => (
  <List
    title="Usuarios"
    //filters={<CognitoUserFilter />}
  >
    <Datagrid >
      <TextField source="name" label="Nombre" sortable={false} />
      <TextField source="family_name" label='Apellidos' sortable={false} />
      <TextField source="email" label='Email' sortable={false} />
      <DateField source="UserLastModifiedDate" label='Fecha Modificación' sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
);

export const CognitoUserShowTitle = ({ record }) => (
  <span>Cognito User{record ? ` #${record.name}` : ""}</span>
);

export const CognitoUserShow = (props) => {
  const { record } = useShowController(props);

  if (!record) {
    return null;
  }

  const {
    id,
    Enabled,
    UserStatus,
    UserLastModifiedDate,
    UserCreateDate,
    sub,
    ...attributes
  } = record;
//  console.log(attributes)
  return (
    <Show title={<CognitoUserShowTitle />} {...props}>
      <SimpleShowLayout>
        <ReferenceManyField
          reference="cognitoGroups"
          target="listGroupsForUser.username"
          label="Groups"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="id" />
          </SingleFieldList>
        </ReferenceManyField>
        <BooleanField source="Enabled" label='activo'/>
        <TextField source="UserStatus" />
        {Object.keys(attributes).map((attribute) => (
          <TextField key={attribute} record={record} source={attribute} />
        ))}
        <DateField source="UserLastModifiedDate" />
        <DateField source="UserCreateDate" />
      </SimpleShowLayout>
    </Show>
  );
};


const editTransform = ( data ) => {
  //console.log(data)
  const strCedula = 'custom:cedula'
  const formatted =  [
        {
            "Name": "sub",
            "Value": data.sub
        },
        {
            "Name": "custom:cedula",
            "Value": data[strCedula]
        },
        {
            "Name": "birthdate",
            "Value": data.birthdate
        },
        {
            "Name": "email_verified",
            "Value": data.email_verified
        },
        {
            "Name": "name",
            "Value": data.name
        },
        {
            "Name": "phone_number_verified",
            "Value": data.phone_number_verified
        },
        {
            "Name": "phone_number",
            "Value": data.phone_number
        },
        {
            "Name": "family_name",
            "Value": data.family_name
        },
        {
            "Name": "email",
            "Value": data.email
        }
      ]
  
  return(formatted)
}

export const CognitoUserEdit = (props) => {
  const { record } = useEditController(props);

  if (!record) {
    return null;
  }

  const {
    id,
    Enabled,
    UserStatus,
    UserLastModifiedDate,
    UserCreateDate,
    sub,
    ...attributes
  } = record;
  //console.log(attributes)
  return (
      <Edit >
        <SimpleForm>
            <DateInput source="UserCreateDate" />
            <DateInput source="UserLastModifiedDate" />
            <TextInput source="Enabled" />
            <TextInput source="UserStatus" />
            <TextInput source="sub" />
            <TextInput source="custom:cedula" />
            <DateInput source="birthdate" />
            <TextInput source="email_verified" />
            <TextInput source="name" />
            <TextInput source="phone_number_verified" />
            <TextInput source="phone_number" />
            <TextInput source="family_name" />
            <TextInput source="email" />
        </SimpleForm>
    </Edit>
  );
};