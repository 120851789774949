import React from 'react'
import { Edit, NumberInput, PasswordInput, SimpleForm, TextInput, TopToolbar, ShowButton, } from 'react-admin'
import Grid from '@mui/material/Grid'

const editTransform = (data) => {
    
    console.log(data)
    
    return(
        {
            id: data.id,
            name: data.name,
            pin: data.pin,
        }
    )
}

const PostEditionsActions = () => (
    <TopToolbar>
        <ShowButton/>
    </TopToolbar>
)

export const UserExposicionEdit = () => {

    return(
        <Edit actions={<PostEditionsActions/>} transform={editTransform}>
            <SimpleForm>
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between'>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextInput fullWidth source='id' label='ID' disabled/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextInput fullWidth source='name' label='USUARIO PRODUCCION'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <PasswordInput fullWidth source='pin' label='PIN' disabled/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};