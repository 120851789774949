
import React, { useState } from 'react';
import { BooleanInput, Create, SaveButton, SimpleForm } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import AreaCustom from './components/AreaInputCustom';
import StockCreateLayout from './components/StockCreateLayout';

export const StockCreate = () => {
    const handleSubmit = (values) => {
      console.log(values);
    }
    return (
      <Create redirect='list' toolbar={<Toolbar />} transform={(d)=> {delete d.manualConsec; console.log("DATA",d); return d}} >
        <SimpleForm >
          <StockCreateLayout />
        </SimpleForm>
      </Create>
    );
  }
  
  const Toolbar = props => {
    
    return(
    <Toolbar >
      <SaveButton />
    </Toolbar>
  );
}
  
