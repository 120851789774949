import { 
  Create,
  TextInput, 
  SimpleForm,
  ArrayInput,
  SelectInput,
  NumberInput,
  DateTimeInput,
  SimpleFormIterator,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { AmplifyFileInput } from '../Amplify';
  
export const ExposicionCreate = () => {

    return(
        <Create redirect="list">
            <SimpleForm p={2} >
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between' style={{marginTop: '20px'}}>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" source="expoName" label='Nombre Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" source="plateName" label='Nombre Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextInput fullWidth color="primary" source="plateType" label='Tipo Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <DateTimeInput fullWidth color="primary" source="dateExposed" label='Fecha Exposicion'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <SelectInput fullWidth source='ciudad' choices={[
                            { name: 'Envigado', id: 'env' },
                            { name: 'Barranquilla', id: 'baq' },
                            { name: 'Cali', id: 'cali' }]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ArrayInput source="len" label="LEN"> 
                            <SimpleFormIterator inline linkType={false}>
                                <TextInput source="name" label="Nombre len"/>
                                <NumberInput source="lenWidth" label="Ancho len"/>
                                <NumberInput source="lenHeight" label="Altura len"/>
                                <NumberInput source="area" label="Área"/>
                                <AmplifyFileInput source="image" label="Imagen Len"/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={12}>
                        <AmplifyFileInput fullWidth color="primary" source="imageExp" label='Exposición Imagen'/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};