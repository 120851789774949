import React from "react";
import { ImageField, useRecordContext } from "react-admin";
import { useStorageThumbnailField } from "../hooks/useStorageThumbnailField";

/*
type Props = {
  source?: string;
  record?: any;
  storageOptions?: any;
} & ImageFieldProps;
*/

export const AmplifyThumbnailField = ({
  source,
  storageOptions = {},
  ...props
}) => {
  const record = useRecordContext(props);
  const fieldProps = useStorageThumbnailField({ source, record, storageOptions });

  if (!fieldProps) {
    return null;
  }

  return <ImageField {...fieldProps} {...props} />;
};