import { 
  useGetOne,
  TextField,
  useNotify, 
  useRefresh,
  useGetList, 
  NumberField,
  SelectInput,
  NumberInput, 
  PasswordInput, 
  ReferenceInput, 
  useDataProvider, 
  useRecordContext,
  AutocompleteInput,
  TextInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';



const UserPinInput = ({ onPinSuccess }) => {

  const notify = useNotify();
  const [user, setUser] = useState({})
  const { setValue } = useFormContext();
  const dataProvider = useDataProvider();
  const [pinError, setPinError] = useState(false);
  const [changedPin, setChangedPin] = useState(false);
  const [responsablePin, setResponsablePin] = useState('');
  
  useEffect(() => {
    if (changedPin){
      dataProvider.getOne('userExposicionByPins', 
      { pin: responsablePin}, { enabled:changedPin })
      .then(({ data }) => {
        setUser(data);
        console.log(data)
        onPinSuccess(true);
        //setLoading(false);
      })
      .catch(error => {
        console.log(error)
        onPinSuccess(false);
        notify('Pin del responsable es incorrecto', { type: 'warning' });
      })
    }
  }, [responsablePin]);
  
  useEffect(() => {
    if (user && user && user.id && user.nombres && user.apellidos) {
      const nameResponsable = `${user.nombres} ${user.apellidos}`;
      setValue('responsableId', user?.id || '');
      setValue('responsableName', nameResponsable || '');
      setChangedPin(false)
      onPinSuccess(true);
      setPinError(false);
    }
    setChangedPin(false)
  }, [user]);
  
  const handlePinChange = (e) => {
    const newPinValue = e.target.value.trim();
    setResponsablePin(newPinValue);
    setChangedPin(true)
    
    if (newPinValue !== '') {
      setValue('responsableId', ''); 
    }
  };
  
  return (
    <>
      <PasswordInput
        error={true}
        fullWidth
        label="PIN"
        source="responsablePin"
        onBlur={handlePinChange}
      />
    </>
  );
};

const CreateExposicionLayout = ({ onPinSuccess }) => {


  return (
    <>
      <Grid item xs={14} md={12}>
        <UserPinInput onPinSuccess={onPinSuccess}/>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput fullWidth source="taras" label="Tara"/>
      </Grid>
      <Grid item xs={14} md={12}>
        <ReferenceInput label="Caja" source="cajaId" reference="cajas">
          <AutocompleteInput
            label="Caja"
            fullWidth
            optionText='consecutivo'
            onChange={(value)=>(console.log("CajaChanged--->", value))}
          />
        </ReferenceInput>
      </Grid>
    </>
  );
};

export default CreateExposicionLayout;
