import { 
    Create,
    Labeled,
    required,
    TextInput,
    SimpleForm, 
    ArrayInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    SimpleFormIterator,
    AutocompleteArrayInput,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { Calibre } from '../../utils';
import { Autocomplete, responsiveFontSizes } from '@mui/material';



export const OrdenSalidaCreate = () => {


    return(
        <Create redirect="list" style={{responsiveFontSizes}}>
            <SimpleForm p={2}>
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between' >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth multiline maxRows={1} source="op" label='Orden Producción'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth multiline maxRows={1} source="version" label='Versión'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth multiline maxRows={6} source="totalPlanchasOrden" label='Total Planchas Orden'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <NumberInput fullWidth multiline maxRows={6} source="totalPlanchasExp" label='Total Planchas Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AutocompleteInput fullWidth variant='outlined' source='calibreOrden' label='Calibre(s) Orden' choices={Calibre}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AutocompleteArrayInput fullWidth variant='outlined' source='calibresExp' label='Calibre(s) Exposición' choices={Calibre}/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
