import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Stack, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { TextField, Datagrid, useGetList, NumberField, BooleanField } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { formatCurrencyString } from '../../../utils';



const sort = { field: 'id', order: 'DESC' };

export default function RemisionesShow() {
    const { getValues, setValue } = useFormContext();
    const productosRemisionados = getValues('productFact');
    const STATE = getValues();
    
    //console.log('STATE ON OPEN', STATE);
    

    const remisionGroup = productosRemisionados && productosRemisionados.items?.length > 0 
        ? productosRemisionados.items.reduce((r, a) => {
            r[a.remisionNro] = [...r[a.remisionNro] || [], a];
            return r;
        }, {}) 
        : {};
    /// set a new component telling remision number, items, area and total from all remisiones selected

    return (
        <Box sx={{ mt: '10px' }}>
            {Object.keys(remisionGroup).length > 0 ? Object.keys(remisionGroup).map((remision, index) => {
                const data = remisionGroup[remision] || []; // Ensure data is an array
                const total = formatCurrencyString(data[0].remision.total,1);
                const area = data[0].remision.totalArea.toFixed(0);
                const totalItems = data.length;
                return (
                    <Accordion key={remision}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id={remision}
                            sx={{ backgroundColor: '#e8e6e6', height: '10px' }}
                        >
                            <Stack width='100%' direction='row' spacing={3} justifyContent={'space-around'} divider={<Divider direction="vertical"/>}>
                                <Typography sx={{fontWeight:'bold'}} component={'div'} >{remision}</Typography>
                                <Typography component={'div'}>{`Items: ${totalItems}`}</Typography>
                                <Typography component={'div'}>{`Area: ${area}`}</Typography>
                                <Typography component={'div'}>{`Total: ${total}`}</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            {data&&data.length>0 ? 
                                <Datagrid
                                    data={data}
                                    total={totalItems}
                                    sort={sort}
                                    bulkActionButtons={false}
                                >
                                <TextField source='ordenVersion' label='Orden'  />
                                <TextField source="descripcion" />
                                <TextField source="caja"   />
                                <NumberField source="ancho"  />
                                <NumberField source="largo"  />
                                <NumberField source="cantidad"/>
                                <NumberField source="area"  />
                                <NumberField source="valorUnitario" label='V. Unitario'  />
                                <BooleanField source="iva" />
                                <NumberField source="valorTotal" label='V. Total' />
                            </Datagrid>:<span>Loading...</span>
                            }
                        </AccordionDetails>
                    </Accordion>
                );
            }) : <span />}
        </Box>
    );
}