import React from 'react';
import { Box, Typography } from '@mui/material';


const ChatBubble = ({ observation }) => {
    const bubbleStyle = {
      padding: '0.5em',
      borderRadius: '10px',
      marginBottom: '1em',
      backgroundColor: observation.PRIORIDAD === 1 ? 'lightgreen' : 'lightblue',
      maxWidth: '100%',
      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
    };
    const textStyle = {
        fontWeight: observation.PRIORIDAD === 1 ? 'bold' : 'normal',
        textAlign : 'center',
        };
    const userInfoStyle = {
            position: 'relative',
            top: '0.05em',
            left: '0.05em',
            fontSize: '0.8em',
            color: 'rgba(0, 0, 0, 0.4)',
          };
  
    return (
      <Box sx={bubbleStyle}>
        <Typography sx={userInfoStyle}>{observation.USUARIO}</Typography>
        <Typography variant="body1" sx={textStyle}>{observation.OBSERV}</Typography>
      </Box>
    );
  };

  export default ChatBubble;