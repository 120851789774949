import { SqlSalidasList } from "./salidas/SalidasList";
import  RemisionCreate  from "./remision/RemisionCreate";
import RemisionList from "./remision/RemisionList";
import RemisionEdit from "./remision/RemisionEdit";
import CotizacionCreate from "./cotizacion/CotizacionCreate";
import CotizacionList from "./cotizacion/CotizacionList";
import CotizacionEdit from "./cotizacion/CotizacionEdit";
import ReportesList from "./reportes/ReportesList";
import FacturaList from "./facturacion/FacturaList";
import FacturaCreate from "./facturacion/FacturaCreate";
import FacturaEdit from "./facturacion/FacturaEdit";
import AlegraInvoiceList from "./facturacion/AlegraInvoiceList";

export default {
    salidas: {
        list: SqlSalidasList,
    },
    remision: {
        create: RemisionCreate,
        list: RemisionList,
        edit: RemisionEdit,
    },
    cotizacion: {
        create: CotizacionCreate,
        list: CotizacionList,
        edit: CotizacionEdit,
    },
    reportes: {
        list: ReportesList,
    },
    factura: {
        list: FacturaList,
        create: FacturaCreate,
        edit: FacturaEdit,
    }, 
    alegraInvoice: {
        list: AlegraInvoiceList,
        //create: FacturaCreate,
        //edit: FacturaEdit,
    }
}