//var due = [dueURGENCIA, dueEMERGENCIA, duePrioridad, duePRIMERA_HORA]
import { ComponentPropsToStylePropsMap } from '@aws-amplify/ui-react';
import { getHolidaysByYear } from './festivos'
const jobTime = 
{
	"ARTE":{
		"type": "ARTE",
		"sufix" : "A",
		"leadTime" : {
					"NORMAL" : 48,
					"URGENCIA" : 12,
					"PRIMERA_HORA" : "ND",
					"EMERGENCIA": 3
				}
			},
	"FINALIZACION":{
		"type": "FINALIZACION",
		"sufix" : "F",
		"leadTime" : {
			"NORMAL" : 36,
			"URGENCIA" : 8,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 4
		}
	},
	"OPENPRINT":{
		"type": "OPENPRINT",
		"sufix" : "O",
		"leadTime" : {
			"NORMAL" : 24,
			"URGENCIA" : 6,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 4
		}
	},
	"COPIADO":{
		"type": "COPIADO",
		"sufix" : "C",
		"leadTime" : {
			"NORMAL" : "ND",
			"URGENCIA" : 6,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 4
		}
	},
	"MONTAJE":{
		"type": "MONTAJE",
		"sufix" : "MP",
		"leadTime" : {
			"NORMAL" : 24,
			"URGENCIA" : 8,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 4
		} 			
	},
	"PRUEBA_CONTRATO":{
		"type": "PRUEBA_CONTRATO",
		"sufix" : "S",
		"leadTime" : {
			"NORMAL" : 24,
			"URGENCIA" : 6,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 3
		} 			
	},
	"REPETICION":{
		"type": "REPETICION",
		"sufix" : "R",
		"leadTime" : {
			"NORMAL" : "ND",
			"URGENCIA" : 4,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 3
		} 			
	},
	"REPOSICION":{
		"type": "REPOSICION",
		"sufix" : "D",
		"leadTime" : {
			"NORMAL" : "ND",
			"URGENCIA" : 6,
			"PRIMERA_HORA" : "ND",
			"EMERGENCIA": 3
		} 			
	},

};

/*
var festivos2021 = [
 "01/01/2021",
 "11/01/2021",
 "22/03/2021",
 "01/04/2021",
 "02/04/2021",
 "01/05/2021",
 "17/05/2021",
 "07/06/2021",
 "14/06/2021",
 "05/07/2021",
 "20/07/2021",
 "07/08/2021",
 "16/08/2021",
 "18/10/2021",
 "01/11/2021",
 "15/11/2021",
 "08/12/2021",
 "25/12/2021"
]
*/

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

Date.prototype.addDays = function(d) {
  this.setTime(this.getTime() + (d*24*60*60*1000));
  return this;
}

function SkipNextDaySundayOrHolyday(date, festivos){
/*/// TESTING
	var dateStr = '25-12-2021'.split('/');
	var date = new Date(dateStr[2], dateStr[1]-1, dateStr[0])
///*/
	//console.log(festivos)
	var skip = 0
	if(date.getDay() === 0){
		date.addDays(1)
		skip = skip+1
	}
	for(var i = 0; i < festivos.length; i++){
		let festivoStr = festivos[i].date.split('/');
		let festivo = new Date(festivoStr[2], festivoStr[1]-1, festivoStr[0])
		if(date.getDate() === festivo.getDate() && date.getMonth() === festivo.getMonth()){
		   date.addDays(1)
		   skip = skip+1
		}
		if(date.getDay() === 0){
			date.addDays(1)
			skip = skip+1
		}
	}


	return skip
}

///Setting enew DAte


function getTimeOffset(offset, festivos, now){

	let dueDate = new Date()
	now = new Date()
	if(offset === "ND"){
		if(now.getHours()>17){
			dueDate = now.addDays(2+SkipNextDaySundayOrHolyday(dueDate, festivos))
			dueDate.setHours(8,0,0,0)
		}else{
			dueDate = now.addDays(1+SkipNextDaySundayOrHolyday(dueDate, festivos))
			dueDate.setHours(8,0,0,0)
		}
	}
	else{
		dueDate = now.addHours(offset)
		dueDate = dueDate.addDays(SkipNextDaySundayOrHolyday(dueDate, festivos))
	}
	///*
	if(dueDate.getHours() > 17){
		dueDate.addDays(1).setHours(8,0,0,0)
	}
	if(dueDate.getHours() < 8){
		dueDate.setHours(8,0,0,0)
	}
	if(dueDate.getDay() === 6 && dueDate.getHours() > 13){
		dueDate.addDays(1+SkipNextDaySundayOrHolyday(dueDate, festivos))
		dueDate.setHours(8,0,0,0)
	}
	//*/
	return dueDate
}

function findJobType(iter){
	if (iter === 0) return "A";
	if (iter === 1) return "F";
	if (iter === 2) return "O";
	if (iter === 3) return "C";
	if (iter === 4) return "S";	
}

/*function findPriority(due){
var  URGENCIA = due[0];
var  Prioridad = due[1];
	var PRIMERA_HORA = due[2]
	if (URGENCIA + Prioridad + PRIMERA_HORA === 0) return "NORMAL";
	if (URGENCIA === 1 && Prioridad === 0 && PRIMERA_HORA === 0) return "URGENCIA";
	if (URGENCIA === 0 && Prioridad === 0 && PRIMERA_HORA === 1) return "PRIMERA_HORA";
	else if (URGENCIA + Prioridad + PRIMERA_HORA  > 1) return "EMERGENCIA";
}*/



export function getDueTime ( jobTypes, priority, date = new Date())
  {
    let dueDateStr = ''
    let oldDueDate = 0

	if( !jobTypes || jobTypes.length===0  ){return dueDateStr}
  	for(var i = 0; i < jobTypes.length; i++){
        if (jobTypes[i]){
			let dueDateTime = new Date()
            const type = jobTypes[i]

  			const currentOffset = jobTime[type].leadTime[priority]
   			dueDateTime = getTimeOffset(currentOffset, getHolidaysByYear(new Date().getFullYear()), date)

			
			//console.log(dueDateTime>= oldDueDate)
            if(dueDateTime.getTime() >= oldDueDate){



				dueDateStr =  dueDateTime.toISOString()
                oldDueDate = dueDateTime.getTime()

            }
  		}

  		
  	}
	  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

	  


	//console.log(dueDateStr)
  	return dueDateStr
	//return dueDateTime
	}

//f ( due, jobTime, sufix, crea);