import { post } from "aws-amplify/api";
import { Auth } from "aws-amplify/auth";
import { Pagination } from "./Pagination";
import sql from 'mysql-bricks'
import { Filter } from "./Filter";
const sortOperators = ["eq", "le", "lt", "ge", "gt", "beginsWith"];
const filterOperators = ["eq", "le", "lt", "ge", "gt", "beginsWith", "like"];

const resourcesToTables ={
  sqlOrdenes: 'orden',
  sqlplanchasop: 'planchasop'
}


export class SqlProvider {

  static async getList(
    resource,
    params
  ){
    const { filter,  pagination, sort, meta } = params;
 

    //console.log("GETLIST sql params", params)
    //console.log("GETLIST sql resource", resource)
    const { data, total } = await this.post('/mysqlConnect-devgf', resource ,params);
    //console.log("GETLIST RESPONSE", data)
   
    return {
      data,
      total,
    };
  }
  static async update(resource, params){
    //console.log("UPDATE", resource, params)
    const { data, total } = await this.post('/mysqlConnect-devgf', resource ,params);
    //consolelog("UPDATE RESPONSE", data)
        
    return {data:data, total: total};
  }
  
  static async create(resource, params){
    //consolelog("CREATE SQL", resource, params)
    const { data, total } = await this.post('/mysqlConnect-devgf', resource ,params);
    //consolelog("CREATE SQL RESPONSE", data)
        
    return {data:data, total: total};
  }




    static async post(path,resource, params) {
        const init = {
          headers: {
            "Content-Type": "application/json",
          },
          body: {resource: resource, params: params},
        };
        const result = await post({
          apiName:"mysqlConnect", 
          path,
          options: init
          }
        )
        const res = await result.response; 

        if (res.statusCode === 200) {

          const responseBody = await res.body.json();
          //consolelog("RESPONSE", responseBody)

          if (responseBody.data && Array.isArray(responseBody.data)) {
            // Typical case for data listing
            //consolelog("RESPONSE BODY", responseBody)
            responseBody.data.forEach(item => item.id = item.REGISTRO);
            return {
              data: responseBody.data,
              total: responseBody.total || responseBody.data.length,
            };
          } else if (responseBody.data.affectedRows !== undefined) {
            // Response for insert, update, delete operations
            // Crafting a response that mimics a list but contains operational results
            const newData = {...responseBody.data, id: 'mockId'};
            //consolelog("RESPONSE BODY in object", newData)
            return {
              data: {...newData}, // Wrapping the response in an array
              total: responseBody.data.affectedRows, // Since it's a single operation response, total is 1
            };
          } else {
            // Handle unexpected response formats
            return { error: "Unexpected response format", details: responseBody };
          }

        }

    }
  }

