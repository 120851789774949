import { Labeled } from 'react-admin';
import React, { useState } from 'react';
import { AmplifyImageField } from '../../Amplify';
import { Grid, Box, Backdrop, Modal, Fade} from '@mui/material';

// const useStyles = makeStyles(theme => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalImage: {
//     width: '100%',
//     display: 'block',
//     margin: 'auto',
//     maxHeight: '80vh',
//     overflowY: 'auto',
//   },
// }));

const ImageView = () =>{
    
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Labeled fullWidth>
            <AmplifyImageField
              style={{ zIndex: 1 }}
              fullWidth
              source="imageExp"
              label={false}
              onClick={(record) => handleOpen(record['imageExp'])}
            />
          </Labeled>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose} closeAfterTransitionBackdropComponent={Backdrop}>
        <Fade in={open}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '100vh' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }} 
              sx={{'img': { minHeight: 640, minWidth: '100%' }}}>
              <AmplifyImageField
                source="imageExp"
                label="IMAGEN EXPOSICIÓN"
                style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              />
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default ImageView;