import { 
    Show,
    NumberField,
    TopToolbar, 
    TextField,
    required,
    SimpleShowLayout,
    ShowButton,
    Labeled,
    ListButton,
    CreateButton,
} from 'react-admin';
import { responsiveFontSizes } from '@mui/material';
import Grid from '@mui/material/Grid';



export const CajaShow = () => {

    const TagListActions = () => (
        <TopToolbar>
          <ListButton/>
          <CreateButton/>
        </TopToolbar>
      )

    return(
        <Show redirect="list" actions={<TagListActions/>} style={{responsiveFontSizes}}>
            <SimpleShowLayout p={2} >
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between' >
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="warning" multiline maxRows={1} source="id" label='ID' validate={[required()]}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <NumberField fullWidth color="primary" multiline maxRows={6} source="proveedor" label='PROVEEDOR'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="ancho" label='ANCHO CAJA"'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="largo" label='LARGO CAJA'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="ancho" label='ÁREA TOTAL'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="ancho" label='CANTIDAD LÁMINAS'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="ancho" label='CALIBRE'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="ancho" label='CONSECUTIVO'/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Labeled>
                            <TextField fullWidth color="primary" multiline maxRows={2} source="ancho" label='ÁREA RESTANTE'/>
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    )
};
