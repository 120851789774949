import React from 'react';
import { useState } from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { Grid, Box, Backdrop, Modal, Fade} from '@mui/material';
import { AmplifyImageLenField } from '../../Amplify/components/AmplifyImageLenField';


//     modalImage: {
//       width: '100%',
//       display: 'block', 
//       margin: 'auto', 
//       maxHeight: '80vh', 
//       overflowY: 'auto',
//     },
//   }));

const LenView = ({record}) =>{
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Labeled fullWidth>
            <AmplifyImageLenField fullWidth record={record} source="image" label={false} onClick={handleOpen}/>
          </Labeled>
        </Grid>
        <Modal open={open} onClose={handleClose}>
          <Fade in={open}>
          <Box height={1.01} width={1.01} display="flex" alignItems="center" style={{margin:'-5rem', marginTop:'5rem', padding:'-20rem', marginLeft:'22.5rem'}} sx={{'img': {minWidth: 510, minHeight: 650}}}>
            <AmplifyImageLenField style={{ marginLeft: '22rem', marginTop: '55rem' }} record={record} source="image" label='IMAGEN EXPOSICIÓN'/>
          </Box>
          </Fade>
        </Modal>
      </>
    );
  };

export default LenView