import { Datagrid, DateField, List, NumberField, TextField , TextInput, SearchInput} from 'react-admin';

const OrdenFilters = [
    <TextInput label="Numero Orden" source="NUMERO" alwaysOn resettable />,
    <TextInput label="Version" source="VERSIONOP" alwaysOn resettable />,
    <TextInput label="Nombre del Trabajo" source="TRABAJO.like" alwaysOn resettable={true}/>,
    <TextInput label="Nombre del Cliente" source="CLIENTE.like" alwaysOn resettable={true}/>,
];

export const SqlOrdenesList = () => (
    <List filters={OrdenFilters}>
        <Datagrid rowClick="edit" sort={{field:"NUMERO", order: "DESC"}} >
            <NumberField source="NUMERO" label='Numero Orden' />
            <NumberField source="VERSIONOP" label="Version"/>
            <TextField source="TRABAJO" label="Nombre Trabajo"/>
            <TextField source="CLIENTE" label="Cliente"/>
            <TextField source="CODCLIENTE" label="NIT"/>
            <DateField source="FECHACREA" label="Fecha"/>
        </Datagrid>
    </List>
);
