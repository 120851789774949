import { Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import CognitoUserInput from '../components/CognitoUserInput';
import CognitoUserGroups from '../components/CognitoUserGroups';
import { TextInput, NumberInput, SelectArrayInput, useRecordContext, PasswordInput } from 'react-admin';
export const GeneralTab = (props) => {
  let cognitoSelected = false
  const setCognitoValuesAndDisableAll = (val) => {
    cognitoSelected = !val
  }
  const roles = useWatch({name: 'roles'});
  const [rolUser, setRolUser] = useState(false);
  useEffect(() => {
    const hasProduccionRole = roles && roles.includes('produccion');
    setRolUser(hasProduccionRole);
  }, [roles])
  return (
    <Grid container direction='row' spacing={2} justifyContent='center'>
        <Grid item md={12} sm={12}>
            <CognitoUserInput autoSetParam={setCognitoValuesAndDisableAll} />
        </Grid>
        <Grid item md={6} xs={12}>
            <TextInput fullWidth source='nombres' variant="outlined" disabled={cognitoSelected} />
        </Grid>
        <Grid item md={6} xs={12}>
            <TextInput source='apellidos' variant="outlined" disabled={cognitoSelected} fullWidth />
        </Grid>
        <Grid item md={3}>
            <TextInput fullWidth source='cedula' variant="outlined" />
        </Grid>
        <Grid item md={3}>
            <SelectArrayInput fullWidth source='sedes' choices={[
            { name: 'Envigado', id: 'env' },
            { name: 'Barranquilla', id: 'baq' },
            { name: 'Cali', id: 'cali' }]}
            />
        </Grid>
        <CognitoUserGroups source='roles'/>
    </Grid>

  );
}
const UserField = (props) => {
  return (
    <>
      <Grid item container direction='row' spacing={2}>
        <Grid item md={4}>
          <TextInput fullWidth source='cedula' variant="outlined"/>
        </Grid>
        <Grid item md={4}>
          <SelectArrayInput fullWidth source='sedes' choices={[
            { name: 'Envigado', id: 'env' },
            { name: 'Barranquilla', id: 'baq' },
            { name: 'Cali', id: 'cali' }
          ]} />
        </Grid>
        <Grid item md={4}>
          <CognitoUserGroups source='roles' {...props} />
        </Grid>
      </Grid>
    </>
  );
}