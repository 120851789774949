import React from 'react'
import {
        SelectInput,
        AutocompleteInput, 
        AutocompleteArrayInput, 
        NumberInput,
        useRecordContext,
        choices
        } 
        from 'react-admin'
import { Box, Grid, Typography } from '@mui/material';
import MountingView from '../components/MountingView'
import { Card, CardHeader } from '@mui/material'
import { Lineatura, Calibres } from '../../utils'
import { width } from '@mui/system';
import ColorPicker from './ColorPicker';





const Rip = (props) => {
    
    const {formData, client} = props
    const findPlanchas=(formData,client)=>{
        return client.maquinas.find(x=>x.nombre === formData.maquina).plancha.map(plancha=>Calibres.find(y=> y.id===plancha))
    }
    return (
    <Card sx={{padding:'10px'}}>
        <CardHeader title='RIP'/>
        <Grid container direction='row' spacing={2}>
            <Grid item>
                <AutocompleteInput source='maquina' choices={client.maquinas} optionText='nombre' optionValue='nombre' variant='outlined' size='small'/> 
            </Grid>

        {
            formData.maquina ?(
                <Grid item md={6} container  spacing={2} direction='row'>
                    <Grid item>
                        <AutocompleteInput 
                            variant='outlined' 
                            source='rip.calibre' 
                            label='Calibre' 
                            choices={ findPlanchas(formData, client)}
                            defaultValue = { findPlanchas(formData, client).length === 1 ? findPlanchas(formData, client)[0].id : '' }
                            size='small'
                            onChange={e=>console.log(e)}
                            />                  
                    </Grid>
                    <Grid item>
                        <AutocompleteInput variant='outlined' source='rip.lpi' label='LPI' choices={ client.maquinas.find(x=>x.nombre === formData.maquina).lpis.map(lpis=>Lineatura.find(y=> y.id===lpis))}
                                size='small'
                        />                  
                    </Grid>
                </Grid>

            ):(null)
        }
            <Grid item lg={12} md={12} sm={12}>
                <ColorPicker {...props} defaultLpi = {formData.rip&&formData.rip.lpi? formData.rip.lpi:''} />
            </Grid>
        </Grid>
    </Card>
    )


}


export default Rip