
import React from "react";

import {Edit , TabbedForm, TextInput, ListButton,
        ShowButton,TopToolbar,
        FormTab,
        useRecordContext } from "react-admin";
import { GeneralTab } from "./tabs/GeneralTab.js";
import CreateFingerPrintFullDataGrid from "./tabs/FingerprintDatagrid.js";

import TurnosTab from "./tabs/TurnosTab.js";

import ChevronLeft from '@mui/icons-material/ChevronLeft';




const CustomerEditActions = () => (
    <TopToolbar>
        <ListButton label="Volver" icon={<ChevronLeft />} />
        <ShowButton />
    </TopToolbar>
);

const editTransform = ({  huellas, ordenesPendientes, ordenesRealizadas, ...data }) => {
    console.log(data)
    return(
    {
    ...data,
    })}

const EditTitle = ()=>{
    const record = useRecordContext()
    return(
        <span>{record? `Editar Usuario: ${record.nombres} ${record.apellidos}`:''}</span>
    )
    
}

const UserEdit = (props) => {
    let cognitoSelected = false
    const setCognitoValuesAndDisableAll = (val)=>{
        cognitoSelected = !val
    }

    
    return(
    <Edit redirect='list' title={<EditTitle/> } transform={editTransform} actions={<CustomerEditActions />}>      
        <TabbedForm  alignContent='center' >
            <TabbedForm.Tab label={'GENERAL'}>
                <GeneralTab/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label='BIOMÉTRICO' >
                <CreateFingerPrintFullDataGrid  />
            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
    )
};

export default UserEdit