import{ 
    List, 
    Datagrid,
    TextField, 
    DateField, 
    NumberField,
    Button,
} from 'react-admin';

export const SalidasList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="salidaName" />
            <TextField source="salidaDate" />
            <NumberField source="_lastChangedAt" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" /> 
        </Datagrid>
    </List>
);
