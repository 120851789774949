import ImageView from './ImageView';
import React, { useState } from 'react';
import { LenContent } from './LenContent';
import Divider from '@mui/material/Divider';
import { generateClient } from "aws-amplify/api";
import IconCancel from '@mui/icons-material/Cancel';
import IconContentAdd from '@mui/icons-material/Add';
import ShowExposicionLayout from './ShowExposicionLayout';
import CreateExposicionLayout from './CreateExposicionLayout';
import { getUserExposicionByPin, listOrdenByOp } from '../../graphql/queries';
import { SolicitarAnulacionDialog } from '../ordenproduccion/components/SolicitarAnulacionDialog';
import { Grid, Dialog, Typography, DialogTitle,  DialogActions, DialogContent, Box } from '@mui/material';
import { organizeAndSortLen, checkLen, checkOp, compararArrays } from '../components/extractOpAndVersion';
import { Form, Button, useNotify, useUpdate, useGetList, SaveButton, useDataProvider, useRecordContext, useRefresh, } from 'react-admin';



export const CreateExposicionDialog = () => {

  const notify = useNotify();
  const refresh = useRefresh();
  const client = generateClient();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = useState(false);
  const [pinSuccess, setPinSuccess] = useState(false);
  const [{ error, loading }] = useUpdate('exposicions');
  const { data: dataOrdenSalidas } = useGetList(
    'OrdenSalidas', { pagination: { page: 1, perPage: 10 } }
  );

  const handleClick = async () => {
    setShowDialog(true);
    refresh();
    const lenDataArray = await checkLen(organizeAndSortLen(record.len));
    const opDataArray = await checkOp(dataOrdenSalidas);
    compararArrays(opDataArray, lenDataArray);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handlePinSuccess = (success) => {
    setPinSuccess(success);
  };

  const updateExposicion = async (values) => {
    try {
      const exposicionUpdateResult = await dataProvider.update('exposicions', {
        id: record.id,
        data: {
          id: record.id,
          responsablePin: values.responsablePin,
          responsableId: values.responsableId !== '' ? values.responsableId : record.responsableId,
          responsableName: values.responsableName !== '' ? values.responsableName : record.responsableName,
          cajaId: values.cajaId !== '' ? values.cajaId : record.cajaId,
          taras: values.taras,
          pendienteSalida: false,
        },
        previousData: record,
      });

      notify('Exposición añadida correctamente', 'info');
      console.log("Antes de actualizar la Exposicion: ", record)
      console.log("Después de actualizar la Exposicion: ", values)
      console.log("exposicionUpdateResult: ", exposicionUpdateResult)
      return exposicionUpdateResult;
    } catch (error) {
      notify('Error al actualizar las Exposiciones:', { type: 'error' });
      throw error;
    }
  };
  const validarExistenciaPin = async (pin) => {
    try {  
      if (!pin || typeof pin !== 'string') {
        console.error('Error: El PIN no es un valor válido.');
        return false;
      }
      //const userResponse = await API.graphql(graphqlOperation(getUserExposicionByPin, {pin: pin}));
      const userResponse = await client.graphql({ 
        query: getUserExposicionByPin, 
        variables: { pin:pin }
      });

      console.log("Responsable Pin--->", userResponse);
      
      if (userResponse && userResponse.data && userResponse.data.getUserExposicionByPin.items.length > 0) {
        return true; 
      } else {
        notify('No se encontró el responsable para el PIN proporcionado', { type: 'error' });
        return false; 
      }   
    } catch (error) {
      console.error('Error al verificar la existencia del PIN:', { type: 'error' });
      return false;
    } 
  };
  const procesarOrden = async () => {
    try {
      console.log("Calibre----->", record.calibre);
      const calibre = record.calibre;
      const lenData = record.len;
      let opArray = [];
      for (let i = 0; i < lenData.length; i++) {
        const nameOp = lenData[i].name;
        const opStr = nameOp?.split('-')[0] || null;
        const versionStr = nameOp?.match(/-(\d+)_/)[1] || null;
        const calibreStr = calibre || null;
        const op = parseInt(opStr);
        const version = parseInt(versionStr);
        const totalPlanchasExp = 1;
        const opIndex = opArray.findIndex((item) => item.op === op && item.version === version);
        if (opIndex === -1) {
          opArray.push({ op, version, calibre: parseInt(calibreStr), totalPlanchasExp });
        } else {
          opArray[opIndex].totalPlanchasExp += 1;
        }
      }
      const ordenSalidaResults = await Promise.all(
        opArray.map(async (op) => {
          return await ordenSalidaExecute(op.op, op.version, op.calibre, op.totalPlanchasExp);
        })
      );
      return ordenSalidaResults;
    } catch (error) {
      console.error('Error al procesar la orden de salida:', error);
      throw error; 
    }
  };

  const ordenSalidaExecute = async (op, version, calibre, totalPlanchasExp) => {
    try {
      const params = {
        filter: { 'NUMERO': op }, 
        pagination: { page: 1, perPage: 10 },
        sort: { "field": "VERSIONOP", "order": "DESC" },
      };
      const resultOrdenes = await dataProvider.getList('sqlOrdenes', params);
      //console.log('Datos obtenidos:', op, ':',resultOrdenes.data);
      //console.log('Total:', resultOrdenes.total);
      const totalPlanchaOrden = resultOrdenes.data[0].TOTALPLAN;
      const calibreOrden = resultOrdenes.data[0].COORDCALIBRE;
      //console.log("totalPlanchaOrden:", op, ':',totalPlanchaOrden)
      //console.log("calibreOrden:", op, ':',calibreOrden)
      const calibreValueMap = {
        1: 45,
        2: 67,
        3: 100,
        4: 112,
        5: 155,
        6: 31,
        7: 38
      };
      let calibreValue;
      if (calibreValueMap.hasOwnProperty(calibreOrden)) {
        calibreValue = calibreValueMap[calibreOrden];
      } else {
        calibreValue = 0;
      };
      //const result = await API.graphql(graphqlOperation(listOrdenByOp, { op: op }));
      const result = await client.graphql({ 
        query: listOrdenByOp, 
        variables: { op:op }
      });

      const existingOrdenSalida = result.data.listOrdenByOp.items[0]; 
      const calibreValueOp = calibre;

      let expIdCreate = [];
      for (let i = 0; i < record.len.length; i++) {
        const expIdCreate = record.id;
      }
      expIdCreate.push(record.id);

      console.log("expIdCreate:", expIdCreate)

      let expIDUpdate = [];
      for (let i = 0; i < record.len.length; i++) {
        const expIDUpdate = expIdCreate[i];
      }
      expIDUpdate.push(record.id);

      console.log("expIDUpdate:", expIDUpdate)
    
      if (existingOrdenSalida) {
        if(existingOrdenSalida.totalPlanchasExp === existingOrdenSalida.totalPlanchasOrden) {
          console.log(`La orden ${op} ya está completa.`);
        }
        if(existingOrdenSalida.totalPlanchasExp + totalPlanchasExp > existingOrdenSalida.totalPlanchasOrden) {
          console.log(`La ${op} supera el total planchas expuestas para la orden.`);
          notify(`La ${op} supera el total planchas expuestas para la orden`, { type: 'warning' });
        }
        if(existingOrdenSalida.calibreOrden !== calibreValueOp) {
          console.log(`La orden ${op} no se puede completar porque el calibre de la orden no coincide con el calibre de la exposición.`);
          notify(`La orden ${op} no se puede completar porque el calibre de la orden no coincide con el calibre de la exposición.`, { type: 'error' });
          throw new Error("El calibre de la orden debe ser igual al calibre de la exposición");
        }
        const updatedOrdenSalida = await dataProvider.update('ordenSalidas', 
        {
          id: existingOrdenSalida.id,
          data: {
            id: existingOrdenSalida.id,
            expID: expIDUpdate,
            totalPlanchasExp: existingOrdenSalida.totalPlanchasExp + totalPlanchasExp,
            version: existingOrdenSalida.version,
            totalPlanchasOrden: totalPlanchaOrden,
            calibreOrden: calibreValue,
            calibresExp: existingOrdenSalida.calibresExp,
            reposicion: false,
          },
        });
        console.log("updatedOrdenSalida", updatedOrdenSalida)
        return updatedOrdenSalida;
      } else {
        const newOrdenSalida = await dataProvider.create('ordenSalidas', {
          data: {
            op: op,
            expID: expIdCreate,
            version: version,
            totalPlanchasExp: totalPlanchasExp,
            totalPlanchasOrden: totalPlanchaOrden,
            calibresExp: calibreValueOp,
            calibreOrden: calibreValue,
            reposicion: false,
          }
        });
        console.log("newOrdenSalida", newOrdenSalida)
        return newOrdenSalida;
      }
    } catch (error) {
      console.error('Error al manejar la orden de salida:', op, ':', error);
      throw error;
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { responsablePin, cajaId, taras } = values;
      if (!responsablePin) {
        notify('Se requiere digitar el PIN del responsable.', { type: 'warning' });
        return;
      }
      if (!cajaId && !taras) {
         notify('Se requieren digitar el número de caja y las taras.', { type: 'warning' });
         return;
      }
      if (!cajaId) {
        notify('Se requiere digitar el número de caja.', { type: 'warning' });
        return;
      }
      if (!taras) {
        notify('Se requiere digitar la cantidad de taras.', { type: 'warning' });
        return;
      }
      const pinExists = await validarExistenciaPin(responsablePin);
      if (!pinExists) {
        notify('El pin del responsable no se ha encontrado.', { type: 'error' });
        return;
      }

      const ordenSalidaResults = await procesarOrden(values);
      if (ordenSalidaResults) {
        const exposicionResult = await updateExposicion(values);
        console.log("Orden Salida Results:", ordenSalidaResults);
        console.log("Exposicion Results:", exposicionResult);

        notify('Medidas añadidas correctamente', /*{ type: 'success' }*/);
        setShowDialog(false);
      } else {
        console.log("Error al procesar la orden de salida.");
        notify('Error al procesar la orden de salida.', { type: 'error' });
        setShowDialog(false);
        throw new Error('Error al procesar la orden de salida.');
      }
    } catch (error) {
      console.error('Error al ejecutar las funciones:', error);
      notify(`No se pudieron añadir las medidas debido a un error, verifique la Exposición.`, { type: 'error' });
      setShowDialog(false);
    }
  };
  
  if (error) {
    return <Typography> 
      Error: {error.message} 
    </Typography>;
  };

  const filterDisabled = record && record.pendienteSalida === 'false';
  const filterAble = record && record.pendienteSalida === 'true';
  const expoName = ( record?.expoName || 'EXPOSICIÓN' ).toUpperCase().replace(/[_-]/g, ' ');

  return (
    filterAble ? (
      <>
        <Button style={{width: 115}} label="Añadir Medidas" variant="contained" onClick={handleClick} disabled={filterDisabled}>
          <IconContentAdd />
        </Button>
        <Dialog fullWidth maxWidth="xl" open={showDialog} onClose={handleCloseClick} aria-label="Crear Medida">
          <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop:'-1rem' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '2rem', fontSize:'15px', paddingTop:'20px', paddingLeft:'1.5rem'}} >
              {`ACTUALIZAR EXPOSICIÓN`}
            </Typography>
            <Typography >
              {<Box sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', marginLeft: 'auto'}}>
                {expoName}
              </Box>}
            </Typography>
            <>
              <SolicitarAnulacionDialog/>
            </>
          </DialogTitle>
          <Form resource="exposicions" id="create-exposicion" onSubmit={handleSubmit}>
            <>
              <DialogContent xs={6} sm={6} md={2} lg={2}>
                <Grid container spacing={{ xs: 2, md: 3 }} flexDirection="row" justifyContent="space-evenly">
                  <Grid item xs={12} sm={5} md={3} lg={2.5} sx={{ '& img': { minHeight: 250 } }}>
                    <ImageView />
                  </Grid>
                  <Divider orientation="vertical" flexItem variant="middle"/>
                  <Grid item xs={4} sm={3.2} md={5} lg={4.5}>
                    <ShowExposicionLayout />
                  </Grid>
                  <Divider orientation="vertical" flexItem variant="middle"/>
                  <Grid item xs={4} sm={2.9} md={3} lg={3}>
                    <CreateExposicionLayout onPinSuccess={handlePinSuccess}/>
                  </Grid>
                </Grid>
                <Grid columns={{ xs: 4, sm: 8, md: 12 }} flexDirection="row" justifyContent="space-evenly">
                  <Grid item xs={6} sm={12} md={12} lg={12}>
                    <LenContent />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 2 }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                  <IconCancel/>
                </Button>
                <SaveButton disabled={!pinSuccess}/>
              </DialogActions>
            </>
          </Form>
        </Dialog>
      </>
    ) : (
      <span />
    )
  );
};