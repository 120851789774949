import { Typography, Grid } from '@mui/material';
import React from 'react'
import Divider from '@mui/material/Divider';
import {
    AutocompleteInput,
    NumberInput,
    AutocompleteArrayInput,
    TextInput,
    required,
    SelectInput,
    FormDataConsumer,
    FormTab,
    BooleanInput, 
    ReferenceInput} from 'react-admin';
import {TiposDocumentos, Sectores, CountryList, CityList, StateList, Sucursales, TiposPersonas} from '../../../utils'
import { useForm, useWatch } from 'react-hook-form';
import { styled } from '@mui/system';


export const MyDatagrid = styled(AutocompleteInput)({
    backgroundColor: "Lavender",
    "& .RaDatagrid-headerCell": {
        backgroundColor: "MistyRose",
    },
});




export const General = (props) => {
    const {type} = props
    return(
            <Grid container direction="column" justifyContent="center" alignItems='center' spacing={3} >
                <Grid item xs={12} container direction='row' spacing={3}>
                    <Grid item sm={12} md={6} >
                        <Grid item container direction='row' justifyContent='space-between'>
                            <Grid item >
                                <SelectInput fullWidth source="datosFacturacion.tipoPersonaAdq" label='Tipo Persona' variant='outlined' defaultValue={'LEGAL_ENTITY'} choices={TiposPersonas} />
                            </Grid>
                            <Grid item > 
                                <SelectInput fullWidth source= 'sucursalEncargada' variant='outlined' label = 'Sucursal' defaultValue={'ENVIGADO'} choices={Sucursales}
                                    /> 
                            </Grid>
                            <Grid item > 
                                <BooleanInput fullWidth source= 'estado' variant='outlined' label = 'Activo'/> 
                            </Grid>
                            <Grid item > 
                                    <TextInput source ='alegraId' sx={{marginTop:'8px'}}/>
                            </Grid>
                        </Grid>

                        <Grid item ><Typography variant ='h4'>Información General</Typography> </Grid>

                        <Grid item xs={12}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.datosFacturacion && formData.datosFacturacion.tipoPersonaAdq === 'LEGAL_ENTITY' ?
                                    (<Grid item xs={12} container  justifyContent='flex-start' alignItems='center' spacing={2}  >
                                        <Grid item xs={12} md={5}> <SelectInput fullWidth source = 'tipoDocumento' defaultValue='NIT' choices={TiposDocumentos} size='small'/> </Grid>
                                        <Grid item xs={10} md= {5}> <TextInput  fullWidth source = 'nit_cedula' label='NIT/Cédula' variant='outlined'/> </Grid>
                                        <Grid item xs={2}> <NumberInput  source = 'digitoVerificacion' label='D. Verificación' variant='outlined'/> </Grid>
                                        <Grid item xs={8}md={8}> <TextInput  fullWidth source = 'razonSocial' variant='outlined'/> </Grid>
                                        <Grid item xs={4}md={4}> <TextInput  fullWidth source = 'codigo' variant='outlined' validate={type==='create'?(required()):(false)} disabled={type==='edit'?(true):(false)}/> </Grid>
                                    </Grid>
                                    ):(  
                                    <Grid item xs={12} container  direction='row' justifyContent='flex-start' spacing={2} rowSpacing={0}>
                                        <Grid item xs={12} md={4}><SelectInput variant='outlined' fullWidth source = 'tipoDocumento' defaultValue='CC' choices={TiposDocumentos}/> </Grid>
                                        <Grid item xs={12} md={6}><TextInput variant='outlined' fullWidth source = 'nit_cedula' label='Cedula'/> </Grid>
                                        <Grid item xs={12} md={5}><TextInput variant='outlined' fullWidth source = 'primerNombre'/> </Grid>
                                        <Grid item xs={12} md={5}><TextInput variant='outlined' fullWidth source = 'segundoNombre'/> </Grid>
                                        <Grid item xs={12} md={5}><TextInput variant='outlined' fullWidth source = 'primerApellido'/> </Grid>
                                        <Grid item xs={12} md={5}><TextInput variant='outlined' fullWidth source = 'segundoApellido'/> </Grid>
                                    </Grid>
                                    )
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <Grid item md={0.1}>
                        <Divider orientation="vertical"  variant="middle"   />
                    </Grid>
                        
                    <Grid item md={5} container spacing={1}  alignItems={'baseline'}>
                        <Grid item md={12}><Typography variant ='h4'>Ubicación</Typography> </Grid>
                            <Grid item md={6} sm={12} >
                                <AutocompleteInput 
                                    format={v =>  parseInt(v)} 
                                    variant='outlined' fullWidth  source = 'pais' choices ={CountryList} size='small' 
                                    /> 
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <FormDataConsumer >
                                    { ({ formData, ...rest }) => (
                                    <SelectInput variant='outlined' fullWidth source = 'departamento'
                                        choices={ formData.pais ? StateList.filter(( element ) => element.id_country === parseInt(formData.pais)):[] }
                                    />
                                    )}
                                </FormDataConsumer>
                            </Grid>
                            <Grid item md={6} sm={12}>
                            <FormDataConsumer >
                                { ({ formData, ...rest }) => (
                                <SelectInput variant='outlined' fullWidth source = 'ciudad' size='small'
                                    choices={ formData.departamento ? CityList.filter(( element ) => element.id_state === parseInt(formData.departamento)):[] }
                                /> 
                                )}
                            </FormDataConsumer>
                            </Grid>
                            <Grid item md={6} sm={12}><TextInput variant='outlined' fullWidth source = 'direccion'  sx={{marginTop:'8px'}}/></Grid>
                            <Grid item md={2} sm={12}><NumberInput variant='outlined' fullWidth source = 'indicativoPais' label='Ind. Pais'/> </Grid>
                            <Grid item md={2} sm={12}><NumberInput variant='outlined' fullWidth source = 'indicativoCiudad' label='Ind. Ciudad'/></Grid>
                            <Grid item md={4} sm={12}><NumberInput variant='outlined' fullWidth source = 'telefono1'/> </Grid>
                            <Grid item md={4} sm={12}><NumberInput variant='outlined' fullWidth source = 'telefono2'/> </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} container justifyContent='center'  spacing={2}> 
                    <Grid item sm={9}>
                        <AutocompleteArrayInput  variant='outlined' fullWidth label='Sectores' source='sector' choices={Sectores} />
                    </Grid>
                </Grid>
            </Grid> 
    )

}

