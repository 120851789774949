/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://lii639qqqa.execute-api.us-east-1.amazonaws.com/devgf",
            "region": "us-east-1"
        },
        {
            "name": "AlegraConnect",
            "endpoint": "https://fztp6i8ey9.execute-api.us-east-1.amazonaws.com/devgf",
            "region": "us-east-1"
        },
        {
            "name": "iotcommunication",
            "endpoint": "https://eo6briveg4.execute-api.us-east-1.amazonaws.com/devgf",
            "region": "us-east-1"
        },
        {
            "name": "mysqlConnect",
            "endpoint": "https://jp3xwng7d8.execute-api.us-east-1.amazonaws.com/devgf",
            "region": "us-east-1"
        },
        {
            "name": "thumbnail",
            "endpoint": "https://egxqwvwo31.execute-api.us-east-1.amazonaws.com/devgf",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fkl36iq72jhnhcokrtkftolbfi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6zkq3qr7rvfhpj32rvzbpe75gu",
    "aws_cognito_identity_pool_id": "us-east-1:0a520e47-e096-4c2a-9be5-2fa83cd5a301",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ruvx9TPJb",
    "aws_user_pools_web_client_id": "1uso1cbu0agv9cbsckr7u80bel",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "testingfiles161021-devgf",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
