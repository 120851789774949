import{
    Edit,
    TabbedForm,
    useRecordContext,
    TopToolbar, 
    ListButton,
    ShowButton
} from 'react-admin'
import { Billing } from './tabs/CustomerBilling';
import { General } from './tabs/CustomerGen'
import { Machines } from './tabs/CustomerMachines'
import  Contacts  from './tabs/CustomerContact'
    


import ChevronLeft from '@mui/icons-material/ChevronLeft';
const CustomerEditActions = () => (
    <TopToolbar>
        <ListButton label="Volver" icon={<ChevronLeft />} />
        <ShowButton />
    </TopToolbar>
);

const EditTitle = () => {
    const record = useRecordContext();
    return <span>Editar Cliente: {record ? `${record.razonSocial}` : ''}</span>;
};

const editTransform = ({ productos, marcas, ordenes, ...data }) => {
    // console.log("EDITTRANSFORM->",data)
    if(data.primerNombre || data.primerApellido){
        data.razonSocial = `${data.primerNombre||''} ${data.primerApellido||''} `
    }
    
    return(
    {
    ...data,
    })}


const CustomerEdit = () => {
    return(
    <Edit  title={<EditTitle/>} actions={<CustomerEditActions />} transform={editTransform} redirect='list'>
        <TabbedForm >
           <TabbedForm.Tab label="General">
                <General type='edit'/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Facturación">
                <Billing />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Máquinas">
                <Machines />
            </TabbedForm.Tab>
            {/*
            <TabbedForm.Tab label="Contactos">
                <Contacts />
            </TabbedForm.Tab>
            */}

        </TabbedForm>
    </Edit>
    )
}
 
export default CustomerEdit

