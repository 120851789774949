
import React , { useState }from 'react'
import Divider from '@mui/material/Divider';

import{
    Create,
    TabbedForm,
    TopToolbar, 
    ListButton,
    Button,
    useRecordContext,
    useGetList,
    AutocompleteInput,  
    ReferenceInput
} from 'react-admin'
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useFormContext } from 'react-hook-form';
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import {CountryList, CityList, StateList} from '../../utils'

import { Billing } from './tabs/CustomerBilling';
import { General } from './tabs/CustomerGen'
import { Machines } from './tabs/CustomerMachines'
import  Contacts   from './tabs/CustomerContact'
import { el } from 'react-date-range/dist/locale';


const createTransform = (data) => {
    // console.log('CREATE TRANSFORM', data)
    if(data.primerNombre || data.primerApellido){
        data.razonSocial = `${data.primerNombre||''} ${data.primerApellido||''} `
    }
    return {
        ...data,
    }
}
    

const CustomerCreate = () => {
    const [importOpen, setImportOpen] = useState(false)

    return(
        <Create actions={<CustomerCreateActions isOpen={importOpen} setOpen={(v)=>setImportOpen(v)}/>} title='Crear Nuevo Cliente' transform={createTransform}>
            <TabbedForm fullWidth >
                <TabbedForm.Tab label="General">
                    <General type='create'/>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Facturación">
                    <Billing />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Máquinas">
                    <Machines />
                </TabbedForm.Tab>
                {
                /*<TabbedForm.Tab label="Contactos">
                    <Contacts />
                </TabbedForm.Tab>
                */}
                <ImportDialog isOpen={importOpen} closeDialog={()=>setImportOpen(false)}/>
            </TabbedForm>
        </Create>
    )
}

const CustomerCreateActions = (props) => (
    <TopToolbar>
        <ListButton label="Volver" icon={<ChevronLeft />} />
        <Button onClick={()=>props.setOpen(true)} label="Importar desde Alegra"/>
    </TopToolbar>
);



const ImportDialog = (props) => {
    const {isOpen, closeDialog} = props
    const [recordAlegra, setRecordAlegra] = useState(null)
    const filterToQuery = searchText => ({ name: searchText });
    const { setValue } = useFormContext();

    
    const handleChange = (value, record) => {
        console.log('Selected: ', value,record)
        setRecordAlegra(record)
    }

    const MapValuestoForm = () => {
        const countryId = CountryList.find(c=>c.name === recordAlegra.address?.country)?.id
        const stateId = StateList.find(s=>s.name === recordAlegra.address?.department && s.id_country===countryId)?.id
        const cityId = CityList.find(c=>c.name === recordAlegra.address?.city && c.id_state === stateId)?.id
        const tiempoPago = recordAlegra.term?.name.replace(' ','_')
        console.log('TRANSFORMED: ', recordAlegra, countryId, stateId, cityId, tiempoPago)
        if(recordAlegra){
            if(recordAlegra.kindOfPerson === 'LEGAL_ENTITY'){
                setValue('razonSocial', recordAlegra.name);
            }else{
                setValue('primerNombre', recordAlegra.firstName);
                setValue('segundoNombre', recordAlegra.secondName);
                setValue('primerApellido', recordAlegra.lastName);
                setValue('segundoApellido', recordAlegra.secondLastName);
            }
            if (countryId === 82) {
                setValue('indicativoPais', 57);
            } else {
                setValue('indicativoPais', null);
            }
            if (stateId === 1700) {
                setValue('indicativoCiudad', '604');
            } else if (stateId === 1702) {
                setValue('indicativoCiudad', '605');
            } else if (stateId === 1722) {
                setValue('indicativoCiudad', '602');
            } else {
                setValue('indicativoCiudad', null);
            }
            
            setValue('alegraId', recordAlegra.id);
            setValue('nit_cedula', recordAlegra.identification);
            setValue('digitoVerificacion', recordAlegra.identificationObject.dv);
            setValue('estado', recordAlegra.status === 'active'); // assuming 'active' is true, adjust accordingly
            setValue('razonSocial', recordAlegra.name);
            setValue('pais', countryId);
            setValue('departamento', stateId);
            setValue('ciudad', cityId);
            setValue('direccion', recordAlegra.address.address || ''); // Defaulting to empty string if null
            setValue('tipoDocumento', recordAlegra.identificationObject.type); // Enum for 'NIT' assuming recordAlegra.identificationObject.type maps directly
            setValue('telefono1', recordAlegra.phonePrimary || recordAlegra.mobile || '');
            setValue('telefono2', recordAlegra.phoneSecondary || '');
            setValue('datosFacturacion.emailFE', recordAlegra.email || '');
            setValue('datosFacturacion.regimenAdq', recordAlegra.regime)
            setValue('datosFacturacion.tipoPersonaAdq', recordAlegra.kindOfPerson);
            setValue('datosFacturacion.formaPago', tiempoPago);
            setValue('datosFacturacion.iva', recordAlegra.regime === 'COMMON_REGIME');      
            closeDialog()
        }
    }
    
    return(
        <Dialog open={isOpen} fullWidth={true}  >
            <h1>Importar desde Alegra</h1>
            <DialogContent>
                <ReferenceInput fullWidth source="alegraId" reference="alegraClientes" sort={{field: 'name', order: 'ASC'}} perPage={10} >
                    <AutocompleteInput fullWidth 
                    label="Cliente Alegra" 
                    optionText={'name'}
                    onChange={handleChange}
                    filterToQuery={filterToQuery} 
                    />
                </ReferenceInput>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>closeDialog()} label="Cerrar"  />
                <Button label="Importar" onClick={()=>MapValuestoForm() } variant="contained">
                    <DownloadIcon />
                </Button>
            </DialogActions>
        </Dialog>
    )
}



export default CustomerCreate

/*
{
    "id": "166",
    "name": "ACERO ECHEVERRI WORLD PACKAGING S.A.S.",
    "identification": "901430057",
    "email": "aewpking@gmail.com",
    "phonePrimary": "3147011686",
    "phoneSecondary": null,
    "fax": null,
    "mobile": null,
    "observations": null,
    "status": "active",
    "kindOfPerson": "LEGAL_ENTITY",
    "regime": "COMMON_REGIME",
    "identificationObject": {
        "type": "NIT",
        "number": "901430057",
        "dv": 2
    },
    "enableHealthSector": false,
    "healthPatients": null,
    "address": {
        "address": "CRA. 35 A # 77 SUR 71 Apto. 1115",
        "city": "Sabaneta",
        "department": "Antioquia",
        "country": "Colombia",
        "zipCode": null
    },
    "type": [
        "client"
    ],
    "seller": null,
    "term": {
        "id": 1,
        "name": "De contado",
        "days": "0"
    },
    "priceList": null,
    "internalContacts": [],
    "settings": {
        "sendElectronicDocuments": false
    },
    "statementAttached": true,
    "accounting": {
        "accountReceivable": {
            "id": "5007",
            "idParent": "5006",
            "name": "Ingresos Cuentas por Cobrar",
            "text": "Ingresos Cuentas por Cobrar",
            "code": "",
            "description": "",
            "type": "asset",
            "readOnly": false,
            "nature": "debit",
            "blocked": "no",
            "status": "active",
            "categoryRule": {
                "id": "5",
                "name": "Cuentas por cobrar",
                "key": "RECEIVABLE_ACCOUNTS"
            },
            "use": "movement",
            "showThirdPartyBalance": true
        },
        "debtToPay": {
            "id": "5033",
            "idParent": "5032",
            "name": "Cuentas por pagar a proveedores ",
            "text": "Cuentas por pagar a proveedores ",
            "code": null,
            "description": "",
            "type": "liability",
            "readOnly": false,
            "nature": "credit",
            "blocked": "no",
            "status": "active",
            "categoryRule": {
                "id": "11",
                "name": "Cuentas por pagar - proveedores",
                "key": "DEBTS_TO_PAY_PROVIDERS"
            },
            "use": "movement",
            "showThirdPartyBalance": true
        }
    }
}*/
/*
id: ID! 
alegraId: ID
nit_cedula: String
codigo: String
ciudad: String
digitoVerificacion: Int
estado: Boolean
razonSocial: String @index(name: "byCliente", queryField: "clientesByRazonSocial")
departamento: String
pais: String
direccion: String
primerNombre: String
segundoNombre: String
primerApellido: String
segundoApellido: String
tipoDocumento: TipoDocumento
datosFacturacion: DatosFacturacion
productos: [Producto] @hasMany(indexName: "byCliente", fields: ["id"])
ordenes: [Orden] @hasMany(indexName: "byCliente", fields: ["id"])
indicativoPais: Int
indicativoCiudad: Int
telefono: String


type DatosFacturacion {
  formaPago: String
  iva: Boolean
  variacionTRM: Boolean
  validarCxC: Boolean
  retefuenteType: String
  retefuentePorc: Float
  reteIvaPorc: Float
  reteIcaPorc: Float
  exportacion: Boolean
  precioOrden: [PrecioOrden]
  regimenAdq: RegimenAdq
  tipoPersonaAdq: TipoPersonaAdq
}

*/