

import React, { useEffect } from "react";
import { useRefresh,BooleanInput,
    } from 'react-admin';
import {  Typography, Grid, Button } from '@mui/material';


import { useFormContext } from 'react-hook-form';
import { formatCurrencyString } from "../../utils";





export const TotalesComponent = (props) => {
    const {getValues, setValue} = useFormContext();
    const data = getValues();
    //console.log('DATA', data);
    const refresh = useRefresh();
    useEffect(() => {
        if( data&&data.itemsFact && data.itemsFact.length>0 ){
            let totalArea = data.itemsFact.reduce((acc, item) => acc + item.area, 0);
            let subtotalIva = data.itemsFact.reduce((acc, item) => item.iva ? acc + item.valorTotal : acc, 0);
            let subtotalNoIva = data.itemsFact.reduce((acc, item) => !item.iva ? acc + item.valorTotal : acc, 0);
            let iva = subtotalIva * 0.19 ;
            let subtotal = subtotalIva + subtotalNoIva;
            let total = subtotal + iva;
            let retenciones = [];
            if (data.clientData&&data.clientData.datosFacturacion){
                if (data.clientData.datosFacturacion.retefuenteType === 'servicios'){
                    retenciones.push(data.clientData.datosFacturacion.retefuentePorc);
                }
                
            }
            setValue('totalArea', totalArea);
            setValue('subtotal', subtotal);
            setValue('iva', iva);
            setValue('total', total);
            setValue('valorPendiente', total);
            }   
            else {
                setValue('totalArea', 0);
                setValue('subtotal', 0);
                setValue('iva', 0);
                setValue('total', 0);
            }
        }, [data]);

 
    const handleDisableIva = (e) => {
        //console.log('CHECKBOX', e.target.checked)
        for (let i = 0; i < data.itemsFact.length; i++){
            if (data.itemsFact[i].iva === e.target.checked) continue;
            if (data.itemsFact[i].material.includes("FLETES") && data.itemsFact[i].iva === false) continue;
            setValue(`itemsFact.${i}.iva`, e.target.checked);
        }
        refresh();
        props.changed(e.target.checked);
    }
    return (
        <Grid item sm={12} md={5} container direction='row' >
            <Grid item md={6}>
                <Typography variant="h6" align="right">Total Area:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    {data?.totalArea?.toLocaleString('es-CO') || 0}
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right"><b>Subtotal:</b></Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    {formatCurrencyString(data?.subtotal) || 0} 
                </Typography>
            </Grid>
            <Grid item container direction='row' justifyContent='flex-end'  md={6}>
                <Grid item md={1.5}>    
                    <BooleanInput source="applyIva" label={false}  onChange={handleDisableIva} helperText={false}/>
                </Grid>
                <Grid item md={2} alignContent='center'>
                    <Typography  variant="h6" align="right"><b>IVA:</b></Typography>
                </Grid>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    { formatCurrencyString(data?.iva)  || 0} 
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right"><b>Total:</b></Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6" align="right">
                    { formatCurrencyString(data?.total) || 0} 
                </Typography>
            </Grid>
        </Grid>
    )
}         


