import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export default function AlegraIcon() {
    return (
        <SvgIcon>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                viewBox="0 0 24 25" >
            <path fill="#808080" d="M17.9,18c-0.5,0.3-5.2,1.2-8.8,0.3c-2.7-0.7-6.2-2.8-7.4-3.5c-0.2-0.1-0.3-0.4-0.1-0.6L6,8.6
                c0.2-0.2,0.6-0.2,0.7,0c0.7,1.1,2.5,3.8,4.7,5.6c2.9,2.5,5.2,3.1,6.6,3.3C18.1,17.6,18.4,17.8,17.9,18z"/>
            <path fill="#808080" d="M16.6,16.1C16.2,16,13,14.3,11.4,12c-1.2-1.6-2.2-4.4-2.6-5.5C8.7,6.3,8.9,6,9.1,6l4.9-1.1
                c0.3-0.1,0.6,0.2,0.5,0.5c-0.1,1.1-0.3,3.4,0,5.3c0.5,2.8,1.5,4.3,2.3,5.1C17,15.9,17.1,16.2,16.6,16.1z"/>
            <path fill="#808080" d="M17.1,14.8c-0.2-0.2-1.2-2.5-1.2-4.4c0-1.3,0.6-3.1,0.8-3.9c0.1-0.2,0.3-0.4,0.6-0.3l2.8,1.3
                c0.3,0.1,0.3,0.5,0.1,0.7c-0.5,0.6-1.5,1.7-2.1,2.8c-0.9,1.7-1,3-0.9,3.7C17.4,14.8,17.3,15,17.1,14.8z"/>
            <path fill="#808080" d="M18.3,14.1c0-0.2,0.3-1.6,1-2.4c0.5-0.6,1.3-1.2,1.7-1.5c0.2-0.1,0.4-0.1,0.5,0.1l0.7,1.5
                c0.1,0.2,0,0.4-0.2,0.5c-0.5,0.1-1.2,0.2-1.8,0.5c-1,0.5-1.5,1-1.7,1.4C18.4,14.2,18.3,14.3,18.3,14.1z"/>
            </svg>
        </SvgIcon>
    );
}
