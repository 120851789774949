import React, { useEffect, useRef, useState } from 'react';
import {
    useRecordContext, Form, useDataProvider,
    BooleanInput, NumberInput, useRedirect, useRefresh
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import { formatCurrencyString } from '../../utils';
import  LabeledCustomItem from '../../utils/LabeledCustomItem';


const AgregarPagoButton = (props) => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [changed, setChanged] = useState(false);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const handleCancel = () => {
        setOpen(false);
    }

    const handleConfirm = (data) => {
        //console.log('DATA', data)
        const {productFact, ...cleanRecord} = record;

        if(data.pagoTotal){
            const modifiedData = { ...cleanRecord, fechaPagado: new Date(), estado: 'Cobrada' ,pagoTotal: true ,  valorPago: record.total, valorPendiente: 0}
            dataProvider.update('cotizacions', { id: record.id, data: {...modifiedData } })
        }else{
            const usuarioID = localStorage.getItem('user');
            let modifiedData;
            if(!record.abonos || record.abonos?.length===0){
                const valorPendiente = record.total - data.valorPago;
                let estadoNew, pagototalbool;
                if (valorPendiente <= 0) {
                    estadoNew = 'Cobrada'
                    pagototalbool = true;
                }
                else{
                    pagototalbool = false;
                    estadoNew = 'Abonada'
                }
                modifiedData = { ...cleanRecord, fechaAbono: new Date(),pagoTotal: pagototalbool, valorPago: data.valorPago, estado: estadoNew, valorPendiente: valorPendiente,  abonos: { valor: data.valorPago, fecha: new Date(), usuarioID } }
            }
            else{
                const currentAbonos = record.abonos;
                currentAbonos.push({valor: data.valorPago, fecha: new Date(), usuarioID})
                const abonosSum = currentAbonos?.reduce((acc, abono) => acc + abono.valor, 0);
                const valorPendiente = record.total - abonosSum;
                let estadoNew, pagototalbool;
                if (abonosSum >= record.total) {
                    estadoNew = 'Cobrada'
                    pagototalbool = true;
                }
                else{
                    pagototalbool = false;
                    estadoNew = 'Abonada'
                }
                modifiedData = { ...cleanRecord, fechaAbono: new Date(), pagoTotal: pagototalbool, valorPago: data.valorPago, valorPendiente: valorPendiente, estado: estadoNew, abonos: currentAbonos }
            }
            dataProvider.update('cotizacions', { data: {...modifiedData } })
        }

        setOpen(false);
    }

    return (
        <>
        {
            props.type === 'edit'?
                <Button type='button' onClick={()=>setOpen(true)} endIcon={<PaymentsIcon />} variant='contained' color="primary" sx={{width:'100%'}} >
                            Aprobar
                </Button>
            :
                <IconButton variant='outlined' onClick={() => {setOpen(true)}}>
                    <PaymentsIcon />
                </IconButton>
            }
            <Dialog
                open={open}
                onClose={() => { 
                    setOpen(false) 
                    redirect('/cotizacions')
                    refresh();

                }}
                size='md'
            >
                <Form onSubmit={handleConfirm}> 
                    <DialogTitle>
                        Agregar Pago: Cotizacion # {record.consecutivo}
                    </DialogTitle>
                    <DialogContent>
                            <BooleanInput
                                sx={{minWidth: '500px'}}
                                source='pagoTotal'
                                label='Pago Completo'
                                defaultValue={true}
                                onChange={() => setChanged(prevChanged => !prevChanged)}
                            />
                            <Pagos record={record} changed={changed} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancelar</Button>
                        <Button  type='submit'>Confirmar</Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
}

const Pagos = ({ record, changed }) => {
    const { getValues, setValue } = useFormContext();
    const [pagoTotal, setPagoValue] = useState(true);
    const [showAbonos, setShowAbonos] = useState(false);
    const [showValorPago, setShowValorPago] = useState(false);

    
    useEffect(() => {
        const formValues = getValues()
        const abonosSum = formValues.abonos&&formValues.abonos?.length>0?formValues.abonos.reduce((acc, abono) => acc + abono.valor, 0) : 0;
        if(formValues.abonos && formValues.abonos.length>0){
            setShowAbonos(true);
            return;
            }
        });

    useEffect(() => {
        const formValues = getValues()
        if(formValues.pagoTotal === true ){
             setPagoValue( true );
             setShowValorPago(false);
        }
        else{
            setPagoValue( false );
            setShowValorPago(true);
        }

    });
    return (
        <>
            <>
                { pagoTotal ?
                    <LabeledCustomItem label='Valor Total' value={formatCurrencyString(getValues().total)} />
                    : <span></span>}
            </><>
            
            {showValorPago ?
                    <NumberInput fullWidth source='valorPago' label='Valor Pago' />:<span></span>
            }
            </>
            <>
                {
                    showAbonos ?
                    <Table style={{ width: '100%', textAlign: 'left' }}>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.abonos?.map((abono, index) => (
                                <tr key={index}>
                                    <td>{new Date(abono.fecha).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                                    <td>{formatCurrencyString(abono.valor)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'left' }}><hr style={{ width: '80%', marginLeft: 0 }}/></td>
                            </tr>
                            <tr>
                                <td><strong>Total Abonos:</strong></td>
                                <td>{formatCurrencyString(record.abonos?.reduce((acc, abono) => acc + abono.valor, 0)||0)}</td>
                            </tr>
                            <tr>
                                <td><strong>Saldo Pendiente:</strong></td>
                                <td>{formatCurrencyString(record.total-(record.abonos?.reduce((acc, abono) => acc + abono.valor, 0)||0))}</td>
                            </tr>
                        </tbody> 
                    </Table>:<span></span>
                }
            </>
        </>         
        
    );
}

export default AgregarPagoButton