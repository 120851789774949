import { BooleanField, Datagrid, DateField, EmailField, List, NumberField, TextField, TextInput } from 'react-admin';

const Filters = [
    <TextInput label="Nombre Cliente" source="name" alwaysOn resettable />,
    <TextInput label="Identidicación" source="identification" alwaysOn resettable/>,
];


export default () => (
    <List filters={Filters}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="identification" />
            <EmailField source="email" />
            <TextField source="phonePrimary" />
            <TextField source="phoneSecondary" />
            <TextField source="fax" />
            <TextField source="mobile" />
            <TextField source="observations" />
            <TextField source="status" />
            <TextField source="kindOfPerson" />
            <TextField source="regime" />
            <TextField source="identificationObject.type" />
            <TextField source="fiscalResidence" />
            <BooleanField source="enableHealthSector" />
            <TextField source="healthPatients" />
            <TextField source="address.address" />
            <TextField source="type" />
            <TextField source="seller" />
            <NumberField source="term.id" />
            <TextField source="priceList" />
            <TextField source="internalContacts" />
            <BooleanField source="settings.sendElectronicDocuments" />
            <BooleanField source="statementAttached" />
            <DateField source="accounting.accountReceivable.id" />
            <TextField source="nameObject.firstName" />
            <TextField source="emailSecondary" />
        </Datagrid>
    </List>
);