import React, { useState, useEffect } from 'react';
import { 
  Form, 
  Button, 
  useNotify, 
  TextInput, 
  useUpdate, 
  SaveButton, 
  useRecordContext, 
} from 'react-admin';
import IconCancel from '@mui/icons-material/Cancel';
import IconContentAdd from '@mui/icons-material/Add';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@mui/material';

export const SolicitarAnulacionDialog = () => {
  
  const notify = useNotify();
  const record = useRecordContext();
  const motivoExpo = record.motivo;
  const [update, { isLoading, error }] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    update('exposicions', {
      id: record.id,
      data: {
        id: record.id,
        motivo: values.motivo !== '' ? values.motivo : record.motivo,
      },
      previousData: record,
    },
    {
      onSuccess: () => {
        setShowDialog(false);
        notify('Motivo agregado correctamente.')
      }
    },
    {
      onFailure: () => {
        notify('Error al agregar el motivo.', {type: 'error'})
      }
    })
  };
  
  if (error) {
    return <Button style={{ color: 'white', fontWeight: 'bold', background: 'red' }}>Error {error.message}</Button>;
  }

  const filterAble = motivoExpo === null;
  const filterDisabled = motivoExpo !== null;

  return (
     filterAble ? ( 
      <>
        <Button label="Solicitar Anulación" variant="contained" onClick={handleClick} disabled={filterDisabled}>
          <IconContentAdd />
        </Button>
        <Dialog fullWidth maxwidth="md" open={showDialog} onClose={handleCloseClick} aria-label="Crear Medida">
          <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ fontWeight: 'bold' }}>{`Agregar Motivo`}</Typography>
          </DialogTitle>
          <Form resource="exposicions" id="create-exposicion" onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} flexDirection="row" justifyContent="space-evenly">
                  <Grid item xs={6} sm={12} md={8} lg={8}>
                    <TextInput resettable multiline fullWidth maxwidth="lg" maxRows={10} source="motivo" label="Motivo"/>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                  <IconCancel/>
                </Button>
                <SaveButton/>
              </DialogActions>
            </>
          </Form>
        </Dialog>
      </>
    ) : (
      <span/>
    )
  );
};