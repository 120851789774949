



//////Working Example////////

import { uploadData } from "@aws-amplify/storage";
import React from "react";
import { useInput } from "react-admin";
import { v4 as uuid } from "uuid";
import { DataProvider } from "../providers/DataProvider";

export function useStorageInput({
  source,
  multiple = false,
  onDropAcceptedCallback,
  storageOptions = {},
}) {
  const { field } = useInput({ source });

  React.useEffect(() => {
    if (Array.isArray(field.value) && field.value.length === 0) {
        field.onChange(undefined);
    }
  }, [field]);

  async function onDropAccepted(files, event) {
    try {
      const values = await Promise.all(
        files.map(async (file) => {
          console.log("FILE", file);
          const result = (await uploadData({
            key:`${uuid().replace(/-/g, "")}_${file.name}`,
            data:file,
            options: {accessLevel: 'guest'}
            }
          )) 
          console.log("RESULT", await result.result);
          const key = await result.result.key
          if(result.state ===  "SUCCESS"  ){
            
            if( typeof  key === "string"){
              console.log("KEY", key);
              return {
                bucket: DataProvider.storageBucket,
                region: DataProvider.storageRegion,
                key: key,
              };
            }
          }
        })
      );

      if (!multiple) {
        field.onChange(values[0]);
        return;
      }

      if (field.value) {
        field.onChange([...field.value, ...values]);
      } else {
        field.onChange(values);
      }
    } catch (e) {
      console.log(e);
      field.onChange(undefined);
    }

    if (onDropAcceptedCallback) {
      onDropAcceptedCallback(files, event);
    }
  }

  return { onDropAccepted };
}


