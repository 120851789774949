
import React, { useEffect } from "react";

import { Loading,  TextField, 
        useDelete, Button, useRefresh,
         FunctionField,   ReferenceManyField,
        useRecordContext,} from "react-admin";
import { IconButton } from '@mui/material';

import { put } from 'aws-amplify/api';
import { useGetOneLive } from "@react-admin/ra-realtime";

import {CreateHuellaDialog} from '../../../marcaciones/huellas/createHuellaDialog.js'
import { EditableDatagrid } from "@react-admin/ra-editable-datagrid";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { FpsDedos, FpsDevices } from "../../../utils/index.js";
import { Chip } from "@mui/material";
import ActionDelete from '@mui/icons-material/Delete';



const CreateFingerPrintFullDataGrid = (props)=>{
    const record = useRecordContext()
    const refresh = useRefresh();
    return (
        <ReferenceManyField
        label="Huellas"
        reference="Huellas"
        target="userID.eq"
        filter={{userID: {eq:record?.id}}}

        >      
            <EditableDatagrid
                sx={{ width: '100%' }}
                mutationMode="undoable"
                createForm={<CreateHuellaDialog r={{ id: record?.cognitoUserId.slice(0, 4) +'-'+ Math.floor(Math.random() * 1000), userID: record?.cognitoUserId, userName: record?.nombres+' '+record?.apellidos}}/>}
                editform={<CreateHuellaDialog r={{ userID: record?.cognitoUserId, userName: record?.nombres+' '+record?.apellidos}}/>}
                noDelete={true}
                actions={<DatagridActionsColumn />}
            >                
                <FunctionField source='device' label= "Dispositivo" render ={record => FpsDevices.find( x => x.id === record?.device )?.name} />
                <ChipFieldCustom source='status' doRefetch={()=>refresh()}/>
                <FunctionField source='finger' label= "Huella" render ={record => FpsDedos.find( x => x.id === record?.finger ).name} />
                <TextField source='fingerDeviceID' label="ID Dispositivo"/>
                <FunctionField source='registered_at' label="Fecha Registro" render={record=>record.registered_at!==undefined ?new Date(record.registered_at).toLocaleString():""} />        
            </EditableDatagrid>
            
        </ReferenceManyField>
    )
}

const DatagridActionsColumn = () => (
    <>            
        <RegistrarButton/>
        <DeleteCustomButton/>
    </>
);

const DeleteCustomButton =(props)=> {
    const record = useRecordContext()
    const [deleteOne] = useDelete(
        'Huellas',
        { id: record.id, previousData: record }
    );
    const handleDelete = () => {
        deleteOne();
        callLambda(record?.userID, record?.userName, record?.finger, record?.device, record?.id, record?.fingerDeviceID,  "BORRAR")
    }


    return(
        <IconButton   
            onClick={()=>handleDelete()}
             >
            <ActionDelete color ='error' />
        </IconButton>
    )
        
    
}
const RegistrarButton =(props)=> {
    const record = useRecordContext()
    return(
        <Button  
            label="Registrar" 
            onClick={()=>{callLambda(record?.userID, record?.userName, record?.finger, record?.device, record?.id, 0 , "REGISTRAR")}}
             >
                <HowToRegIcon/>
             </Button>
    )
}

async function callLambda(userID, userName, finger, device, id, fingerDeviceID , type) {
    try {
    if(fingerDeviceID === 0 && type === "BORRAR") return;
      const result = await put({

          apiName:'iotcommunication', 
          path:'/iotcomm',
          options:
           {
              body: {
                  id: id,
                  userID: userID,
                  userName: userName,
                  finger: finger,
                  device: device,
                  fingerDeviceID:fingerDeviceID,
                  type: type
                },
                headers: {
                    'Content-Type': 'application/json',
                },
        }
      });
      console.log("Lambda Called...");
    } catch (error) {
      console.log(error);
    }
  }

const ChipFieldCustom = (props)=>{
    const record = useRecordContext();
    const { data, isLoading, error } = useGetOneLive("Huellas", {
         id: record.id,
        });
        useEffect(() => {
            props.doRefetch();
        }, [record]);

        if (isLoading) {
            return <Loading />;
        }
        if (error) {
            return <p>ERROR</p>;
        }

  return <Chip color={data?.status === "REGISTRADO"? "success": data?.status=== "NO_REGISTRADO"? "default":"warning"} label={data?.status}/>;
};



export default CreateFingerPrintFullDataGrid