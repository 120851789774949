import React, {useState, useEffect}from 'react';
import { SelectInput, TextInput, NumberInput, DateInput, useRefresh, useRecordContext, FunctionField, useCreate , Labeled, Button} from 'react-admin';
import { Grid, TextField } from '@mui/material';
import TrmInput from './TrmInput';
import { useFormContext, useWatch } from 'react-hook-form';

const CurrencyInputs = () => {
    const { setValue } = useFormContext();

    const [changedForm, setChangedForm] = useState(false);

    const changeCurrencyValues = (event) => {
        setMonedaReferencia(event.target.value);
    };
    
    const record = useRecordContext();
    const [monedaReferencia, setMonedaReferencia] = useState(record?.monedaReferencia || "USD");



    const valorUsd = useWatch({ name: 'valorUsd' });
    const valorUpchargeUsd = useWatch({ name: 'valorUpchargeUsd' });
    const valorTotalUsd = useWatch({ name: 'valorTotalUsd' });
    const trmValue = useWatch({ name: 'trm' });
    const usdUnit = useWatch({ name: 'unidadesUsd' });
    const copUnit = useWatch({ name: 'unidadesCop' });
    const valorCop = useWatch({ name: 'valorCop' }) || 0;
    const valorUpchargeCop = useWatch({ name: 'valorUpchargeCop' }) || 0;
    const valorTotalCop = useWatch({ name: 'valorTotalCop' }) || 0;

    useEffect(() => {
        if (!valorCop) {
            setValue('valorCop', parseFloat(record?.valorCop || 0));
        }
        if (!valorUpchargeCop) {
            setValue('valorUpchargeCop', parseFloat(record?.valorUpchargeCop || 0));
        }
        if (!valorTotalCop) {
            setValue('valorTotalCop', parseFloat(record?.valorTotalCop || 0));
        }
    }, [valorCop, valorUpchargeCop, valorTotalCop, record]);

    
  
   
    const unitConversionRates = {
      centimeterSquared: 10000, 
      footSquared: 10.7639,     
      meterSquared: 1,          
      unit: 1                   
    };

  
    const calculateCOPValues = () => {
        const conversionRateUSD = unitConversionRates[usdUnit] || 1;
        const conversionRateCOP = unitConversionRates[copUnit] || 1;
        const newCopValues = {
            valorCop: (valorUsd * trmValue * conversionRateUSD / conversionRateCOP).toFixed(2),
            valorUpchargeCop: (valorUpchargeUsd * trmValue * conversionRateUSD / conversionRateCOP).toFixed(2),
            valorTotalCop: ((valorUsd + valorUpchargeUsd) * trmValue * conversionRateUSD / conversionRateCOP).toFixed(2)
        };
        //setCopValues(newCopValues);
        setValue('valorCop', parseFloat(newCopValues.valorCop));
        setValue('valorUpchargeCop', parseFloat(newCopValues.valorUpchargeCop));
        setValue('valorTotalCop', parseFloat(newCopValues.valorTotalCop));
    };
  

    useEffect(() => {
        if (monedaReferencia === 'USD' ) {
            calculateCOPValues();
            setValue('valorTotalUsd', parseFloat((valorUsd + valorUpchargeUsd).toFixed(2)));
            setChangedForm(false);
        }
    },[valorTotalUsd, valorUsd, valorUpchargeUsd, trmValue, monedaReferencia, changedForm, trmValue, usdUnit, copUnit]);
  
    const handleUSDChange = (event) => {
  
    };
  
    const handleTrmChange = event => {
        setChangedForm(true)
    };
    const handleUsdUnitChange = event => {
        setChangedForm(true)
    };
    const handleCopUnitChange = event => {
        setChangedForm(true)
    };




    return (
        <Grid item container xs={12} sm={5} >
            <Grid item xs={12} md={4}>
                <SelectInput source="monedaReferencia" label='Moneda Ref.'  choices={[{ id: "USD", name: "USD" }, { id: "COP", name: "COP" }]} defaultValue={'USD'} onChange={changeCurrencyValues}/>
            </Grid>
            {monedaReferencia === "USD" ? (
                <>
                    <Grid item xs={12} sm={5}>
                        <TrmInput source="trm" sx={{ marginTop: "8px" }} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <DateInput source="fechaTrm" sx={{ marginTop: "8px" }} />
                    </Grid>
                </>
                    ) : null
            }{
                monedaReferencia === "USD" ? (
                    <Grid item xs={12} sm={12} container>
                        <Grid item xs={12} sm={12}>
                            <SelectInput
                                source="unidadesUsd"
                                choices={[
                                    { id: "centimeterSquared", name: "Centimetros Cuadrados" },
                                    { id: "footSquared", name: "Pie Cuadrado" },
                                    { id: "meterSquared", name: "Metros Cuadrados" },
                                    { id: "unit", name: "UNIDAD" },
                                ]}
                                defaultValue={'footSquared'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberInput source="valorUsd" />
                            
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberInput source="valorUpchargeUsd" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberInput source="valorTotalUsd" />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <SelectInput
                                source="unidadesCop"
                                choices={[
                                    { id: "centimeterSquared", name: "Centimetros Cuadrados" },
                                    { id: "footSquared", name: "Pie Cuadrado" },
                                    { id: "meterSquared", name: "Metros Cuadrados" },
                                    { id: "unit", name: "UNIDAD" },
                                ]}
                                defaultValue={'centimeterSquared'}
                            />
                        </Grid>
                        <CopEquivalentValuesField valorCop={valorCop} valorUpchargeCop={valorUpchargeCop} valorTotalCop={valorTotalCop} />
                    </Grid>
                ) : null
            }
            {monedaReferencia === "COP" ? (
            <Grid item xs={12} sm={12} container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <SelectInput
                        source="unidadesCop"
                        choices={[
                            { id: "centimeterSquared", name: "Centimetros Cuadrados" },
                            { id: "footSquared", name: "Pie Cuadrado" },
                            { id: "meterSquared", name: "Metros Cuadrados" },
                            { id: "unit", name: "Unidades" },
                        ]}
                        defaultValue={'centimeterSquared'}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberInput source="valorTotalCop" sx={{marginTop:'8px'}} fullWidth />
                </Grid>
            </Grid>
            ): null}
        </Grid>
    );
};

const CopEquivalentValuesField = (props) => {
  
    return (
        <Grid item container xs={12} sm={12}  spacing={1} >
            <Grid item xs={12} sm={4}>
                <TextField label="Valores Base COP" variant="outlined"  value={props.valorCop} size='small'  fullWidth/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField  label= "Valor UpCharge COP" variant="outlined"  value={props.valorUpchargeCop} size='small' fullWidth/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField label="Valor Total COP" variant="outlined"  value={props.valorTotalCop} size='small' fullWidth/>
            </Grid>
        </Grid>
    );
}


export default CurrencyInputs;