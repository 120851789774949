import React, { useEffect, useState } from "react";
import { TextField, Datagrid,
        useListContext, NumberField, useDataProvider
        } from "react-admin"

import { getHolidaysByYear } from "../utils/festivos";

import {  Button, Card, CardHeader, CardContent, IconButton, Typography } from '@mui/material';



  

const Summary = (props) => {
  const { data, filterValues } = useListContext();
  //state of resumen by user  generated in useEffect
  const [resumen, setResumen] = useState([]);
  console.log("filterValues", filterValues)
  //const [create, {isLoading, error}]  = useCreate();
  const dataProvider = useDataProvider();


    const settlePeriod = (data) => { 
        data.forEach(period => {    
                console.log("Periodo---->", period)
                //create({ data: period })
                const periodo = {
                    id: filterValues?.inicio.gt+'_'+filterValues?.fin.lte+'_'+period.userId.split("-")[0],
                    inicio: new Date(filterValues?.inicio.gt),
                    fin: new Date(filterValues?.fin.lte),
                    userId: period.userId,
                    totalHoras: period.totalHoras,
                    totalPausas: period.totalPausas,
                    totalHorasLaboradas: period.totalHoras-period.totalPausas,
                    totalTiempoLibre: period.totalTiempoLibre,
                    totalHERecargos: period.totalRecargos,
                    diasLaborables: period.workingDays,
                    diasLaborados: period.workingDays,
                    horasLaborables: period.workingHours,
                    horasLaboradas: period.totalHoras-period.totalPausas,
                }
                console.log("PeriodoModificado---->", periodo)
                dataProvider.create('periodos',{ data:periodo })//se debe escribir "periodoss" para que el data provider lo reconozca en singular la tabla periodos
        })
        
        //Create a new period with the data of the period to settle
       
    }
/* 

  id: ID!
  inicio: AWSDateTime
  fin: AWSDateTime
  userId: ID @index(name: "byUser")
  totalHoras: Float
  totalPausas: Float
  totalHorasLaboradas: Float
  totalTiempoLibre: Float
  totalHERecargos:Float
  diasLaborables: Int
  diasLaborados: Int
  horasLaborables: Float
  horasLaboradas: Float
  diasIncapacidad: Int 
  diasVacaciones: Int
  diasLicenciaRemunerada: Int
  diasLicenciaNoRemunerada: Int
  Turnos : [Turno] @hasMany(indexName: "byPeriodo", fields: ["id"])
*/
  const RequiredWorkingDays =   filterValues?.inicio?.gt && filterValues?.fin?.lte ? CalculateRequiredWorkingDays(filterValues.inicio.gt, filterValues.fin.lte) : null;

  useEffect(() => {
    if (data) {
      const userIds = data.map(({ userId }) => userId);
      const uniqueUserIds = [...new Set(userIds)];

      //extract data grouped by userId and make a resumen for each user
      const res = uniqueUserIds.map(userId => {
        const userTurnos = data.filter(turno => turno.userId === userId);
        return {
          userId,
          userName: userTurnos[0].userName,
          totalHoras: userTurnos.reduce((acc, turno) => acc + turno.totalHoras, 0),
          totalPausas: userTurnos.reduce((acc, turno) => acc + turno.tPausas, 0),
          totalTiempoLibre: userTurnos.reduce((acc, turno) => acc + turno.hLibre, 0),
          totalRecargos: userTurnos.reduce((acc, turno) => acc + turno.TotalHorasExtrasConRecargos, 0),
          workingDays: RequiredWorkingDays?.workingDays,
          workingHours: RequiredWorkingDays?.totalHours.toFixed(1),
        };
      });
      setResumen(res);
  }}, [data]);


  return (
      filterValues?.inicio && filterValues?.fin ? (
          <Card>
              <CardHeader
                  title={`Resumen Periodo Desde ${RequiredWorkingDays?.startDate} hasta ${RequiredWorkingDays?.endDate}` }
                  titleTypographyProps={{fontWeight:"bold"}}
                  subheader={`Dias Laborables: ${RequiredWorkingDays?.workingDays}  /  Horas Laborables: ${RequiredWorkingDays?.totalHours.toFixed(1)}  /  Domingos o Festivos: ${RequiredWorkingDays?.holidaysOrSundays}`}
                  action={
                      <IconButton onClick={()=>settlePeriod(resumen)} label='GUARDAR PERIODO'>

                      </IconButton>
                  }
              />
              <CardContent>
                  <Datagrid data={resumen} >
                      <TextField source="userName" label="Nombre"/>
                      <NumberField source="totalHoras" label="Total Horas Laboradas"/>
                      <NumberField source="totalTiempoLibre" label="Total Tiempo Libre"/>
                      <NumberField source="workingDays" label="Dias Laborados"/>
                      <NumberField source="totalRecargos" label="Total Horas Extras a Pagar"/>
                  </Datagrid>
              </CardContent>
          </Card>
      ) : (
          <Card>
              <Typography variant="h6" minHeight="50px" align="center" justifyItems="center">Seleccione Rango de fechas para generar pre-liquidación</Typography>
          </Card>
      )
  );
};


const calculateWorkingDays = (data) => {
  let datesArray = data.map(obj => new Date(obj.inicio));
  let localDateTimeArray =  datesArray.map(date => new Date(date.getTime() + (-300 * 60 * 1000)));
  let workingDays = 0;
  let startDate;
  let endDate;
  let totalHours = 0;
  let holidaysOrSundays = 0;

  localDateTimeArray.sort((a, b) => a - b);

    startDate = new Date(localDateTimeArray[0]);
    endDate = new Date(localDateTimeArray[localDateTimeArray.length - 1]);

    localDateTimeArray.forEach((date) => {
        let currentDay = date.getDay();
        let isHolidayVal = isHoliday(date);
        
        if (isHolidayVal || currentDay === 0) {  // Sunday is 0 in JS
            holidaysOrSundays++;
        } else {
            if (currentDay === 6) {  // Saturday
                totalHours += 5;
                workingDays++;
            } else {
                totalHours += 8.4;   // Monday to Friday
                workingDays++;
            }
        }
    });

    return {
        startDate: startDate,
        endDate: endDate,
        workingDays: workingDays,
        totalHours: totalHours,
        holidaysOrSundays: holidaysOrSundays
      };
}

const CalculateRequiredWorkingDays = (startDate, endDate) => {
    let workingDays = 0;
    let totalHours = 0;
    let holidaysOrSundays = 0;
    // generate array of dates between start and end dates

    let datesArray = getDates(startDate, endDate);
    let localDateTimeArray =  datesArray.map(date => new Date(date.getTime() + (-300 * 60 * 1000)));

    localDateTimeArray.sort((a, b) => a - b);
    localDateTimeArray.forEach((date) => {
        let currentDay = date.getDay();
        let isHolidayVal = isHoliday(date);
        
        if (isHolidayVal || currentDay === 0) {  // Sunday is 0 in JS
            holidaysOrSundays++;
        } else {
            if (currentDay === 6) {  // Saturday
                totalHours += 5.5;
                workingDays++;
            } else {
                totalHours += 8.4;   // Monday to Friday
                workingDays++;
            }
        }
    }
    );
    return {
        startDate: startDate,
        endDate: endDate,
        workingDays: workingDays,
        totalHours: totalHours,
        holidaysOrSundays: holidaysOrSundays
      };
}
// implementation of getDates function
const getDates = (startDate, endDate) => {
    let dates = []
    let endDateObj = new Date(endDate)
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    while (theDate < endDateObj) {
      dates = [...dates, new Date(theDate)]
      theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, endDateObj]
    return dates
  }




function isHoliday(date) {
  // List of holiday dates in 'yyyy-mm-dd' format
  let holidays = []

  //This is because the holidys are only for colombia, if we want to use this in other countries we need to change this
  const localDateTime = new Date(date.getTime() + (-300 * 60 * 1000));

  getHolidaysByYear(localDateTime.toISOString().substring(0, 4)).map(holiday => {
    let holidayArray = holiday.date.split("/")
    holidays.push(holidayArray[2] + "-" + holidayArray[1] + "-" + holidayArray[0])
    }
  )
  const formattedDate = localDateTime.toISOString().substring(0, 10); // Convert date to 'yyyy-mm-dd' format
  return holidays.includes(formattedDate);
}



export default Summary;
