import React from "react";
import { ImageInput } from "react-admin";
import { useStorageThumbnailInput } from "../hooks/useStorageThumbnailInput";
import { AmplifyThumbnailField } from "./AmplifyThumbnailField";
/*
type Props = {
  source: string;
  multiple?: boolean;
  options?: any;
  storageOptions?: any;
} & FileInputProps &
  InputProps<FileInputOptions>;
*/

export const FileWithThumbnail = ({
  options = {},
  storageOptions = {},
  ...props
}) => {
  const { onDropAccepted } = useStorageThumbnailInput({
    source: props.source,
    multiple: props.multiple,
    onDropAcceptedCallback: options.onDropAccepted,
    storageOptions,
  });

  return (
    <ImageInput {...props} options={{ ...options, onDropAccepted }}>
      <AmplifyThumbnailField storageOptions={storageOptions} />
    </ImageInput>
  );
};