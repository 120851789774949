import { 
  List, 
  Datagrid, 
  TextField, 
  ArrayField, 
  FilterList,
  NumberField,
  FunctionField,
  FilterListItem, 
  useUnselectAll,
  useListContext,
  ReferenceField,
} from 'react-admin';
import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import BadgeIcon from '@mui/icons-material/Badge';
import { Card, CardContent } from '@mui/material';
import { OrdenSalidaShow } from './OrdenSalidaShow';
import { AddDiferenciaDialog } from './components/AddDiferenciaDialog';
import { SolicitarReposicionDialog } from './components/SolicitaReposicionDialog';

export const OrdenSalidaList = () => { 

  return (
    <List rowClick={false} aside={<OrdenSalidaFilters/>}> 
      <Datagrid>
        <NumberField source="op" style={{ fontWeight: 'bold' }} label="Orden Producción"/>
        <NumberField source="version" style={{ fontWeight: 'bold' }} label="Versión"/>
        <NumberField source="totalPlanchasOrden" style={{ fontWeight: 'bold' }} label="Planchas Orden"/>
        <NumberField source="totalPlanchasExp" style={{ fontWeight: 'bold' }} label="Planchas Expuestas"/>
        <NumberField source="calibreOrden" style={{ fontWeight: 'bold' }} label="Calibre Orden"/>
        <ArrayField source='calibresExp' label="Calibres Exposición">
          <TextField alignContent='right' alignItems='right' source="calibresExp" style={{ fontWeight: 'bold' }} />
        </ArrayField>
        <ReferenceField label='RESPONSABLE' source='responsableRepo' reference='usuarios' link={false}>
          <FunctionField label="RESPONSABLE" style={{ fontWeight: 'bold' }} render={record => `${record.nombres} ${record.apellidos}`} />
        </ReferenceField>
        <AddDiferenciaDialog/>
        <SolicitarReposicionDialog/>
        <OrdenSalidaShow/>
      </Datagrid>
    </List>
  );
};

const OrdenSalidaFilters = () => {

  const unselectAll = useUnselectAll('ordenSalidas');
  const { data, filterValues, setFilters } = useListContext();
  const responsableReposicion = removeDuplicates(data, 'responsableRepo');

  useEffect(() => {
    if (filterValues) {
      unselectAll()
    }
    else{
    }
  }, [filterValues]);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 210 }}>
      <CardContent>
        <FilterList label="Reposicion">
          <FilterListItem 
            label="Sí" 
            onChange={(value)=>console.log("Pendiente Salida Sí", value)}
            value={{ pendienteSalida: { contains: 'true' }}} 
          />
          <FilterListItem 
            label="No"
            onChange={(value)=>console.log("Pendiente Salida No", value)}
            value={{ pendienteSalida: { contains: 'false' }}} 
          />
        </FilterList>
        <Divider sx={{ mt:2 }}/>
        <FilterList label="Responsable" icon={<BadgeIcon sx={{ fontSize: 12 }}/>} >
          {responsableReposicion?responsableReposicion.map((item)=>(
            <FilterListItem sx={{RaFilterListItem:{fontSize:'10px'}, ml:-4, width: 200}} 
              source='responsableRepo.contains'
              onChange={(value)=>console.log("value responsableRepo", value)} 
              key={item.responsableRepo} 
              label={ item.responsableRepo} 
              value={{ responsableRepo: { contains: item.responsableRepo }}}
            />
          )): <span/>}
        </FilterList>
      </CardContent>
    </Card>
  )
};

const removeDuplicates = (data, uniqueKey) => {
  return data?data.reduce((accumulator, current) => {
    if (!accumulator.some((item) => item[uniqueKey] === current[uniqueKey])) {
      if(current[uniqueKey]){
        accumulator.push(current);
      }
    }
    return accumulator;
  }, []):[];
};
