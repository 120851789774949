import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error, CheckboxGroupInput, RadioButtonGroupInput, ReferenceInput, choices, FormDataConsumer } from 'react-admin';
import { Typography, Grid, Button } from '@mui/material';
import get from 'lodash/get';
import {useFormContext, useWatch} from 'react-hook-form'

const SelectOrderType = ( props) => {
    const {clientId, handleSetClient, client , children,formData, source, index, ...rest } = props;
    const {setValue}=useFormContext()
    const dataProvider = useDataProvider();
    const productoID = useWatch({name: 'productoID[0]'})
    //console.log(productoID)
    useEffect(() => {
        dataProvider.getOne('Clientes', { id: clientId })
            .then(({ data }) => {
                handleSetClient(data);
            })
            .catch(error => {
                
            })
    }, []);



    const handleOrderSelection=()=>
    {   
        const arr= ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','9','8','7','6','5','4','3','2','1']
        
        const newId = client.codigo +'-'+ (new Date().getFullYear()-2020).toString()+ arr[Math.floor(Math.random()*arr.length)]+arr[Math.floor(Math.random()*arr.length)]+arr[Math.floor(Math.random()*arr.length)]
        setValue('id', newId)
    }
 
    if (!client) return null;
    //console.log(client)
    return (
        <CheckboxGroupInput variant = 'outlined' label='Seleccione el tipo de Orden' source = 'tipoOrden' choices={client.datosFacturacion.precioOrden} optionText='tipoOrden' onClick={handleOrderSelection} optionValue='tipoOrden' />
    )
};



export default SelectOrderType


/* 
<Grid container spacing={3}>
            {client.datosFacturacion.precioProducto.map((client, index)=>(
                <Grid item md={6}>
                    <Button fullWidth variant="outlined" 
                        key={get(client,'tipoProducto')}
                        onClick={handleSelection}
                    > {client.tipoProducto }
                     </Button>
                </Grid>
            ))}

        </Grid>

*/