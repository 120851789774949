import AdminPanelSettingsIcon  from '@mui/icons-material/AdminPanelSettings';
import CustomerIcon from '@mui/icons-material/Contacts';
import UsersIcon from '@mui/icons-material/PeopleAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CategoryIcon from '@mui/icons-material/Category';
import customerList from './customer/CustomerList';
import customerEdit from './customer/CustomerEdit';
import customerCreate from './customer/CustomerCreate'
import AlegraCustomerList from './alegra/AlegraCustomerList';
import UserList from './users/UserList'
import UserCreate from './users/UserCreate'
import UserEdit from './users/UserEdit'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { MaterialList, MaterialCreate, MaterialEdit, MaterialShow } from './inventario/Materiales';
import { StockList } from './inventario/Stock/StockList';
import { StockCreate } from './inventario/Stock/StockCreate';
import { EditGuesser } from 'react-admin';


//import CustomerContactEdit from './customer/tabs/EditContactButton'

const Admin = {
    customerList: customerList,
    customerCreate: customerCreate,
    customerEdit: customerEdit,
    AlegraCustomerList: AlegraCustomerList,
    UserList: UserList,
    UserCreate: UserCreate,
    UserEdit: UserEdit,
    MaterialCreate: MaterialCreate,
    MaterialEdit: MaterialEdit,
    MaterialList: MaterialList,
    MaterialShow: MaterialShow,
    StockList: StockList,
    StockCreate: StockCreate,
    StockEdit: EditGuesser,
    icons: {
      main: AdminPanelSettingsIcon,
      user:UsersIcon,
      cognitoUsers: SupervisedUserCircleIcon,
      customer: CustomerIcon,
      inventory: ListAltIcon,
      materiales: CategoryIcon,
      stock: InventoryIcon,
      retal: DashboardIcon
    }
};

export default Admin
/*
export default {
    customerList: customerList,
    //customerEdit: customerEdit,
    //customerCreate: customerCreate,
    //customerContactEdit: CustomerContactEdit,
    icons: {
      main:AdminIcon,
      user:UsersIcon,
      customer: CustomerIcon
    }
};



*/