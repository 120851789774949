import { Show, TextField, SimpleShowLayout, Labeled, } from "react-admin"
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

export const UserExposicionShow = () => {

    return(
        <Show>
            <SimpleShowLayout>
                <Grid container direction='row' columnSpacing={2} justifyContent='space-between' style={{fontSize:'1.5rem', fontWeight:'bold'}}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Labeled>
                            <TextField fullWidth source='id' label='ID' disabled style={{fontSize:'1rem', fontWeight:'bold'}}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Labeled>
                            <TextField fullWidth source='name' label='USUARIO PRODUCCION' style={{fontSize:'1rem', fontWeight:'bold'}}/>
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    )
}