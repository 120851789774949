import { Grid } from '@mui/material';

import React from 'react';
import { TextField,  FunctionField, Labeled, NumberField, } from 'react-admin';



const ShowExposicionLayout = () => {


    return (
      <Grid item container style={{ fontSize:'20px', paddingTop: 'auto' }}>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Labeled>
            <TextField source="expoName" label="NOMBRE EXPOSICIÓN"  style={{fontWeight:'bold'}}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Labeled>
            <TextField source="plateName" label="NOMBRE PLANCHA" style={{fontWeight:'bold'}}/>     
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Labeled>
            <TextField source="plateType" label="TIPO DE PLANCHA" style={{fontWeight:'bold'}}/> 
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Labeled>
            <TextField source="calibre" label='CALIBRE EXPOSICIÓN' style={{fontWeight:'bold'}}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Labeled>
            <FunctionField source='dateExposed' label="FECHA EXPOSICIÓN" 
            render={record=> record.dateExposed!==undefined ? new Date(record.dateExposed).toLocaleString():""} style={{fontWeight:'bold'}}/>     
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Labeled>
            <FunctionField source="updatedAt" label="FECHA ACTUALIZACIÓN" 
            render={record=> record.updatedAt!==undefined ? new Date(record.updatedAt).toLocaleString():""} style={{fontWeight:'bold'}}/>      
          </Labeled>
        </Grid>
      </Grid>
    );
  };
  
export default ShowExposicionLayout;